import { Icon } from '@components/@shared';

interface AddButtonProps {
  onAdd: () => void;
}

export default function AddButton({ onAdd }: AddButtonProps) {
  return (
    <button type="button" onClick={onAdd}>
      <Icon name="AddSquare" fill="gray400" />
    </button>
  );
}
