import { useCallback } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import { captureMultipleElementsAsImage } from '@lib/utils';
import {
  fontListAtom,
  careLabelThumbRefAtom,
  selectedLayersAtom,
  mockupImageRefsAtom,
} from '@store/admin/careLabel';
import { useLabelFormat } from './useLabelFormat';
import {
  CareLabelDesignData,
  CareLabelDetailData,
  CareLabelPage,
} from '@customTypes/admin';

export const useImageCapture = () => {
  const labelRef = useAtomValue(careLabelThumbRefAtom);
  const mockupRefs = useAtomValue(mockupImageRefsAtom);

  const fontList = useAtomValue(fontListAtom);
  const setSelectedLayers = useSetAtom(selectedLayersAtom);
  const { backgroundColor } = useLabelFormat();

  type CaptureThumbnailProps = {
    fileName: string;
    designData: CareLabelDesignData;
  };

  const captureThumbnail = useCallback(
    async ({ fileName, designData }: CaptureThumbnailProps) => {
      if (!labelRef?.current) return;

      // 선택되어 있던 레이어 모두 선택 해제
      setSelectedLayers((prev) => ({ ...prev, layerIds: [] }));
      // 약간의 지연을 주어 선택 해제 대기
      await new Promise((resolve) => setTimeout(resolve, 100));

      // 이미지 캡쳐 후 이미지 파일 반환
      return await captureMultipleElementsAsImage({
        elemRefs: [labelRef],
        fileNamePrefix: fileName,
        backgroundColor,
        fontList,
        designData,
        type: 'file',
      });
    },
    [labelRef, fontList, setSelectedLayers]
  );

  const captureMockupImages = useCallback(
    async ({ fileName, designData }: CaptureThumbnailProps) => {
      if (!mockupRefs.length) return;

      // 선택되어 있던 레이어 모두 선택 해제
      setSelectedLayers((prev) => ({ ...prev, layerIds: [] }));
      // 약간의 지연을 주어 선택 해제 대기
      await new Promise((resolve) => setTimeout(resolve, 100));

      return await captureMultipleElementsAsImage({
        elemRefs: mockupRefs,
        fileNamePrefix: fileName,
        fontList,
        designData,
        backgroundColor: 'transparent',
        type: 'base64',
      });
    },
    [mockupRefs, fontList, setSelectedLayers, backgroundColor]
  );

  return { captureThumbnail, captureMockupImages };
};
