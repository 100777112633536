import { useState } from 'react';
import styled from 'styled-components';
import { CLOSE_ICON_GRAY_URL } from '@lib/constants/iconUrls';
import { Icon } from '@components/@shared';
import Calendar, {
  DateValue,
} from '@components/admin/@shared/Calendar/Calendar';

interface DatePickerProps {
  dates: DateValue;
  dateRangeLabel: string;
  onChangeDates: (dates: DateValue) => void;
  placeholder?: string;
  width?: string;
}

export default function DatePicker({
  dates,
  dateRangeLabel,
  onChangeDates,
  placeholder,
  width,
}: DatePickerProps) {
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);

  const handleDropdownOpen = () => {
    setIsCalendarOpen((prev) => !prev);
  };

  const handleValueReset = () => {
    onChangeDates([null, null]);
    setIsCalendarOpen(true);
  };

  const handleValueChange = (dates: DateValue) => {
    onChangeDates(dates);
    setIsCalendarOpen(false);
  };

  return (
    <Container $width={width}>
      <DatePickerButton type="button" onClick={handleDropdownOpen}>
        <Label $isSelected={dateRangeLabel !== ''}>
          <Icon name="Calender" />
          <span>{dateRangeLabel || placeholder}</span>
        </Label>
        {dateRangeLabel !== '' && (
          <ResetButton onClick={handleValueReset}>
            <img src={CLOSE_ICON_GRAY_URL} />
          </ResetButton>
        )}
      </DatePickerButton>
      {isCalendarOpen && (
        <CalendarCantainer>
          <Calendar dates={dates} onChangeDates={handleValueChange} />
        </CalendarCantainer>
      )}
    </Container>
  );
}

interface ContainerProps {
  $width?: string;
}

interface LabelProps {
  $isSelected?: boolean;
}

interface CalendarContainrProps {
  $isOpen?: boolean;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  width: ${(props) => props.$width || '100%'};
  align-self: center;
  font-size: ${(props) => props.theme.fonts.size.sm};
  cursor: pointer;
`;

const DatePickerButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${(props) => props.theme.sizes.dropdownHeight};
  color: ${(props) => props.theme.colors.gray400};
  border: 1px solid ${(props) => props.theme.colors.gray100};
  border-radius: ${(props) => props.theme.borderRadiuses.lg};
  background-color: ${(props) => props.theme.colors.white};
  padding: ${(props) => props.theme.spaces.xxs};
  cursor: pointer;
`;

const Label = styled.label<LabelProps>`
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spaces.xxxs};
  font-size: ${(props) => props.theme.fonts.size.sm};
  text-align: left;
  color: ${(props) =>
    props.$isSelected
      ? props.theme.colors.gray900
      : props.theme.colors.gray400};
  cursor: pointer;
`;

const ResetButton = styled.div`
  padding: ${(props) => props.theme.spaces.xxs} 0;
  display: flex;
  align-items: center;
  z-index: 1;

  & > img {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
`;

const CalendarCantainer = styled.div<CalendarContainrProps>`
  position: absolute;
  top: ${(props) =>
    `calc(${props.theme.sizes.dropdownHeight} + ${props.theme.spaces.xxs})`};
  left: 0;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.borderRadiuses.lg};
  border: 1px solid ${(props) => props.theme.colors.gray100};
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
  z-index: 10;
`;
