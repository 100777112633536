import { forwardRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Icon } from '@components/@shared';

interface InputContainerProps {
  children: React.ReactNode;
  label?: string;
  errorMessage?: string;
  infoMessage?: string;
  inputName: string;
  direction?: 'row' | 'column';
}

function InputContainer({
  children,
  label,
  errorMessage,
  infoMessage,
  inputName,
  direction = 'row',
}: InputContainerProps) {
  return (
    <Container>
      <FieldContainer $direction={direction}>
        {label && (
          <LabelContainer>
            <label htmlFor={inputName}>{label}</label>
          </LabelContainer>
        )}
        <InputWrapper>{children}</InputWrapper>
      </FieldContainer>
      {errorMessage && (
        <Description $direction={direction}>{errorMessage}</Description>
      )}
      {infoMessage && (
        <InfoMessage $direction={direction}>{infoMessage}</InfoMessage>
      )}
    </Container>
  );
}

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  inputName: string;
  noResetButton?: boolean;
  onReset?: () => void;
  maxLength?: number;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      inputName,
      placeholder,
      type = 'text',
      disabled = false,
      noResetButton = false,
      onReset,
      maxLength,
      ...props
    },
    ref
  ) => {
    return (
      <InputFieldContainer>
        <StyledInput
          id={inputName}
          name={inputName}
          placeholder={placeholder}
          type={type}
          ref={ref}
          disabled={disabled}
          maxLength={maxLength}
          {...props}
        />
        {!noResetButton && !disabled && onReset && (
          <ResetButton type="button" onClick={onReset} tabIndex={-1}>
            <Icon name="Close" fill="gray400" />
          </ResetButton>
        )}
      </InputFieldContainer>
    );
  }
);

interface ActionButtonProps {
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

function ActionButton({ label, onClick, disabled }: ActionButtonProps) {
  return (
    <Button type="button" onClick={onClick} disabled={disabled}>
      {label}
    </Button>
  );
}

interface DropdownProps {
  inputName: string;
  options: Array<{ name: string; label: string }>;
  value?: string;
  placeholder?: string;
  onSelect?: (name: string, label: string) => void;
  message?: string;
  maxLength?: number;
}

const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(
  (
    {
      inputName,
      options,
      value = '',
      placeholder,
      onSelect,
      message = '',
      maxLength,
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedLabel, setSelectedLabel] = useState<string>(
      placeholder || ''
    );
    const [isDirectInput, setIsDirectInput] = useState<boolean>(false);
    const [errorMessage, setErrorMEssage] = useState<string>('');

    const handleSelect = (name: string, label: string) => {
      if (name === 'direct_input') {
        setIsDirectInput(true);
        setSelectedLabel('직접 입력');
      } else {
        onSelect?.(name, label);
        setSelectedLabel(label);
        setIsDirectInput(false);
      }
      setIsOpen(false);
    };

    const handleDirectInput = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.length > 200) {
        setErrorMEssage(message);
      } else {
        setErrorMEssage('');
        onSelect?.('direct_input', e.target.value);
      }
    };

    useEffect(() => {
      const selected = options.find((option) => option.name === value);
      if (!selected) return;

      setSelectedLabel(selected.label);
    }, [value, options]);

    return (
      <DropdownContainer ref={ref}>
        <DropdownButton
          type="button"
          onClick={() => setIsOpen((prev) => !prev)}
          $isOpen={isOpen}
        >
          <DropdownLabel $isSelected={selectedLabel !== placeholder}>
            {selectedLabel}
          </DropdownLabel>
          <ArrowIcon $isOpen={isOpen}>
            <Icon name="ChevronDown" fill="gray400" />
          </ArrowIcon>
        </DropdownButton>
        {isOpen && (
          <OptionsList>
            {options.map(({ name, label }) => (
              <OptionItem key={name} onClick={() => handleSelect(name, label)}>
                {label}
              </OptionItem>
            ))}
          </OptionsList>
        )}
        {isDirectInput && (
          <DirectInputContainer>
            <Input
              inputName={`${inputName}_direct`}
              placeholder="요청사항을 입력해주세요."
              onChange={handleDirectInput}
              maxLength={maxLength}
            />
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          </DirectInputContainer>
        )}
      </DropdownContainer>
    );
  }
);

Input.displayName = 'Input';
Dropdown.displayName = 'Dropdown';

export default Object.assign(InputContainer, {
  Input,
  ActionButton,
  Dropdown,
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const FieldContainer = styled.div<{ $direction: 'row' | 'column' }>`
  display: flex;
  flex-direction: ${({ $direction }) => $direction};
  align-items: ${({ $direction }) =>
    $direction === 'row' ? 'center' : 'flex-start'};
  gap: ${({ $direction }) => ($direction === 'column' ? '8px' : '')};
`;

const LabelContainer = styled.div`
  font-size: 16px;
  font-weight: 500;
  flex-shrink: 0;
  min-width: 80px;
`;

const InputWrapper = styled.div`
  flex: 1;
  width: 100%;
`;

const Description = styled.span<{ $direction: 'row' | 'column' }>`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.stateError};
  margin-left: ${({ $direction }) => ($direction === 'row' ? '84px' : '0')};
`;

const InfoMessage = styled.span<{ $direction: 'row' | 'column' }>`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray400};
`;

const InputFieldContainer = styled.div`
  width: 100%;
  position: relative;
`;

const StyledInput = styled.input`
  width: 100%;
  height: 44px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray200};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) =>
    theme.colors.black}; // 입력 텍스트 색상 black으로 설정

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray400};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.gray50};
    color: ${({ theme }) => theme.colors.gray300};
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

const Button = styled.button`
  height: 44px;
  padding: 0 12px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray200};
  background-color: ${({ theme }) => theme.colors.white};
  white-space: nowrap;

  &:disabled {
    color: ${({ theme }) => theme.colors.gray400};
    background-color: ${({ theme }) => theme.colors.gray50};
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
  align-self: center;
`;

const DropdownButton = styled.button<{ $isOpen: boolean }>`
  width: 100%;
  height: 44px;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-radius: 10px;
  border: 1px solid
    ${({ $isOpen, theme }) =>
      $isOpen ? theme.colors.black : theme.colors.gray200};
  background-color: ${({ theme }) => theme.colors.white};
`;

const DropdownLabel = styled.span<{ $isSelected: boolean }>`
  color: ${(props) =>
    props.$isSelected
      ? props.theme.colors.gray900
      : props.theme.colors.gray400};
`;

const ArrowIcon = styled.div<{ $isOpen: boolean }>`
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  transform: ${({ $isOpen }) => ($isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 300ms ease;
`;

const OptionsList = styled.ul`
  position: absolute;
  bottom: 48px;
  left: 0;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray200};
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 1;
`;

const OptionItem = styled.li`
  padding: 8px 10px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray50};
  }

  & + & {
    margin-top: 4px;
  }
`;

const DirectInputContainer = styled.div`
  margin-top: 8px;
  color: ${({ theme }) => theme.colors.black};
`;

const ResetButton = styled.button`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
`;

const ErrorMessage = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.stateError};
  margin-top: 4px;
`;
