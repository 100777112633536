import { z } from 'zod';
import { checkSchema } from '@lib/apis/user/@schema/core';
import { EmailInput } from '@customTypes/admin/signUp';

export type LoginInfoSchemaType = z.infer<typeof loginInfoSchema>;
export type UserInfoSchemaType = z.infer<typeof userInfoSchema>;
export type SelectedBrandSchemaType = z.infer<typeof selectedBrandSchema>;
export type NewBrandSchemaType = z.infer<typeof newBrandSchema>;
export type SignUpSchemaType = z.infer<ReturnType<typeof createSignUpSchema>>;

export const emailSchema = z.object({
  email: z.string().email('올바른 이메일 형식이 아닙니다'),
});

export const checkEmailSchema = (dataObj: EmailInput) => {
  return checkSchema(dataObj, emailSchema);
};

export const loginInfoSchema = emailSchema.extend({
  email: z
    .string({ message: '이메일을 입력해주세요' })
    .email('올바른 이메일 형식이 아닙니다'),
  code: z.string({ message: '인증코드를 입력해 주세요' }),
  password: z
    .string({ message: '비밀번호를 입력해 주세요' })
    .min(8, '8자 이상이어야 합니다')
    .max(16, '16자 이하여야 합니다')
    .regex(/[A-Z]/, '대문자를 포함해야 합니다')
    .regex(/[a-z]/, '소문자를 포함해야 합니다')
    .regex(/[0-9]/, '숫자를 포함해야 합니다')
    .regex(/[!@#$%^&*(),.?":{}|<>]/, '특수문자를 포함해야 합니다'),
  password_confirm: z
    .string({ message: '확인 비밀번호를 입력해 주세요' })
    .min(1, '비밀번호를 다시 입력해 주세요'),
});

export const userInfoSchema = z.object({
  name: z.string({ message: '이름을 입력해 주세요' }),
  phone: z
    .string({ message: '전화번호를 입력해 주세요' })
    .regex(/^[0-9]{11}$/, '전화번호는 11자리 숫자로 입력해 주세요'),
});

// 기존 브랜드 선택 시 사용할 스키마
export const selectedBrandSchema = z.object({
  brand_name: z.string().min(1, '브랜드를 선택해 주세요'),
});

export const validateImageWithCustomError = (
  fieldName: string,
  isNullable: boolean = false
) => {
  return z.preprocess(
    (value) => {
      if (isNullable && !value) return null;

      return value;
    },
    z.union([
      z.null().optional(), // null 허용을 위한 조건
      z
        .custom<File>((file) => file instanceof File && file.size > 0, {
          message: `${fieldName}를 등록해 주세요.`,
        })
        .refine(
          (file) =>
            ['image/jpeg', 'image/jpg', 'image/png'].includes(file.type),
          { message: `${fieldName}는 PNG, JPG 파일만 등록 가능합니다.` }
        ),
    ])
  );
};

// 새 브랜드 등록 시 사용할 스키마
export const newBrandSchema = z.object({
  brand_name: z.string({ message: '브랜드명을 입력해 주세요' }),
  img_logo: validateImageWithCustomError('브랜드 로고 이미지', true),
});

export const createSignUpSchema = () => {
  return loginInfoSchema
    .merge(userInfoSchema)
    .merge(newBrandSchema)
    .refine((data) => data.password === data.password_confirm, {
      message: '비밀번호가 일치하지 않습니다',
      path: ['passwordConfirm'],
    });
};
