import { z } from 'zod';
import { checkSchema } from '@lib/apis/user/@schema/core';

const brandFieldList = {
  brand_name: '브랜드 이름',
  brand_description: '요약 설명',
  brand_homepage_url: '홈페이지 URL',
  brand_location: '위치',
  brand_contact: '브랜드 연락처',
  img_main: '메인 이미지',
  img_logo: '로고 이미지',
};

// 브랜드 등록
export type BrandUploadSchemaType = z.infer<typeof BrandUploadSchema>;

const BrandUploadSchema = z.object({
  brand_name: z.string().min(1),
  brand_description: z.string().min(1),
  brand_homepage_url: z.string().min(1).url(),
  brand_location: z.string().min(1),
  brand_contact: z.string().min(1),
  img_main: z.custom<File>((file) => file instanceof File && file.size > 0, {
    message: '메인 이미지를 등록해주세요.',
  }),
  img_logo: z.custom<File>((file) => file instanceof File && file.size > 0, {
    message: '로고 이미지를 등록해주세요.',
  }),
});

export const checkBrandUploadSchema = (formData: FormData) => {
  return checkSchema(formData, BrandUploadSchema, brandFieldList);
};

// 브랜드 수정
export type BrandUpdateSchemaType = z.infer<typeof BrandUpdateSchema>;

const BrandUpdateSchema = BrandUploadSchema.omit({
  img_main: true,
  img_logo: true,
});

export const checkBrandUpdateSchema = (formData: FormData) => {
  return checkSchema(formData, BrandUpdateSchema, brandFieldList);
};
