import { useCallback } from 'react';
import { useAtom, useSetAtom } from 'jotai';
import { v4 as uuidv4 } from 'uuid';
import { dataURLtoFile } from '@lib/utils';
import { useImageUploadOnLibrary } from '@lib/apis/admin';
import {
  careLabelDesignJsonAtom,
  currentPageIdAtom,
  LayerFactory,
  syncPagesLayersAtom,
} from '@store/admin/careLabel';
import {
  CareLabelPage,
  CareLabelDetailData,
  CareLabelDesignData,
  PdfSlideImage,
  PdfSlideText,
  PdfSlide,
  Layer,
  SvgDetails,
} from '@customTypes/admin';

const DEFAULT_BACKGROUND = 'white' as const;

// 유틸리티 함수들
const createPage = (side: 'front' | 'back'): CareLabelPage => ({
  id: uuidv4(),
  side,
  layers: [],
});

const createPages = (isDoubleSide: boolean): CareLabelPage[] =>
  isDoubleSide
    ? [createPage('front'), createPage('back')]
    : [createPage('front')];

export const useDesignUpdate = () => {
  const [designData, setDesignData] = useAtom(careLabelDesignJsonAtom);
  const [pages, syncLayers] = useAtom(syncPagesLayersAtom);
  const setInitPageId = useSetAtom(currentPageIdAtom);
  const { mutateAsync: uploadImage } = useImageUploadOnLibrary();

  // 이미지 업로드 로직 분리
  const processImageUpload = async (imageItem: SvgDetails): Promise<string> => {
    const { image } = imageItem;
    const file = dataURLtoFile(image, 'image.svg');

    if (!file) {
      throw new Error('이미지 파일 변환 실패');
    }

    const formData = new FormData();
    formData.append('img', file);

    const resData = await uploadImage(formData);
    console.log('resData', resData);

    return resData.img_url || '';
  };

  const createInitData = useCallback((data: any) => {
    const {
      name,
      width,
      height,
      seam_margin,
      side_margin,
      option,
      color_mode,
      textile_color,
    } = data;

    const settings = {
      name,
      width,
      height,
      printFormat: option,
      seamMargin: seam_margin,
      sideMargin: side_margin,
      colorMode: color_mode,
      backgroundColor: textile_color || DEFAULT_BACKGROUND,
    };

    const pages = createPages(option === 'double');

    return { settings, pages };
  }, []);

  // const getInitDataFromPdf = useCallback(async (data: PdfSlide) => {
  //   const settings = {
  //     name: '',
  //     width: data.width,
  //     height: data.height,
  //     printFormat: '',
  //     seamMargin: 0,
  //     sideMargin: 0,
  //     colorMode: '',
  //     backgroundColor: '',
  //   };

  //   // 디자인 페이지들
  //   const pages = createPages(false);

  //   const createLayerFromObject = async (item: PdfSlide['objects'][0]) => {
  //     // 이미지인 경우
  //     if (item.type === 'image') {
  //       const imgUrl = await processImageUpload(item as PdfSlideImage);
  //       return LayerFactory.createLayer({
  //         existingLayers: pages[0].layers,
  //         category: 'image',
  //         layout: { x: item.x, y: item.y, w: item.width, h: item.height },
  //         src: imgUrl,
  //         content: '',
  //         key: undefined,
  //       });
  //     }

  //     // 텍스트인 경우 - 내용이 있을 때만 레이어 생성
  //     const textContent = (item as PdfSlideText).text;
  //     if (textContent) {
  //       return LayerFactory.createLayer({
  //         existingLayers: pages[0].layers,
  //         category: 'text',
  //         layout: { x: item.x, y: item.y, w: item.width, h: item.height },
  //         src: '',
  //         content: textContent,
  //         key: undefined,
  //       });
  //     }

  //     // 이미지도 아니고 텍스트 내용도 없는 경우 null 반환
  //     return null;
  //   };

  //   // null 값 필터링하여 유효한 레이어만 저장
  //   const layers = await Promise.all(data.objects.map(createLayerFromObject));
  //   pages[0].layers = layers.filter(
  //     (layer): layer is NonNullable<typeof layer> => layer !== null
  //   );

  //   return { settings, pages };
  // }, []);

  const getInitDataFromAiPdf = useCallback(async (data: SvgDetails) => {
    const settings = {
      name: '',
      width: data.width,
      height: data.height,
      printFormat: '',
      seamMargin: 0,
      sideMargin: 0,
      colorMode: '',
      backgroundColor: '',
    };

    const imgUrl = await processImageUpload(data);

    const imageLayer = LayerFactory.createLayer({
      existingLayers: [],
      category: 'image',
      layout: { x: 0, y: 0, w: data.width, h: data.height },
      src: imgUrl,
      content: '',
      key: undefined,
    });

    // 디자인 페이지들
    const pages = createPages(false);
    pages[0].layers = [imageLayer];

    return { settings, pages };
  }, []);

  const initializeDesignState = useCallback(
    (data: CareLabelDesignData) => {
      setDesignData(data);
      syncLayers(data.pages);
      setInitPageId(data.pages[0]?.id ?? '');
    },
    [setDesignData, syncLayers, setInitPageId]
  );

  // 케어라벨 상세 조회 시 디자인 데이터 상태 업데이트
  const updateDesignData = useCallback((data: CareLabelDetailData) => {
    if (!data) return;

    const bgColor =
      data.textile_color === 'white' || data.textile_color === 'black'
        ? data.textile_color
        : DEFAULT_BACKGROUND;

    const settings = {
      name: data.name,
      width: data.width,
      height: data.height,
      printFormat: data.option,
      seamMargin: data.seam_margin,
      sideMargin: data.side_margin,
      colorMode: data.color_mode,
      backgroundColor: bgColor,
    };

    // 생성 직후 이전의 데이터가 없는 경우
    if (!data.json?.settings && !data.json.pages?.length) {
      // 디자인 페이지들
      const pages = createPages(data.option === 'double');
      const designData = { settings, pages };
      initializeDesignState(designData);
      return;
    }

    // 기존 디자인 데이터가 있는 경우
    const designData = { settings, pages: data.json.pages };
    initializeDesignState(designData);
  }, []);

  // 작업 후 변경된 디자인 데이터 가져오는 함수
  const getUpdatedDesignData = useCallback(
    (nextData?: CareLabelDetailData) => {
      let nextPages: CareLabelPage[] = pages;

      if (nextData) {
        // 단면인쇄/절반접기 -> 양면인쇄로 변경한 경우
        if (pages.length === 1 && nextData.option === 'double') {
          nextPages = [...pages, createPage('back')];
        }
        // 양면인쇄 -> 단면인쇄/절반접기로 변경한 경우
        if (pages.length === 2 && nextData.option !== 'double') {
          nextPages = [pages[0]];
        }

        // 절반접기인 경우 시접 두 번 계산, 이외의 경우 한 번 계산
        const seamMarginCount = nextData.option === 'fold' ? 2 : 1;

        // 레이아웃 조정
        nextPages.forEach((page: CareLabelPage) => {
          page.layers.forEach((layer: Layer) => {
            const maxX = nextData.width - nextData.side_margin - layer.layout.w;
            const maxY =
              nextData.height -
              nextData.seam_margin * seamMarginCount -
              layer.layout.h;
            const x = layer.layout.x > maxX ? maxX : layer.layout.x;
            const y = layer.layout.y > maxY ? maxY : layer.layout.y;

            return {
              ...layer,
              layout: {
                ...layer.layout,
                x,
                y,
              },
            };
          });
        });
      }

      const nextDesignData = {
        ...designData,
        pages: nextPages,
      };

      setDesignData(nextDesignData);

      return nextDesignData;
    },
    [designData, pages]
  );

  return {
    createInitData,
    initializeDesignState,
    // getInitDataFromPdf,
    getInitDataFromAiPdf,
    updateDesignData,
    getUpdatedDesignData,
  };
};
