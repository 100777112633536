import {
  CHART_ICON_URL,
  CLOTHES_ICON_URL,
  HEART_ICON_URL,
  INFO_ICON_URL,
  LABEL_ICON_URL,
} from '@lib/constants/iconUrls';
import PAYMENT_LIST_ICON from '@assets/icons/payment-list.svg';
// language
export const LANGUAGES = [
  {
    name: 'ko',
    label: 'KO',
  },
  {
    name: 'en',
    label: 'EN',
  },
];
// path
export const ADMIN_GUEST_PATH = {
  signup: '/signup',
  signupResult: '/signup/result',
  login: '/login',
  findEmail: '/find-email',
  findPassword: '/find-password',
};
const ADMIN_MY_PAGE_PATH = {
  index: '/my',
  get account() {
    return `${this.index}/account`;
  },
  get authority() {
    return `${this.index}/authority`;
  },
};
const ADMIN_DASHBOARD_PATH = {
  index: 'dashboard',
  list: 'list',
};
const ADMIN_PRODUCT_IMAGE_PATH = {
  index: 'product-image',
  list: 'list',
  upload: 'upload',
};
const ADMIN_CARE_LABEL_IMAGE_PATH = {
  index: 'care-label-image',
  list: 'list',
  upload: 'upload',
};
const ADMIN_ORDER_PATH = {
  index: 'order',
  list: 'list',
  detail: ':orderId',
};
const ADMIN_DPP_INFO_PATH = {
  index: 'dpp',
  list: 'list',
  // detail: ':productId',
  detail: ':no',
  excelUpload: 'upload-excel',
  upload: 'upload',
  // update: 'update/:productId',
  update: 'update/:no',
  sample: 'sample',
};
const ADMIN_CARE_LABEL_PATH = {
  index: 'care-label',
  create: 'new',
  design: 'design',
  order: 'order',
  payment: 'payment',
  paymentSuccess: 'payment/success',
  paymentFail: 'payment/fail',
  guide: 'guide',
};
const ADMIN_BRAND_PATH = {
  index: 'brand',
  shippingAddress: 'shipping-address',
  managers: 'managers',
  list: 'list',
  detail: ':brandName',
  upload: 'upload',
  update: 'update/:brandName',
};
const ADMIN_TERMS_PATH = {
  index: 'terms',
  privacyPolicy: 'privacy-policy',
  service: 'service',
  refund: 'refund',
};
/**
 * @todo path 구조 전체 수정 필요
 */
export const ADMIN_PAGE_PATH = {
  landing: '/',
  terms: ADMIN_TERMS_PATH,
  index: '/admin',
  guest: ADMIN_GUEST_PATH,
  mypage: ADMIN_MY_PAGE_PATH,
  dashboard: ADMIN_DASHBOARD_PATH,
  productImage: ADMIN_PRODUCT_IMAGE_PATH,
  careLabelImage: ADMIN_CARE_LABEL_IMAGE_PATH,
  order: ADMIN_ORDER_PATH,
  dppInfo: ADMIN_DPP_INFO_PATH,
  careLabel: ADMIN_CARE_LABEL_PATH,
  brand: ADMIN_BRAND_PATH,
};
export type Nav = {
  id: number;
  path: string;
  labelKey: string;
  iconUrl: string;
};
export const SUPER_ADMIN_NAV_LIST: Nav[] = [
  {
    id: 0,
    path: `${ADMIN_PAGE_PATH.index}/${ADMIN_PAGE_PATH.dashboard.index}`,
    labelKey: 'nav.dashboard',
    iconUrl: CHART_ICON_URL,
  },
  {
    id: 1,
    path: `${ADMIN_PAGE_PATH.index}/${ADMIN_PAGE_PATH.brand.index}`,
    labelKey: 'nav.brandManagement',
    iconUrl: HEART_ICON_URL,
  },
  {
    id: 2,
    path: `${ADMIN_PAGE_PATH.index}/${ADMIN_PAGE_PATH.productImage.index}`,
    labelKey: 'nav.productImage',
    iconUrl: CLOTHES_ICON_URL,
  },
  {
    id: 3,
    path: `${ADMIN_PAGE_PATH.index}/${ADMIN_PAGE_PATH.careLabelImage.index}`,
    labelKey: 'nav.careLabelImage',
    iconUrl: LABEL_ICON_URL,
  },
  {
    id: 4,
    path: `${ADMIN_PAGE_PATH.index}/${ADMIN_PAGE_PATH.dppInfo.index}`,
    labelKey: 'nav.dppInfo',
    iconUrl: INFO_ICON_URL,
  },
];
export const BRAND_ADMIN_NAV_LIST: Nav[] = [
  ...SUPER_ADMIN_NAV_LIST.slice(2),
  {
    id: 5,
    path: `${ADMIN_PAGE_PATH.index}/${ADMIN_PAGE_PATH.careLabel.index}`,
    labelKey: 'nav.careLabelDesign',
    iconUrl: HEART_ICON_URL,
  },
  {
    id: 6,
    path: `${ADMIN_PAGE_PATH.index}/${ADMIN_PAGE_PATH.order.index}/${ADMIN_PAGE_PATH.order.list}`,
    labelKey: 'nav.orderList',
    iconUrl: PAYMENT_LIST_ICON,
  },
  {
    id: 7,
    path: `${ADMIN_PAGE_PATH.index}/${ADMIN_PAGE_PATH.brand.index}`,
    labelKey: 'nav.brandInfo',
    iconUrl: HEART_ICON_URL,
  },
  {
    id: 8,
    path: `${ADMIN_PAGE_PATH.index}/${ADMIN_PAGE_PATH.brand.index}/${ADMIN_PAGE_PATH.brand.managers}`,
    labelKey: 'nav.managers',
    iconUrl: HEART_ICON_URL,
  },
];
export const BRAND_MANAGER_NAV_LIST: Nav[] = [
  ...BRAND_ADMIN_NAV_LIST.slice(0, -1),
];
// search input fields
export const BRAND_SEARCH_INPUT = {
  name: 'brand',
  placeholder: '입력해주세요.',
  label: '브랜드 이름',
};
export const BRAND_NAME_SEARCH_INPUT = {
  name: 'name',
  placeholder: '입력해주세요.',
  label: '브랜드 이름',
};
export const IMAGE_NAME_SEARCH_INPUT = {
  name: 'name',
  placeholder: '입력해주세요.',
  label: '이미지 이름',
};
export const PRODUCT_NAME_SEARCH_INPUT = {
  name: 'name',
  placeholder: '입력해주세요.',
  label: '제품 이름',
};
export const PRODUCT_ID_SEARCH_INPUT = {
  name: 'id',
  placeholder: '입력해주세요.',
  label: '제품 아이디',
};
