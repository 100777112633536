import { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Icon, IconName } from '@components/admin/@shared/atoms';

export type Tab = {
  index: number;
  label: string;
  iconName?: IconName;
  component?: () => ReactNode;
};

interface TabsProps {
  tabList: Tab[];
  selectedTabIndex: number;
  onClick: (index: number) => void;
}

export default function Tabs({
  tabList,
  selectedTabIndex,
  onClick,
}: TabsProps) {
  const [currTabIndex, setCurrTabIndex] = useState<number>(0);

  useEffect(() => {
    if (!selectedTabIndex) return;

    setCurrTabIndex(selectedTabIndex);
  }, [selectedTabIndex]);

  const handleTabClick = (index: number) => {
    setCurrTabIndex(index);
    onClick(index);
  };

  useEffect(() => {
    return () => {
      setCurrTabIndex(0);
    };
  }, []);

  return (
    <TabsContainer>
      {tabList.map((tab) => (
        <Tab
          type="button"
          onClick={() => handleTabClick(tab.index)}
          key={tab.index}
          $isSelected={tab.index === currTabIndex}
        >
          {tab.iconName && <Icon name={tab.iconName} />}
          <span>{tab.label}</span>
        </Tab>
      ))}
    </TabsContainer>
  );
}

const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
`;

interface TabProps {
  $isSelected?: boolean;
}

const Tab = styled.button<TabProps>`
  position: relative;
  padding: 14px 10px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme, $isSelected }) =>
    $isSelected ? theme.colors.black : theme.colors.gray400};

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: ${(props) =>
      props.$isSelected ? props.theme.colors.black : 'transparent'};
  }
`;
