import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAtom } from 'jotai';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { SUPER_ADMIN_NAV_LIST } from '@lib/constants/admin/common';
import {
  chargeBrandAtomWithPersistence,
  roleAtomWithPersistence,
} from '@store/login';
import { LanguageSelector, LoginButton } from '.';

export default function TopBar() {
  const { t } = useTranslation('admin/common');
  const location = useLocation();
  const [currPathLabel, setCurrPathLabel] = useState<string>('');
  const [role] = useAtom(roleAtomWithPersistence);
  const [chargeBrand] = useAtom(chargeBrandAtomWithPersistence);

  useEffect(() => {
    const currPath = SUPER_ADMIN_NAV_LIST.find((nav) =>
      location.pathname.startsWith(nav.path)
    );
    setCurrPathLabel(currPath?.labelKey || '');
  }, [location.pathname]);

  return (
    <Wrapper>
      <Container>
        <LanguageSelector />
        <Profile>
          <Border />
          <ProfileRole>
            <span>
              {role === 'brand' ? chargeBrand || 'BRAND' : 'MNTC.STAFF'}
            </span>
            <span>Admin</span>
          </ProfileRole>
          <Border />
          <LoginButton />
        </Profile>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: ${(props) => props.theme.sizes.adminSideBarWidth};
  width: ${(props) => `calc(100% - ${props.theme.sizes.adminSideBarWidth})`};
  height: ${(props) => props.theme.sizes.adminTopBarHeight};
  background-color: ${(props) => props.theme.colors.white};
  z-index: 1800;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${(props) => props.theme.spaces.xxxxxl};
`;

const Breadcrumb = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spaces.lg};
  font-weight: ${(props) => props.theme.fonts.weight.semibold};
  color: ${(props) => props.theme.colors.gray400};
  min-width: 120px;
  word-break: keep-all;

  & > span:last-child {
    color: ${(props) => props.theme.colors.gray900};
  }
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spaces.lg};
`;

const ProfileRole = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spaces.xxxs};
  font-weight: ${(props) => props.theme.fonts.weight.bold};

  & span:last-child {
    font-size: ${(props) => props.theme.fonts.size.xs};
    font-weight: ${(props) => props.theme.fonts.weight.medium};
    color: ${(props) => props.theme.colors.gray600};
  }
`;

const Border = styled.div`
  width: 1px;
  height: 20px;
  background-color: ${(props) => props.theme.colors.gray100};
`;
