import { atom } from 'jotai';
import { toKoreanISO, convertToDateRangeLabel } from '@lib/utils';
import { DateValue } from '@components/admin/@shared/organisms';
import { SearchKeywords } from '@customTypes/admin';

/**
 * 페이지 번호
 */
export const pageNumberAtom = atom<number>(1);
pageNumberAtom.onMount = (setAtom) => {
  return () => {
    setAtom(1);
  };
};

// 페이지 offset
export const pageOffsetAtom = atom<number>(1);
pageOffsetAtom.onMount = (setAtom) => {
  return () => {
    setAtom(1);
  };
};

/**
 * 페이지당 아이템 개수
 */
export const itemCountAtom = atom<string>('10');
itemCountAtom.onMount = (setAtom) => {
  return () => {
    setAtom('10');
  };
};

export const initSearchKeywords: SearchKeywords = {
  product: '',
  sku_code: '',
};

/**
 * 검색 키워드
 */
export const searchKeywordsAtom = atom<SearchKeywords>(initSearchKeywords);
searchKeywordsAtom.onMount = (setAtom) => {
  return () => {
    setAtom(initSearchKeywords);
  };
};

/**
 * 의류 분류 필터
 */
export const categoryFilterAtom = atom<string>('');
categoryFilterAtom.onMount = (setAtom) => {
  return () => {
    setAtom('');
  };
};
