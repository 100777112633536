import RowWrapper from '../RowWrapper';
import Layout from '../Layout';
import {
  LANDING_SECTION_HOME_IMAGE_URL_1,
  LANDING_SECTION_HOME_IMAGE_URL_2,
  LANDING_SECTION_HOME_IMAGE_URL_3,
  LANDING_SECTION_HOME_IMAGE_URL_4,
  LANDING_SECTION_HOME_IMAGE_URL_5,
} from '@lib/constants/imageUrls';

type Section = {
  badge: string;
  title1: string;
  title2: string;
  description: string;
  open?: string;
  image: string;
  imagePosition: 'right' | 'left';
  button?: string;
};

const sections: Section[] = [
  {
    badge: 'Convert PDF',
    title1: '디자인 파일만 넣으면',
    title2: '주문하기 끝',
    description:
      '기존 작업하던 일러스트 파일을 손쉽게 변환할 수 있습니다. 망설이지 말고 바로 시작해보세요.',
    image: LANDING_SECTION_HOME_IMAGE_URL_2,
    imagePosition: 'left',
  },
  {
    badge: 'Size',
    title1: '하나의 디자인 파일로',
    title2: '모든 사이즈 주문 가능',
    description:
      '귀찮게 모든 사이즈 복붙하지 마세요. 디자인 수정 없이 모든 사이즈 출력할 수 있습니다.',
    image: LANDING_SECTION_HOME_IMAGE_URL_3,
    imagePosition: 'right',
  },
  {
    badge: 'Authenticated',
    title1: '골치아픈 정품인증,',
    title2: '시험성적서 인증까지 한번에.',
    description:
      '별도의 인증 솔루션 비용 없이, CARE ID로 케어라벨 제작과 정품 인증을 한 번에 해결하세요.',
    open: '(2025 상반기 오픈 예정)',
    image: LANDING_SECTION_HOME_IMAGE_URL_4,
    imagePosition: 'left',
    button: '문의하기',
  },
  {
    badge: 'Easy to Use',
    title1: '글로벌 브랜드의 케어라벨',
    title2: '템플릿을 마음껏 활용하세요.',
    description:
      '고민 없이 손쉽게 디자인 완성 하여 시간을 절약할 수 있습니다. 케어라벨 디자인 대신 더 중요한 일에 집중하세요.',
    open: '(2025 상반기 오픈 예정)',
    image: LANDING_SECTION_HOME_IMAGE_URL_1,
    imagePosition: 'right',
    button: '문의하기',
  },
  {
    badge: 'Dashboard',
    title1: '뿐만 아니라,',
    title2: '중고 거래 데이터까지 제공.',
    description:
      '리셀 데이터, 고객 정보 등 데이터를 제공해요. 케어라벨 제작만으로 추가 가치를 창출해보세요. ',
    open: '(2025 하반기 오픈 예정)',
    image: LANDING_SECTION_HOME_IMAGE_URL_5,
    imagePosition: 'left',
    button: '문의하기',
  },
];

export default function Section03() {
  return (
    <Layout>
      {sections.map((section, index) => (
        <RowWrapper key={index} {...section} />
      ))}
    </Layout>
  );
}
