import { forwardRef, InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { Icon } from '@components/@shared';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  inputName?: string;
  placeholder?: string;
  value?: string | number;
  type?: string;
  disabled?: boolean;
  readOnly?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onReset?: () => void;
  height?: string;
  fontSize?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      inputName,
      placeholder,
      value = '',
      type = 'text',
      disabled = false,
      readOnly = false,
      onChange,
      onBlur,
      onReset,

      height,
      fontSize,
      ...props
    },
    ref
  ) => {
    return (
      <FieldInputContainer>
        <FieldInput
          id={inputName}
          name={inputName}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          type={type}
          ref={ref}
          disabled={disabled}
          $readOnly={readOnly}
          $height={height}
          $fontSize={fontSize}
          $noResetButton={!onReset}
          {...props}
        />
        {onReset && !disabled && value && (
          <ResetButton type="button" onClick={onReset} tabIndex={-1}>
            <Icon name="Close" fill="gray400" />
          </ResetButton>
        )}
      </FieldInputContainer>
    );
  }
);
// DevTools에서 컴포넌트 이름 표시를 위해 설정
Input.displayName = 'Input';
export default Input;

const FieldInputContainer = styled.div`
  width: 100%;
  position: relative;
`;

interface FieldInputProps {
  $readOnly?: boolean;
  $noResetButton?: boolean;
  $height?: string;
  $fontSize?: string;
}

const FieldInput = styled.input<FieldInputProps>`
  padding: 10px;
  padding-right: ${(props) => (props.$noResetButton ? '10px' : '44px')};
  display: block;
  width: 100%;
  min-width: 120px;
  height: ${(props) => props.$height || '44px'};
  border-radius: ${(props) => props.theme.borderRadiuses.lg};
  border: 1px solid ${(props) => props.theme.colors.gray200};
  background-color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.$fontSize || '16px'};
  // 말줄임표
  text-overflow: ellipsis;
  white-space: nowrap;

  &::placeholder {
    color: ${(props) => props.theme.colors.gray400};
  }

  /* Focused */
  &:focus {
    border-color: ${(props) =>
      props.$readOnly
        ? props.theme.colors.gray200
        : props.theme.colors.black} !important;
  }

  /* Disabled */
  &:disabled {
    border-color: ${(props) => props.theme.colors.gray100} !important;
    color: ${(props) => props.theme.colors.gray300};
    background-color: ${(props) => props.theme.colors.gray50};
  }

  &:disabled::placeholder {
    color: ${(props) => props.theme.colors.gray300};
  }

  ${(props) =>
    props.$readOnly &&
    `
      border-color: ${props.theme.colors.gray200} !important;
      cursor: not-allowed;
      pointer-events: none;
    `}

  /* 자동완성 기본 스타일 제거 */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-text-fill-color: ${(props) => props.theme.colors.black};
    -webkit-box-shadow: 0 0 0px 1000px ${(props) => props.theme.colors.white}
      inset;
  }
`;

const ResetButton = styled.button`
  position: absolute;
  right: ${(props) => props.theme.spaces.md};
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
`;
