import { z } from 'zod';

// 로그인
export type LoginSchemaType = z.infer<typeof loginSchema>;

export const loginSchema = z.object({
  email: z
    .string({ message: '이메일을 입력해 주세요' })
    .email({ message: '올바른 이메일 형식이 아닙니다' }),
  password: z.string({ message: '비밀번호를 입력해 주세요' }),
});
