import styled from 'styled-components';
import { Icon } from '../Icon';
import { useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { CustomerService } from '@components/admin/CustomerService';

export default function FloatingActionButton() {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleToggleFab = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <FabContainer ref={containerRef}>
      <AnimatePresence mode="wait">
        {!isOpen ? (
          <MotionDiv
            key="button"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: 0.2 }}
            onClick={handleToggleFab}
          >
            <CustomerService.Button />
          </MotionDiv>
        ) : (
          <MotionDiv
            key="wrapper"
            initial={{ opacity: 0, scale: 0.9, y: 20 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.9, y: 20 }}
            transition={{
              duration: 0.3,
              ease: 'easeOut',
            }}
            onClick={handleToggleFab}
          >
            <CustomerService />
          </MotionDiv>
        )}
      </AnimatePresence>
    </FabContainer>
  );
}

const FabContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  cursor: pointer;
  z-index: 1000;
`;

const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.black};
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  border: none;
`;

const MotionDiv = styled(motion.div)`
  position: relative;
`;
