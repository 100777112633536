import styled from 'styled-components';
import RadioButtons from './RadioButtons';
import { BOOLEAN_OPTIONS } from '@lib/constants/booleanOptions';

interface BooleanRadioGroupProps {
  inputName: string;
  onSelect?: (value: string) => void;
  selectedOption?: string;
  readonly?: boolean;
}

export default function BooleanRadioGroup({
  inputName,
  onSelect,
  selectedOption,
  readonly,
}: BooleanRadioGroupProps) {
  return (
    <Container>
      <RadioButtons
        inputName={inputName}
        options={BOOLEAN_OPTIONS}
        onSelect={onSelect}
        selectedOption={selectedOption}
        disabled={readonly}
      />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;

  /* button들을 담은 컨테이너 div */
  & > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 8px;
  }

  /* 각각의 button (네, 아니오) */
  & > div > * {
    flex: 1;
  }
`;
