import { Icon } from '@components/@shared';
import { Button } from '@components/admin/@shared';
import styled from 'styled-components';

const CUSTOMER_SERVICE_INFO = [
  {
    label: '전화번호',
    value: '070-7782-7601',
  },
  {
    label: '이메일',
    value: 'careid@careid.center',
  },
  {
    label: '영업시간',
    value: '10:00 ~ 19:00',
  },
];

const CHAT_URL = 'https://pf.kakao.com/_IxdBxdn';

function CustomerServiceBox() {
  const handleNavigateToChannelTalk = (e: React.MouseEvent) => {
    e.stopPropagation();
    const windowFeatures = 'width=600,height=800,scrollbars=yes,resizable=yes';
    window.open(CHAT_URL, '_blank', windowFeatures);
  };

  return (
    <CustomerServiceContainer>
      <CustomerServiceTitle>고객센터</CustomerServiceTitle>
      <CustomerServiceContent>
        {CUSTOMER_SERVICE_INFO.map((info) => (
          <CustomerServiceInfo key={info.label}>
            <CustomerServiceLabel>{info.label}</CustomerServiceLabel>
            <CustomerServiceValue>{info.value}</CustomerServiceValue>
          </CustomerServiceInfo>
        ))}
      </CustomerServiceContent>
      <CustomButton
        onClick={handleNavigateToChannelTalk}
        backgroundColor="white"
        color="black"
      >
        1:1 채팅 문의
      </CustomButton>
    </CustomerServiceContainer>
  );
}

interface CustomerServiceButtonProps {
  onClick?: () => void;
}

function CustomerServiceButton({ onClick }: CustomerServiceButtonProps) {
  return (
    <StyledButton onClick={onClick}>
      <Icon name="Headphone" fill="white" />
    </StyledButton>
  );
}

export default Object.assign(CustomerServiceBox, {
  Button: CustomerServiceButton,
});

const CustomerServiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px;
  padding: 12px;
  gap: 12px;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  width: 180px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
`;

const CustomerServiceTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
`;

const CustomerServiceContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

const CustomerServiceInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const CustomerServiceLabel = styled.p`
  font-size: 10px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray600};
`;

const CustomerServiceValue = styled.p`
  font-size: 12px;
  font-weight: 500;
`;

const CustomButton = styled(Button)`
  && {
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.black};

    &:not(:disabled):hover {
      color: ${(props) => props.theme.colors.black};
      background-color: ${(props) => props.theme.colors.gray400};
    }
  }
`;

const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.black};
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  border: none;
`;
