import { DefaultTheme } from 'styled-components';

const colors = {
  stateDefault: '#999999',
  stateSuccess: '#00C964',
  stateError: '#FF0000',
  primary: '#C6D7E4',
  // Neutral
  neutral50: '#737373',
  // Grayscale
  white: '#ffffff',
  gray25: '#f8f8f8',
  gray50: '#f3f3f3',
  gray100: '#e6e6e6',
  gray200: '#cccccc',
  gray300: '#b3b3b3',
  gray400: '#999999',
  gray500: '#808080',
  gray600: '#666666',
  gray700: '#4d4d4d',
  gray800: '#333333',
  gray900: '#1a1a1a',
  gray1000: '#000000',
  black: '#030303',
  red: '#FF0000',
  pink: '#FFDADA',
  lightpink: '#FFF5F5',
  yellow: '#F5BB25',
  // Primary
  primary50: '#e0ffef',
  primary100: '#c3ffe0',
  primary200: '#a0f5c9',
  primary300: '#7ceab3',
  primary400: '#59e09c',
  primary500: '#41d98d',
  primary600: '#32a86d',
  primary700: '#257c50',
  //
  modalBg: 'rgba(0, 0, 0, 0.6)',
  toastBg: 'rgba(0, 0, 0, 0.8)',
};

const fonts = {
  family: `'DM Sans',
  'Pretendard Variable',
  Pretendard,
  -apple-system,
  BlinkMacSystemFont,
  system-ui,
  Roboto,
  'Helvetica Neue',
  'Segoe UI',
  'Apple SD Gothic Neo',
  'Noto Sans KR',
  'Malgun Gothic',
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
  sans-serif`,
  size: {
    base: '10px',
    xs: '1.2rem',
    sm: '1.4rem',
    md: '1.6rem',
    lg: '1.8rem',
    xl: '2.0rem',
    xxl: '2.4rem',
    xxxl: '2.8rem',
    xxxxl: '3.2rem',
    xxxxxl: '4.0rem',
  },
  weight: {
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  lineHeight: {
    none: 1,
    normal: 1.3,
  },
};

const borderRadiuses = {
  none: 0,
  xs: '4px',
  sm: '6px',
  md: '8px',
  lg: '10px',
  xxl: '20px',
  full: '50%',
};

const boxShadows = {
  container: '1px 1px 10px 0px rgba(0, 0, 0, 0.08)',
  shadow50: '1px 1px 16px 0px rgba(0, 0, 0, 0.12)',
};

const spaces = {
  none: 0,
  xxxxxs: '2px',
  xxxxs: '4px',
  xxxs: '8px',
  xxs: '10px',
  xs: '12px',
  sm: '14px',
  md: '16px',
  lg: '20px',
  xl: '30px',
  xxl: '32px',
  xxxl: '40px',
  xxxxl: '48px',
  xxxxxl: '60px',
};

const sizes = {
  headerHeight: '60px',
  bottomCtaHeight: '50px',
  tabsHeight: '48px',
  adminSideBarWidth: '240px',
  adminTopBarHeight: '60px',
  dropdownHeight: '44px',
};

const animations = {
  customCubicBezier: 'cubic-bezier(0.22, 0.005, 0.055, 0.995)',
};

export const theme: DefaultTheme = {
  colors,
  fonts,
  borderRadiuses,
  boxShadows,
  spaces,
  sizes,
  animations,
};
