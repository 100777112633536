import { useCallback } from 'react';
import { useAtom } from 'jotai';
import styled from 'styled-components';
import ReactCalendar from 'react-calendar';
import {
  NavigationLabelArgs,
  Value,
} from 'react-calendar/dist/cjs/shared/types';
import 'react-calendar/dist/Calendar.css';
import { convertDateToNavLabelFormat } from '@lib/utils/date';
import { languageAtomWithPersistence } from '@store/admin/common';
import { Icon } from '@components/@shared';

export type DateValue = Value;

interface CalendarProps {
  dates: DateValue;
  onChangeDates: (dates: DateValue) => void;
}

export default function Calendar({ dates, onChangeDates }: CalendarProps) {
  const [language] = useAtom(languageAtomWithPersistence);
  const preventDrillEvent = useCallback(() => {}, []);

  return (
    <CalenderContainer>
      <StyledCalender
        value={dates}
        onChange={onChangeDates}
        selectRange={true}
        returnValue="range"
        maxDate={new Date()}
        locale={language === 'ko' ? 'ko-KR' : 'en-US'}
        formatDay={(_, date) => date.toLocaleString('en', { day: 'numeric' })}
        // 일요일부터 시작하도록 설정
        calendarType="gregory"
        view="month"
        onDrillUp={preventDrillEvent}
        onDrillDown={preventDrillEvent}
        navigationLabel={({ date }: NavigationLabelArgs) => (
          <NavLabel>{convertDateToNavLabelFormat(date)}</NavLabel>
        )}
        prevLabel={<Icon name="ChevronLeft" />}
        prev2Label={<Icon name="ChevronLeftDouble" />}
        nextLabel={<Icon name="ChevronRight" />}
        next2Label={<Icon name="ChevronRightDouble" />}
      />
    </CalenderContainer>
  );
}

const CalenderContainer = styled.div`
  padding: 30px;
`;

const StyledCalender = styled(ReactCalendar)`
  &&& {
    font-family: ${(props) => props.theme.fonts.family};
    border: none;
    width: auto;
    color: ${(props) => props.theme.colors.gray900};
    z-index: 100 !important;
  }

  & button {
    &:enabled:hover {
      background: ${(props) => props.theme.colors.gray50};
    }

    &:enabled:focus,
    &:enabled:active {
      background: none;
    }

    &:disabled {
      background: none;
      color: ${(props) => props.theme.colors.gray200};
      cursor: default;
    }
  }

  & abbr {
    border: none;
    text-decoration: none;
  }

  & .react-calendar__navigation {
    /* margin-top: 8px;
    margin-bottom: 16px; */
    margin-bottom: 12px;
    height: fit-content;
    display: flex;
    align-items: center;
  }

  & .react-calendar__navigation__label {
    cursor: default !important;
    background: none !important;

    &:hover {
      background: none !important;
    }
  }

  & .react-calendar__navigation__arrow {
    display: inline-block;
    min-width: 28px;
    width: 28px;
    height: 28px;
    padding: 2px;

    &:enabled:focus,
    &:enabled:active {
      background: none;
    }

    &:enabled:hover {
      background-color: ${(props) => props.theme.colors.gray100};
      border-radius: 4px;
    }
  }

  & .react-calendar__navigation button:disabled {
    background: none;

    & > * {
      fill: ${(props) => props.theme.colors.gray200};
    }
  }

  .react-calendar__month-view__weekdays__weekday {
    width: 64px;
    height: 64px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    font: inherit;
    font-size: ${(props) => props.theme.fonts.size.sm};
    font-weight: ${(props) => props.theme.fonts.weight.semibold};
    cursor: default;
  }

  & .react-calendar__tile {
    width: 64px;
    height: 64px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font: inherit;
    font-size: ${(props) => props.theme.fonts.size.sm};
    border-right: 1px solid ${(props) => props.theme.colors.gray100};
    border-bottom: 1px solid ${(props) => props.theme.colors.gray100};

    &:nth-child(7n) {
      border-right: none;
    }

    &:nth-last-child(-n + 7) {
      border-bottom: none;
    }

    &:enabled:hover {
      background: ${(props) => props.theme.colors.gray50};
    }
  }

  & .react-calendar__tile--active,
  & .react-calendar__tile--active.react-calendar__tile--now {
    background: ${(props) => props.theme.colors.gray900} !important;
    color: ${(props) => props.theme.colors.white};
  }

  & .react-calendar__tile--active:enabled:hover,
  & .react-calendar__tile--active:enabled:focus {
    background: ${(props) => props.theme.colors.gray900};
  }

  & .react-calendar__tile--now {
    background: none;

    &:enabled:hover {
      background: ${(props) => props.theme.colors.gray50};
    }

    &:enabled:focus,
    &:enabled:active {
      background: none;
    }
  }

  & .react-calendar__month-view__days {
    border: 1px solid ${(props) => props.theme.colors.gray100};
  }

  & .react-calendar__month-view__days__day--weekend {
    color: ${(props) => props.theme.colors.red};
  }

  & .react-calendar__month-view__days__day--neighboringMonth,
  & .react-calendar__decade-view__years__year--neighboringDecade,
  & .react-calendar__century-view__decades__decade--neighboringCentury {
    color: ${(props) => props.theme.colors.gray200};
    background: ${(props) => props.theme.colors.gray50} !important;
  }
`;

const NavLabel = styled.span`
  font-size: 24px;
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  color: ${(props) => props.theme.colors.gray900};
  background: none !important;
  cursor: default !important;

  &:hover,
  &.focus,
  &:active {
    background: none !important;
  }
`;
