import { ReactElement, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Icon } from '@components/@shared';
import { roundedMinimalScrollbar } from '@lib/styles/scrollbarStyles';

export type FilterDropdownOption = {
  name: string;
  label: string;
  disabled?: boolean;
};

interface FilterDropdownProps {
  options: FilterDropdownOption[];
  value: string;
  onChangeValue: (value: string) => void;
  placeholder?: string;
  canReset?: boolean;
  iconUrl?: string;
  icon?: ReactElement;
  width?: string;
  boldLabel?: boolean;
}

export default function FilterDropdown({
  options,
  value,
  onChangeValue,
  placeholder,
  canReset,
  icon,
  iconUrl,
  width,
  boldLabel,
}: FilterDropdownProps) {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [label, setLabel] = useState<string>(placeholder || '');

  const handleOptionClick = (name: string, label: string) => {
    onChangeValue(name);
    setLabel(label);
    setIsDropdownOpen(false);
  };

  const handleDropdownOpen = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const handleValueReset = () => {
    onChangeValue('');
    setLabel(placeholder || '');
    setIsDropdownOpen(true);
  };

  useEffect(() => {
    onChangeValue(value);
    const selected = options.find((option) => option.name === value);
    if (!selected) return;

    setLabel(selected.label);
  }, [value]);

  return (
    <Container $width={width}>
      <DropdownButton
        type="button"
        onClick={handleDropdownOpen}
        $isOpen={isDropdownOpen}
      >
        <Label $isSelected={value !== ''} $isBold={boldLabel}>
          {icon}
          <span>{label}</span>
        </Label>
        {canReset && value !== '' ? (
          <ResetButton onClick={handleValueReset}>
            <Icon name="Close" fill="gray400" />
          </ResetButton>
        ) : (
          <ArrowDownIcon $isOpen={isDropdownOpen}>
            <Icon name="ChevronDown" fill="gray400" />
          </ArrowDownIcon>
        )}
      </DropdownButton>
      {isDropdownOpen && (
        <Options $isOpen={isDropdownOpen}>
          {options.map(({ name, label, disabled }) => (
            <Option
              type="button"
              onClick={() => handleOptionClick(name, label)}
              disabled={!!disabled}
              key={name}
            >
              {label}
            </Option>
          ))}
        </Options>
      )}
    </Container>
  );
}

interface ContainerProps {
  $width?: string;
  $readOnly?: boolean;
}

interface StyledDropdownProps {
  $isOpen?: boolean;
  $readOnly?: boolean;
}

interface StyledLabelProps {
  $isSelected?: boolean;
  $isBold?: boolean;
  $readOnly?: boolean;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  width: ${(props) => props.$width || '100%'};
  align-self: center;
  font-size: ${(props) => props.theme.fonts.size.sm};
  cursor: ${(props) => (props.$readOnly ? 'default' : 'pointer')};
`;

const DropdownButton = styled.button<StyledDropdownProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${(props) => props.theme.sizes.dropdownHeight};
  color: ${(props) => props.theme.colors.gray400};
  border: 1px solid ${(props) => props.theme.colors.gray100};
  border-radius: ${(props) => props.theme.borderRadiuses.lg};
  background-color: ${(props) => props.theme.colors.white};
  padding: ${(props) => props.theme.spaces.xxs};
  cursor: ${(props) => (props.$readOnly ? 'default' : 'pointer')};

  ${(props) =>
    props.$isOpen &&
    css`
      border-bottom-left-radius: ${(props) => props.theme.borderRadiuses.none};
      border-bottom-right-radius: ${(props) => props.theme.borderRadiuses.none};
    `}
`;

const Label = styled.label<StyledLabelProps>`
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spaces.xxxs};
  font-size: ${(props) => props.theme.fonts.size.sm};
  font-weight: ${(props) =>
    props.$isBold
      ? props.theme.fonts.weight.semibold
      : props.theme.fonts.weight.normal};
  text-align: left;
  color: ${(props) =>
    props.$isSelected
      ? props.theme.colors.gray900
      : props.theme.colors.gray400};
  cursor: ${(props) => (props.$readOnly ? 'default' : 'pointer')};
`;

const ArrowDownIcon = styled.div<StyledDropdownProps>`
  margin-left: 4px;
  padding: ${(props) => props.theme.spaces.xxs} 0;
  display: flex;
  align-items: center;
  transform: ${(props) => (props.$isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 300ms
    ${(props) => props.theme.animations.customCubicBezier};

  /* & > img {
    width: 24px;
    height: 24px;
    object-fit: contain;
  } */
`;

const ResetButton = styled.div`
  padding: ${(props) => props.theme.spaces.xxs} 0;
  display: flex;
  align-items: center;
  z-index: 1;

  /* & > img {
    width: 24px;
    height: 24px;
    object-fit: contain;
  } */
`;

const Options = styled.div<StyledDropdownProps>`
  position: absolute;
  top: ${(props) => props.theme.sizes.dropdownHeight};
  left: 0;
  width: 100%;
  max-height: 270px;
  border-bottom-left-radius: ${(props) => props.theme.borderRadiuses.lg};
  border-bottom-right-radius: ${(props) => props.theme.borderRadiuses.lg};
  border: 1px solid ${(props) => props.theme.colors.gray100};
  border-top: none;
  overflow: hidden;
  overflow-y: auto;
  z-index: 1;

  ${roundedMinimalScrollbar}
`;

const Option = styled.button`
  width: 100%;
  padding: ${(props) => props.theme.spaces.xxs};
  display: flex;
  align-items: center;
  text-align: left;
  font-size: ${(props) => props.theme.fonts.size.sm};
  color: ${(props) =>
    props.disabled ? props.theme.colors.gray500 : props.theme.colors.gray900};
  background-color: ${(props) =>
    props.disabled ? props.theme.colors.gray100 : props.theme.colors.white};
  transition: background-color 100ms ease-in;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        background-color: ${(props) => props.theme.colors.gray50};
      }
    `};
`;
