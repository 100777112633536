import { CSSProperties, forwardRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Icon } from '@components/admin/@shared/atoms';
import { toast } from 'react-toastify';

export type DropdownOption = {
  name: string;
  label: string;
};

interface DropdownProps extends CSSProperties {
  inputName?: string;
  options?: DropdownOption[];
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (value: any) => void;
  onBlur?: () => void;
  onClickDropdown?: () => void;
  height?: string;
  readOnly?: boolean;
  toastMessage?: string;
}

const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(
  (
    {
      inputName,
      options,
      value = '',
      placeholder,
      disabled,
      onChange,
      onBlur,
      onClickDropdown,
      height,
      readOnly,
      toastMessage,
      ...styles
    },
    ref
  ) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [label, setLabel] = useState<string>(placeholder || '');

    const toastReadOnly = () => {
      if (toastMessage) {
        setIsDropdownOpen(false);
        toast.info(toastMessage);
      }
    };

    const handleDropdownClick = () => {
      if (onClickDropdown) {
        onClickDropdown();
        return;
      }

      if (!options?.length) return;

      if (readOnly) {
        toastReadOnly();
        return;
      }

      setIsDropdownOpen((prev) => !prev);
    };

    const handleOptionClick = (name: string, label: string) => {
      if (readOnly) {
        toastReadOnly();
        return;
      }

      onChange?.(name);
      setLabel(label);
      setIsDropdownOpen(false);
    };

    useEffect(() => {
      if (!options) return;

      const selected = options.find((option) => option.name === value);
      if (!selected) {
        setLabel(placeholder || '');
        return;
      }

      setLabel(selected.label);
    }, [value, options]);

    return (
      <Container ref={ref} style={styles}>
        <DropdownButton
          type="button"
          onClick={handleDropdownClick}
          onBlur={onBlur}
          $isOpen={isDropdownOpen}
          $height={height}
          disabled={!options?.length || disabled}
          $readOnly={readOnly}
        >
          <Label $isSelected={!!value && value.length > 0} $disabled={disabled}>
            {label}
          </Label>
          {!disabled && (
            <ArrowDownIcon $isOpen={isDropdownOpen}>
              <Icon name="ChevronDown" fill="gray400" />
            </ArrowDownIcon>
          )}
        </DropdownButton>
        {isDropdownOpen && !!options?.length && (
          <Options $height={height}>
            {options.map(({ name, label }) => (
              <Option onClick={() => handleOptionClick(name, label)} key={name}>
                {label}
              </Option>
            ))}
          </Options>
        )}
      </Container>
    );
  }
);
// DevTools에서 컴포넌트 이름 표시를 위해 설정
Dropdown.displayName = 'Dropdown';
export default Dropdown;

const Container = styled.div`
  position: relative;
  width: 100%;
  min-width: 120px;
  align-self: center;
  font-size: ${(props) => props.theme.fonts.size.sm};
  /* cursor: pointer; */
`;

interface StyledDropdownProps {
  $isOpen?: boolean;
  $height?: string;
  $readOnly?: boolean;
}

const DropdownButton = styled.button<StyledDropdownProps>`
  padding: ${(props) => props.theme.spaces.xxs};
  width: 100%;
  height: ${(props) => props.$height || props.theme.sizes.dropdownHeight};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.gray400};
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  background-color: ${(props) => props.theme.colors.white};
  transition: all 100ms ease-in;
  cursor: ${(props) =>
    props.disabled || props.$readOnly ? 'default' : 'pointer'};

  /* Disabled */
  &:disabled {
    border-color: ${(props) => props.theme.colors.gray100};
    color: ${(props) => props.theme.colors.gray300};
    background-color: ${(props) => props.theme.colors.gray50};
  }

  /* ReadOnly */
  ${(props) =>
    props.$readOnly &&
    `
      border-color: ${props.theme.colors.gray100};
      color: ${props.theme.colors.black};
      background-color: ${props.theme.colors.gray50};
    `}

  ${(props) =>
    props.$isOpen &&
    `
      border-color: ${props.theme.colors.black};
    `}

  & * {
    cursor: ${(props) =>
      props.disabled || props.$readOnly ? 'default' : 'pointer'};
  }
`;

interface StyledLabelProps {
  $isSelected?: boolean;
  $disabled?: boolean;
}

const Label = styled.label<StyledLabelProps>`
  display: block;
  width: 100%;
  margin: 0;
  text-align: left;
  color: ${(props) =>
    props.$isSelected
      ? props.theme.colors.gray900
      : props.theme.colors.gray400};
  /* cursor: pointer; */

  /* Disabled */
  ${(props) =>
    props.$disabled &&
    `
      color: ${props.theme.colors.gray300};
    `}
`;

const ArrowDownIcon = styled.div<StyledDropdownProps>`
  padding: ${(props) => props.theme.spaces.xxs} 0;
  display: flex;
  align-items: center;
  transform: ${(props) => (props.$isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 300ms
    ${(props) => props.theme.animations.customCubicBezier};

  & > img {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
`;

const Options = styled.ul<StyledDropdownProps>`
  padding: 10px;
  position: absolute;
  top: ${(props) => `calc(${props.theme.sizes.dropdownHeight} + 4px)`};
  left: 0;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  z-index: 1;
  background-color: ${(props) => props.theme.colors.white};
`;

const Option = styled.li`
  padding: 5px 10px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.gray900};
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.white};
  transition: all 100ms ease-in;
  cursor: pointer;

  height: 44px;

  &:hover {
    background-color: ${(props) => props.theme.colors.gray50};
  }

  /* & + & {
    margin-top: 10px;
  } */
`;
