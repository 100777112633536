import styled from 'styled-components';
import { Icon } from '@components/@shared';
import { Button } from '@components/admin/@shared/atoms';

interface CreateButtonProps {
  onClick?: () => void;
}

export default function CreateButton({ onClick }: CreateButtonProps) {
  return (
    <Button
      label={
        <LabelContainer>
          <Icon name="Add" fill="white" />
          <span>새로 만들기</span>
        </LabelContainer>
      }
      width="fit-content"
      onClick={onClick}
    />
  );
}

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
`;
