import styled from 'styled-components';
import { ProgressBarStep } from '../../molecules';
import React from 'react';
import {
  ProgressBarStepConfigType,
  ProgressBarStepKeyType,
} from '@customTypes/admin/careLabel/progress';

interface ProgressBarProps {
  currentStatus: ProgressBarStepKeyType; // 유연성을 위해 string 타입 사용
  statusList: [string, ProgressBarStepConfigType][];
  currentStatusConfig: ProgressBarStepConfigType;
  customStyles?: {
    wrapper?: React.CSSProperties;
    container?: React.CSSProperties;
  };
  width?: number;
  height?: number;
  borderWidth?: number;
  hasIconWrapper?: boolean;
}

export default function ProgressBar({
  currentStatus,
  statusList,
  currentStatusConfig,
  customStyles,
  width = 54,
  height = 54,
  borderWidth = 2,
  hasIconWrapper = true,
}: ProgressBarProps) {
  if (!currentStatus) {
    return;
  }

  const getProgressStatus = (configStatusCode: number) => {
    if (currentStatusConfig.statusCode === 7) {
      return 'inactive';
    }

    if (currentStatusConfig.statusCode === configStatusCode) {
      return 'active'; // 현재 단계
    }

    if (currentStatusConfig.statusCode < configStatusCode) {
      return 'inactive'; // 미래 단계
    }
    return 'default';
  };

  const getLineColor = (configStatusCode: number): string => {
    // 취소된 상태이거나, 현재/미래 단계인 경우 회색
    if (
      currentStatusConfig.statusCode === 7 ||
      currentStatusConfig.statusCode <= configStatusCode
    ) {
      return '#cccccc'; // 함수 내에서 theme 사용할 수 없어 theme.colors.gray200 하드코딩
    }
    // 지난 단계는 검정색
    return 'black';
  };

  return (
    <ProgressBarContainer>
      {statusList.map(([status, config], index) => (
        <React.Fragment key={index}>
          <ProgressBarStep
            orderStepIcon={
              !hasIconWrapper &&
              getProgressStatus(config.statusCode) === 'default'
                ? 'CheckCircleFilled'
                : config.icon
            }
            orderStatus={config.label}
            progressStatus={getProgressStatus(config.statusCode)}
            width={width}
            height={height}
            borderWidth={borderWidth}
            hasIconWrapper={hasIconWrapper}
          />
          {index !== statusList.length - 1 && (
            <ProgressBarStepLine
              $backgroundColor={getLineColor(config.statusCode)}
            />
          )}
        </React.Fragment>
      ))}
    </ProgressBarContainer>
  );
}

const ProgressBarContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface ProgressBarStepLineProps {
  $backgroundColor: string;
}

const ProgressBarStepLine = styled.div<ProgressBarStepLineProps>`
  position: relative;
  flex-grow: 1; /* 부모 컨테이너의 남은 공간에 맞게 늘어남 */
  height: 2px;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  min-width: 0; /* 최소 너비 제한 제거 */
`;
