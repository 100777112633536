import { z } from 'zod';
import { checkSchema } from '@lib/apis/user/@schema/core';

const careLabelImageFieldList = {
  brand: '브랜드 이름',
  name: '이미지 이름',
  img: '케어라벨 이미지',
};

// 케어라벨 이미지 등록
export type CareLabelImageUploadSchemaType = z.infer<
  typeof CareLabelImageUploadSchema
>;

const CareLabelImageUploadSchema = z.object({
  brand: z.string().min(1),
  name: z.string().min(1),
  img: z.custom<File>((file) => file instanceof File && file.size > 0, {
    message: '케어라벨 이미지를 등록해주세요.',
  }),
});

export const checkCareLabelImageUploadSchema = (formData: FormData) => {
  return checkSchema(
    formData,
    CareLabelImageUploadSchema,
    careLabelImageFieldList
  );
};
