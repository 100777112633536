import { FormEvent, forwardRef, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { REFRESH_ICON_2_URL, SEARCH_ICON_URL } from '@lib/constants/iconUrls';

interface SearchFormProps extends PropsWithChildren {
  onSearch: (e: FormEvent<HTMLFormElement>) => void;
}
function SearchForm({ onSearch, children }: SearchFormProps) {
  return <Form onSubmit={onSearch}>{children}</Form>;
}

interface SearchBarProps {
  inputName?: string;
  placeholder?: string;
  width?: string;
}

const SearchBar = forwardRef<HTMLInputElement | null, SearchBarProps>(
  function SearchBar({ inputName, placeholder, width }: SearchBarProps, ref) {
    return (
      <SearchInputContainer>
        <SearchInput
          type="search"
          ref={ref}
          name={inputName}
          placeholder={placeholder}
          $width={width}
        />
        <SearchIcon src={SEARCH_ICON_URL} alt="search icon" />
      </SearchInputContainer>
    );
  }
);

function SubmitButton() {
  return <SearchButton type="submit">검색</SearchButton>;
}

interface ResetButtonProps {
  onReset: () => void;
}

function ResetButton({ onReset }: ResetButtonProps) {
  return (
    <RefreshButton type="button" onClick={onReset}>
      <img src={REFRESH_ICON_2_URL} alt="refresh icon" />
    </RefreshButton>
  );
}

export default Object.assign(SearchForm, {
  SearchBar,
  SubmitButton,
  ResetButton,
});

const Form = styled.form`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spaces.xxs};
`;

const SearchInputContainer = styled.div`
  position: relative;
`;

interface SearchInputProps {
  $width?: string;
}

const SearchInput = styled.input<SearchInputProps>`
  width: ${(props) => props.$width || '300px'};
  height: 44px;
  padding: ${(props) => `${props.theme.spaces.xxs} ${props.theme.spaces.md}`};
  padding-left: ${(props) => props.theme.spaces.xxxxl};
  font-size: 14px;
  border: none;
  border-radius: ${(props) => props.theme.borderRadiuses.lg};
  background-color: ${(props) => props.theme.colors.white};

  &::placeholder {
    color: ${(props) => props.theme.colors.gray400};
  }

  /* 기본 X 아이콘 제거 */
  &::-ms-clear,
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
`;

const SearchIcon = styled.img`
  position: absolute;
  top: 50%;
  left: ${(props) => props.theme.spaces.md};
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  z-index: 1;
`;

const SearchButton = styled.button`
  padding: ${(props) => `0 ${props.theme.spaces.md}`};
  font-weight: ${(props) => props.theme.fonts.weight.semibold};
  height: 44px;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray100};
  border-radius: ${(props) => props.theme.borderRadiuses.lg};
`;

const RefreshButton = styled.button`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;

  & > img {
    display: block;
    width: 100%;
    height: 100%;
  }
`;
