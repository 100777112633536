import styled from 'styled-components';
import { Icon } from '@components/@shared';

interface RemoveButtonProps {
  onRemove: () => void;
}

export default function RemoveButton({ onRemove }: RemoveButtonProps) {
  return (
    <Button type="button" onClick={onRemove}>
      <Icon name="Delete" />
    </Button>
  );
}

const Button = styled.button`
  display: block;
  padding: ${(props) => props.theme.spaces.xxs} 0;
`;
