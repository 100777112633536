import { CSSProperties } from 'react';
import styled from 'styled-components';

interface BoxContainerProps extends CSSProperties {
  children: React.ReactNode;
}

function BoxContainer({ children, ...styles }: BoxContainerProps) {
  return <Container style={styles}>{children}</Container>;
}

function Title({ children }: BoxContainerProps) {
  return <ContainerTitle>{children}</ContainerTitle>;
}

export default Object.assign(BoxContainer, {
  Title,
});

const Container = styled.div`
  padding: 16px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const ContainerTitle = styled.h3`
  display: block;
  font-size: 18px;
  font-weight: 700;
`;
