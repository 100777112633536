import { ProductMaterial } from '@customTypes/admin';
import { GENDER_OPTIONS } from '@lib/constants/genderOptions';
import { z } from 'zod';

export type DppProductSizeSchemaType = z.infer<typeof productSizeSchema>;

const productSizeSchema = z.object({
  size_name: z
    .string({ message: '사이즈 호칭을 입력해 주세요' })
    .min(1, '사이즈 호칭을 입력해 주세요'),
  size_value: z
    .string({ message: '사이즈 값을 입력해 주세요' })
    .min(1, '사이즈 값을 입력해 주세요'),
  // 총 기장
  size_outseam: z.string().nullable().optional(),
  // 어깨 너비
  size_shoulder_width: z.string().nullable().optional(),
  // 가슴 단면
  size_chest_width: z.string().nullable().optional(),
  // 소매 길이
  size_sleeve_length: z.string().nullable().optional(),
  // 허리 단면
  size_waist_width: z.string().nullable().optional(),
  // 밑위
  size_raise_length: z.string().nullable().optional(),
  // 엉덩이 단면
  size_back_rise: z.string().nullable().optional(),
  // 허벅지 단면
  size_thigh_width: z.string().nullable().optional(),
  // 밑단 단면
  size_leg_opening: z.string().nullable().optional(),
});

export type ProductMaterialSchemaType = z.infer<typeof materialSchema>;

const materialSchema = z.object({
  // 소재 이름
  material_name: z
    .string({ message: '소재 이름을 선택해 주세요' })
    .min(1, '소재 이름을 선택해 주세요'),
  // 소재 비율
  material_value: z
    .string({ message: '소재 비율을 입력해 주세요' })
    .min(1, '소재 비율을 입력해 주세요'),
});

// 제품 정보
export type ProductInfoSchemaType = z.infer<typeof productInfoSchema>;

export const productInfoSchema = z.object({
  // 제품 이름
  name: z
    .string({
      message: '제품 이름을 입력해 주세요',
    })
    .min(1, '제품 이름을 입력해 주세요'),
  // 제품 카테고리
  category: z
    .string({ message: '제품 카테고리를 선택해 주세요' })
    .min(1, '제품 카테고리를 선택해 주세요'),
  // 제품 색상
  color: z
    .string({
      message: '제품 색상을 입력해 주세요',
    })
    .min(1, '제품 색상을 입력해 주세요'),
  // 제품 설명
  description: z
    .string({
      message: '제품 설명을 입력해 주세요',
    })
    .min(1, '제품 설명을 입력해 주세요'),
  // 제품 시즌
  season: z
    .string({
      message: '제품 시즌을 입력해 주세요',
    })
    .min(1, '제품 시즌을 입력해 주세요'),
  // 제품 출시 날짜
  release_date: z
    .string({
      message: '제품 출시 날짜를 입력해 주세요',
    })
    .min(1, '제품 출시 날짜를 입력해 주세요'),
  // 스큐 코드
  sku_code: z
    .string({
      message: '스큐 코드를 입력해 주세요',
    })
    .min(1, '스큐 코드를 입력해 주세요'),
  // 소재
  materials: z
    .array(materialSchema, {
      message: '최소 1개 이상의 소재를 입력해 주세요',
    })
    .min(1, '최소 1개 이상의 소재를 입력해 주세요')
    .refine(
      (materials) => {
        const sum = materials.reduce(
          (acc, curr) => acc + Number(curr.material_value),
          0
        );

        return sum === 100;
      },
      { message: '소재 비율의 총합이 100%가 되어야 합니다' }
    ),
  // 부자재
  component: z
    .string({
      message: '부자재를 입력해 주세요',
    })
    .min(1, '부자재를 입력해 주세요'),
  // 사이즈 국가 코드
  size_country_code: z
    .string({ message: '사이즈 국가 코드를 선택해 주세요' })
    .min(1, '사이즈 국가 코드를 선택해 주세요'),
  // 사이즈 단위
  size_unit: z
    .string({ message: '사이즈 단위를 선택해 주세요' })
    .min(1, '사이즈 단위를 선택해 주세요'),
  // 사이즈
  size: z
    .array(productSizeSchema, {
      message: '최소 1개 이상의 사이즈를 입력해 주세요',
    })
    .min(1, '최소 1개 이상의 사이즈를 입력해 주세요')
    .refine(
      (sizes) => {
        if (sizes.length <= 1) return true;

        const firstSize = sizes[0] as DppProductSizeSchemaType;
        const pattern = (
          Object.keys(firstSize) as Array<keyof DppProductSizeSchemaType>
        ).reduce(
          (acc, key) => {
            acc[key] = firstSize[key] !== null && firstSize[key] !== undefined;
            return acc;
          },
          {} as Record<keyof DppProductSizeSchemaType, boolean>
        );

        return sizes.every((size) =>
          Object.keys(size).every(
            (key) =>
              pattern[key as keyof DppProductSizeSchemaType] ===
              (size[key as keyof DppProductSizeSchemaType] !== null &&
                size[key as keyof DppProductSizeSchemaType] !== undefined)
          )
        );
      },
      { message: '모든 사이즈는 동일한 항목을 가져야 합니다' }
    ),
  // 제품 사진
  imgs: z
    .custom<FileList | File[]>(
      (files) =>
        files instanceof FileList ||
        (Array.isArray(files) && files.every((file) => file instanceof File)),
      {
        message: '유효한 이미지 파일을 등록해 주세요',
      }
    )
    .optional(),
  // 고객 성별
  target_gender: z
    .string()
    .refine((value) => GENDER_OPTIONS.some((option) => option.name === value), {
      message: '유효한 성별을 선택해 주세요',
    })
    .optional(),
  // 고객 연령
  target_age: z.string().optional(),
  // 제품 무게
  product_weight: z.string().optional(),
  // 패키지 무게
  package_weight: z.string().optional(),
  // 무게 단위
  weight_unit: z.string().optional(),
  // 방수 정보
  water_properties: z.string().optional(),
  // GTIN 번호
  gtin_number: z.string().optional(),
  // Article 번호
  article_number: z.string().optional(),
  // PO 번호
  po_number: z.string().optional(),
  // 국제 분류 코드 시스템
  commodity_code_system: z.string().optional(),
  // 국제 분류 코드 값
  commodity_code_value: z.string().optional(),
  // 의류 패턴
  pattern: z.string().optional(),
});

// 재료 정보
export type MaterialInfoSchemaType = z.infer<typeof materialInfoSchema>;

const materialInfoSchema = z.object({
  // 재활용 가능 여부
  recycle: z
    .string({
      message: '재활용 가능 여부를 선택해 주세요',
    })
    .min(1, '재활용 가능 여부를 선택해 주세요')
    .refine((value) => value === 'true' || value === 'false'),
  // 재활용 가능한 부분
  recycle_input_src: z.string().optional(),
  // 재활용 가능한 부분의 비율
  recycle_percentage: z
    .string({
      message: '재활용 가능한 부분의 비율을 입력해 주세요',
    })
    .refine(
      (value) => {
        const number = Number(value);
        return Number.isInteger(number) && number >= 0 && number <= 100;
      },
      {
        message:
          '재활용 가능한 부분의 비율은 0 이상 100 이하의 정수여야 합니다',
      }
    )
    .optional(),
  // 재활용 소재 사용 여부
  recycled: z
    .string({
      message: '재활용 소재 사용 여부를 선택해 주세요',
    })
    .min(1, '재활용 소재 사용 여부를 선택해 주세요')
    .refine((value) => value === 'true' || value === 'false'),
  // 사용된 재활용 소재 비율
  recycled_percentage: z
    .string()
    .refine(
      (value) => {
        const number = Number(value);
        return Number.isInteger(number) && number >= 0 && number <= 100;
      },
      { message: '사용된 재활용 소재 비율은 0 이상 100 이하의 정수여야 합니다' }
    )
    .optional(),
  // 가죽 사용 여부
  leather: z
    .string({
      message: '가죽 사용 여부를 선택해 주세요',
    })
    .min(1, '가죽 사용 여부를 선택해 주세요')
    .refine((value) => value === 'true' || value === 'false'),
  // 가죽 종류
  leather_species: z.string().optional(),
  // 가죽 등급
  leather_grade: z.string().optional(),
  // 프린팅 잉크 타입
  print_ink_type: z.string().optional(),
  // 염색 클래스
  dye_class: z.string().optional(),
  // 염색 클래스 규격
  dye_class_standard: z.string().optional(),
  // 봉제실 구성 성분
  sweing_thread_content: z.string().optional(),
  // 마감 방법
  finishes: z.string().optional(),
});

// 공급망 정보
export type SupplierInfoSchemaType = z.infer<typeof supplierInfoSchema>;

const supplierInfoSchema = z.object({
  // 의류 제작 지역
  origin_confection: z
    .string({
      message: '의류 제작 지역을 입력해 주세요',
    })
    .min(1, '의류 제작 지역을 입력해 주세요'),
  // 의류 소비 지역
  origin_consumption: z
    .string({
      message: '의류 소비 지역을 입력해 주세요',
    })
    .min(1, '의류 소비 지역을 입력해 주세요'),
  // 의류 염색 및 프린팅 지역
  origin_dyeing_printing: z
    .string({
      message: '의류 염색 및 프린팅 지역을 입력해 주세요',
    })
    .min(1, '의류 염색 및 프린팅 지역을 입력해 주세요'),
  // 의류 원사 제작 지역
  origin_weaving_knitting: z.string().optional(),
});

// 규정 준수 정보
export type RegulationsInfoSchemaType = z.infer<typeof regulationsInfoSchema>;

const regulationsInfoSchema = z.object({
  // 유해물질 포함 여부
  harmful_substances: z.string().optional(),
  // 유해물질 정보
  harmful_substances_info: z.string().optional(),
  // 미세물질 정보
  microfibers: z.string().optional(),
  // 인증 정보
  certification: z.string().optional(),
});

// 순환성 정보
export type CircularityInfoSchemaType = z.infer<
  typeof sustainabilityInfoSchema
>;

const circularityInfoSchema = z.object({
  // 의류 수거 지침 방법
  instruction_take_back: z.string().optional(),
  // 의류 재활용 지침 방법
  instruction_recycle: z.string().optional(),
  // 의류 분해 방법
  instruction_disassembly: z.string().optional(),
  // 의류 수선 방법
  instruction_repair: z.string().optional(),
});

// 케어 정보
export type CareInfoSchemaType = z.infer<typeof careInfoSchema>;

const careInfoSchema = z.object({
  // 케어라벨 사진
  carelabel_img: z
    .custom<FileList | File[]>(
      (files) =>
        files instanceof FileList ||
        (Array.isArray(files) && files.every((file) => file instanceof File)),
      {
        message: '유효한 이미지 파일을 등록해 주세요',
      }
    )
    .refine((files) => files.length <= 1, {
      message: '케어라벨 사진은 최대 1장까지 등록 가능합니다.',
    })
    .optional(),
  // 케어 정보
  carelabel_text: z.string().optional(),
});

// 지속가능성 정보
export type SustainabilityInfoSchemaType = z.infer<
  typeof sustainabilityInfoSchema
>;

const sustainabilityInfoSchema = z.object({
  // 소셜 브랜드 선언문
  social_brand_statement: z.string().optional(),
});

// 식별자 정보
export type IdentifierInfoSchemaType = z.infer<typeof identifierInfoSchema>;

const identifierInfoSchema = z.object({
  // 식별자 위치 정보
  identifier_location: z
    .string({
      message: '케어라벨 부착 위치를 입력해 주세요',
    })
    .min(1, '케어라벨 부착 위치를 입력해 주세요'),
});

/**
 * DPP 생성 스키마
 */
export type DppCreateSchemaType = z.infer<typeof dppCreateSchema>;

export const dppCreateSchema = productInfoSchema
  .merge(materialInfoSchema)
  .merge(supplierInfoSchema)
  .merge(regulationsInfoSchema)
  .merge(circularityInfoSchema)
  .merge(careInfoSchema)
  .merge(identifierInfoSchema)
  .merge(sustainabilityInfoSchema);

/**
 * DPP 수정 스키마
 */
export type DppUpdateSchemaType = z.infer<typeof dppUpdateSchema>;

export const dppUpdateSchema = dppCreateSchema;
