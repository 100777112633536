import { IconName } from '@components/@shared';
import { Icon } from '@components/@shared';
import styled from 'styled-components';

interface TapButtonProps {
  label: string;
  onClick?: () => void;
  isActive: boolean;
  iconName?: IconName;
  fill?: string;
}

export default function TapButton({
  label,
  onClick,
  isActive,
  iconName,
  fill,
  ...styles
}: TapButtonProps) {
  return (
    <ItemTapButton onClick={onClick} $isActive={isActive} style={{ ...styles }}>
      {iconName ? <Icon name={iconName} fill={fill} /> : null}
      <span>{label}</span>
    </ItemTapButton>
  );
}

interface ItemTapButtonProps {
  $isActive: boolean;
}

const ItemTapButton = styled.button<ItemTapButtonProps>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: fit-content;
  padding: 10px 18px;
  height: 50px;
  color: ${(props) =>
    props.$isActive ? props.theme.colors.black : props.theme.colors.gray400};
  font-weight: ${(props) => (props.$isActive ? 'bold' : 'normal')};
  border: none;
  background: none;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: ${(props) =>
      props.$isActive ? props.theme.colors.black : 'transparent'};
  }

  &:hover {
    color: ${(props) => props.theme.colors.gray600};
  }
`;
