import imageCompression from 'browser-image-compression';
import { getFontEmbedCSS, toPng } from 'html-to-image';
import {
  CareLabelDesignData,
  CareLabelDetailData,
  CareLabelPage,
  FontItem,
  FontListData,
} from '@customTypes/admin';
import { embedFontsInDOM, getUsedFontList } from './font';

export const utf8ToBase64 = (str: string) => {
  // UTF-8 문자열을 Base64로 변환
  return btoa(
    new TextEncoder()
      .encode(str)
      .reduce((data, byte) => data + String.fromCharCode(byte), '')
  );
};

/**
 * base64 이미지 데이터를 File 객체로 변환해주는 함수
 *
 * @param dataUrl base64 포맷 이미지
 * @param filename 이미지 파일 이름
 * @returns {File} 이미지 파일
 */
export const dataURLtoFile = (
  dataUrl: string,
  filename: string
): File | undefined => {
  const arr = dataUrl.split(',');
  if (arr.length < 2) return;

  const mimeArr = arr[0].match(/:(.*?);/);
  if (!mimeArr || mimeArr.length < 2) return;

  const mime = mimeArr[1];
  const bstr = atob(arr[1]);

  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const convertFileToBase64 = async (file: File) => {
  function getBase64(file: File): Promise<string> {
    const reader = new FileReader();

    return new Promise((resolve) => {
      reader.onload = (ev: ProgressEvent<FileReader>) => {
        if (!ev.target) return;

        resolve(String(ev.target.result));
      };

      reader.readAsDataURL(file);
    });
  }

  return await getBase64(file);
};

/**
 * 이미지 압축 함수
 *
 * @param files 압축할 이미지 파일 배열
 * @param maxSizeMB 최대 크기 (MB)
 * @param maxWidthOrHeight 최대 너비 또는 높이
 *
 * @returns {File[]} 압축된 이미지 파일 배열
 */
export const compressImageFiles = async (
  file: File,
  maxSizeMB: number = 5
  // maxWidthOrHeight: number = 1280
) => {
  const options = {
    maxSizeMB,
    // maxWidthOrHeight,
    useWebworker: true,
    fileType: 'image/webp',
  };

  const newFileName = `${file.name.split('.')[0]}.webp`;
  const compressedFile = await imageCompression(file, options);

  // Blob을 File로 변환하며 확장자 설정
  return new File([compressedFile], newFileName, { type: 'image/webp' });
};

/**
 * 이미지의 비율을 유지하면서 새로운 크기를 계산하는 함수
 */
export const calculateImageDimensions = (
  imgUrl: string,
  baseWidth: number = 10,
  maxHeight: number = 20
): Promise<{ w: number; h: number }> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = imgUrl;

    img.onload = () => {
      const aspectRatio = img.width / img.height;

      let width = baseWidth;
      let height = Math.round(baseWidth / aspectRatio);

      // 높이가 maxHeight를 초과하는 경우
      // 높이를 maxHeight로 고정하고 너비를 비율에 맞게 재계산
      if (height > maxHeight) {
        height = maxHeight;
        width = Math.round(height * aspectRatio);
      }

      resolve({ w: width, h: height });
    };
  });
};

type CaptureElementAsImageProps = {
  elemRef: React.RefObject<HTMLElement>;
  fileName: string;
  backgroundColor?: string;
  fontList: FontItem[];
};

interface CaptureMultipleElementsAsImageProps {
  elemRefs: React.RefObject<HTMLDivElement>[];
  fileNamePrefix: string;
  backgroundColor: string;
  fontList: FontItem[];
  designData: CareLabelDesignData;
  type?: 'file' | 'base64';
}

// /**
//  * 이미지 캡쳐 함수 (ex. 썸네일 생성)
//  *
//  * @description html2canvas에서 html-to-image로 교체.
//  *  html2canvas는 transform 미지원하여 레이아웃이 제대로 반영되지 않음
//  *
//  * @param elemRef 이미지를 캡쳐할 HTML 요소
//  * @returns {Blob} 캡쳐된 이미지
//  */
// export const captureElementAsImage = async ({
//   elemRef,
//   fileName,
//   backgroundColor,
//   fontList,
// }: CaptureElementAsImageProps) => {
//   const elem = elemRef?.current;

//   if (!elem) {
//     console.error('elem not found!');
//     return;
//   }

//   // 1. 폰트 로드
//   const styleTag = embedFontsInDOM(fontList);
//   await document.fonts.ready;

//   try {
//     // PNG 데이터 URL 생성
//     const dataUrl = await toPng(elem, {
//       // 배경색 지정
//       backgroundColor,
//       // 캐싱 방지
//       cacheBust: true,
//       // 폰트 포함
//       skipFonts: false,
//     });
//     // 데이터 URL을 File로 변환
//     const file = dataURLtoFile(dataUrl, fileName);

//     return file;
//   } catch (error) {
//     console.error('Failed to capture image:', error);
//   } finally {
//     // 폰트 제거
//     document.head.removeChild(styleTag);
//   }
// };

/**
 * 여러 요소를 동시에 이미지로 캡쳐하는 함수
 *
 * @param elemRefs 캡쳐할 HTML 요소들의 ref 배열
 * @param fileNamePrefix 파일명 접두사
 * @returns {File[] | string[]} 캡쳐된 이미지 파일 배열
 */
export const captureMultipleElementsAsImage = async ({
  elemRefs,
  fileNamePrefix,
  backgroundColor,
  fontList,
  designData,
  type = 'file',
}: CaptureMultipleElementsAsImageProps): Promise<File[] | string[]> => {
  if (!elemRefs.length) {
    console.error('No elements to capture!');
    return [];
  }

  // 1. 폰트 로드 (한 번만 수행)
  // const styleTag = embedFontsInDOM(fontList);
  // await document.fonts.ready;
  let styleTag;
  const usedFontList = getUsedFontList(fontList, designData.pages);

  try {
    // CORS 이슈를 피하기 위해 폰트 로드 방식 변경
    if (usedFontList?.length) {
      styleTag = embedFontsInDOM(usedFontList);

      // 폰트 로드 완료 대기
      // await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    // 2. 모든 요소 동시에 캡쳐
    const capturedFiles = await Promise.all(
      elemRefs.map(async (elemRef, index) => {
        const elem = elemRef.current;
        if (!elem) return null;

        const dataUrl = await toPng(elem, {
          backgroundColor: designData.settings.backgroundColor,
          cacheBust: true,
          skipFonts: false,
        });

        if (type === 'file') {
          return dataURLtoFile(dataUrl, `${fileNamePrefix}`);
        }

        return dataUrl;
      })
    );

    // null 값 필터링
    return capturedFiles.filter((file): file is File => file !== null);
  } catch (error) {
    console.error('Failed to capture images:', error);
    return [];
  } finally {
    // 폰트 제거
    if (styleTag) {
      document.head.removeChild(styleTag);
    }
  }
};
