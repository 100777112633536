import { z } from 'zod';

export const demoSchema = z.object({
  email: z
    .string({ message: '이메일을 입력해주세요' })
    .email('올바른 이메일 형식이 아닙니다'),
  name: z.string().min(1, '이름을 입력해 주세요'),
  phone: z
    .string()
    .min(1, '전화번호를 입력해 주세요')
    .regex(/^[0-9]{11}$/, '전화번호는 11자리 숫자로 입력해 주세요'),
});

export type DemoFormData = z.infer<typeof demoSchema>;
