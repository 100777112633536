import { Dispatch, FormEvent, SetStateAction, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { resetAllInputsValue } from '@lib/utils/form';
import { Icon } from '@components/@shared';

interface IndexedObj {
  [key: string]: string;
}

export interface SearchInput {
  name: string;
  placeholder?: string;
  label?: string;
}

interface SearchInputFormProps {
  inputList: SearchInput[];
  initKeywords: IndexedObj;
  onSetKeywords: Dispatch<SetStateAction<any>>;
  translationScriptPath?: string;
}

export default function SearchInputForm({
  inputList,
  initKeywords,
  onSetKeywords,
  translationScriptPath,
}: SearchInputFormProps) {
  const { t } = useTranslation(translationScriptPath || 'admin/dppList');
  const searchInputListRef = useRef<(HTMLInputElement | null)[]>([]);

  // 검색어 초기화해주는 함수
  const handleKeywordReset = () => {
    resetAllInputsValue(searchInputListRef);
    onSetKeywords(initKeywords);
  };

  // 검색어 저장해주는 함수
  const handleKeywordSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    for (const key of formData.keys()) {
      const keyword = formData.get(key) as string;
      const nextKeyword =
        keyword !== '' && typeof keyword === 'string' ? keyword : '';
      onSetKeywords((prev: typeof initKeywords) => ({
        ...prev,
        [key]: nextKeyword,
      }));
    }
  };

  return (
    <Form onSubmit={handleKeywordSearch}>
      <SearchFields>
        <SearchInputList>
          {inputList.map((input, index) => (
            <SearchInputContainer key={input.name}>
              <SearchInputLabel htmlFor={input.name}>
                {t(`searchInput.${input.name}.label`) || input.name}
              </SearchInputLabel>
              <SearchInput
                type="search"
                id={input.name}
                ref={(el) => (searchInputListRef.current[index] = el)}
                name={input.name}
                placeholder={t(`searchInput.${input.name}.placeholder`)}
              />
            </SearchInputContainer>
          ))}
        </SearchInputList>
        <ButtonsContainer>
          <RefreshButton type="button" onClick={handleKeywordReset}>
            <Icon name="Refresh" fill="gray200" width={32} height={32} />
          </RefreshButton>
          <SearchButton type="submit">
            <Icon name="MagnifierSearch" fill="white" width={20} height={20} />
          </SearchButton>
        </ButtonsContainer>
      </SearchFields>
    </Form>
  );
}

const Form = styled.form`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spaces.xxxs};
`;

const SearchFields = styled.div`
  padding: ${(props) =>
    `${props.theme.spaces.xxxxs} ${props.theme.spaces.xxs}`};
  padding-left: ${(props) => props.theme.spaces.xs};
  width: 620px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.borderRadiuses.lg};
  overflow: hidden;
`;

const SearchInputList = styled.div`
  width: 460px;
  display: flex;
  gap: ${(props) => props.theme.spaces.xxs};
`;

const SearchInputContainer = styled.div`
  flex: 1 0 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid ${(props) => props.theme.colors.gray100};
`;

const SearchInputLabel = styled.label`
  font-size: ${(props) => props.theme.fonts.size.xs};
  font-weight: ${(props) => props.theme.fonts.weight.medium};
  color: ${(props) => props.theme.colors.gray900};
`;

const SearchInput = styled.input`
  padding: 0;
  width: 100%;
  font-size: ${(props) => props.theme.fonts.size.sm};
  background-color: ${(props) => props.theme.colors.white};
  border: none;

  &::placeholder {
    color: ${(props) => props.theme.colors.gray400};
  }

  /* 기본 X 아이콘 제거 */
  &::-ms-clear,
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spaces.xxs};
`;

const RefreshButton = styled.button`
  padding: ${(props) => props.theme.spaces.xxxxs};
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};

  & > img {
    display: block;
    width: 100%;
    width: 100%;
    object-fit: contain;
  }
`;

const SearchButton = styled.button`
  padding: 6px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${(props) => props.theme.fonts.weight.semibold};
  background-color: ${(props) => props.theme.colors.gray900};
  border-radius: ${(props) => props.theme.borderRadiuses.full};

  & > img {
    display: block;
    width: 100%;
    width: 100%;
    object-fit: contain;
  }
`;
