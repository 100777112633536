import { Icon } from '@components/admin/@shared/atoms';
import styled from 'styled-components';

interface StarRatingProps {
  total?: number;
  current: number;
}

export default function StarRating({ total = 3, current }: StarRatingProps) {
  return (
    <Container>
      {Array.from({ length: current }, (_, index) => (
        <Icon
          name="StarFill"
          width={16}
          height={16}
          fill="gray400"
          key={`star-fill-${index}`}
        />
      ))}
      {Array.from({ length: total - current }, (_, index) => (
        <Icon
          name="Star"
          width={16}
          height={16}
          fill="gray400"
          key={`star-${index}`}
        />
      ))}
    </Container>
  );
}

const Container = styled.div`
  /* display: flex;
  align-items: center;
  gap: 4px; */
`;
