export type FooterContent = {
  title: string;
  items: FooterContentItem[];
};

export type FooterContentItem = {
  name: string;
  title?: string;
  value: string;
  href?: string;
};

export const BUSINESS_LICENSE_INFO: FooterContent = {
  title: '윤회(주) Yune inc.',
  items: [
    { name: 'ceoName', title: '대표이사', value: '노힘찬' },
    { name: 'companyName', title: '상호명', value: '윤회주식회사' },
    { name: 'businessNumber', title: '사업자번호', value: '373-87-02339' },
    {
      name: 'businessLicenseNumber',
      title: '통신판매업신고증',
      value: '2022-서울서대문-1411',
    },
  ],
} as const;

export const CONTACT_INFO: FooterContent = {
  title: '연락처',
  items: [
    { name: 'phoneNumber', title: '전화번호', value: '070-7782-7601' },
    { name: 'email', title: '이메일', value: 'careid@careid.center' },
    {
      name: 'address',
      title: '주소',
      value: `(HQ) 서울특별시 서대문구 연희로 11가길 32\n(Office) 서울특별시 용산구 이태원동 141-3`,
    },
  ],
} as const;

export const FAMILY_SITE_INFO: FooterContent = {
  title: '패밀리 사이트',
  items: [
    { name: 'yune', value: 'YUNE', href: 'https://careid.center' },
    { name: 'careId', value: 'CARE ID', href: 'https://careid.center' },
    { name: 'loveTrain', value: 'LOVE TRAIN', href: 'https://careid.center' },
    { name: 'gaudi', value: 'GAUDI', href: 'https://careid.center' },
  ],
} as const;

/**
 * @todo 추후 상수로 변경 필요
 */
export const TERMS_AND_CONDITIONS: FooterContent = {
  title: '이용약관',
  items: [
    {
      name: 'privacyPolicy',
      value: '개인정보처리방침',
      href: '/terms/privacy-policy',
    },
    { name: 'service', value: '서비스이용약관', href: '/terms/service' },
    { name: 'refund', value: '청약철회방침', href: '/terms/refund' },
  ],
} as const;
