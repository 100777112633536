import { z } from 'zod';
import { checkSchema } from '@lib/apis/user/@schema/core';

const productImageFieldList = {
  brand: '브랜드 이름',
  name: '이미지 이름',
  img: '제품 이미지',
};

// 제품 이미지 등록
export type ProductImageUploadSchemaType = z.infer<
  typeof ProductImageUploadSchema
>;

const ProductImageUploadSchema = z.object({
  brand: z.string().min(1),
  name: z.string().min(1),
  img: z.custom<File>((file) => file instanceof File && file.size > 0, {
    message: '제품 이미지를 등록해주세요.',
  }),
});

export const checkProductImageUploadSchema = (formData: FormData) => {
  return checkSchema(formData, ProductImageUploadSchema, productImageFieldList);
};
