import { FormEvent, MouseEvent, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import styled from 'styled-components';
import { allowScroll, preventScroll } from '@lib/utils';
import { useFormField } from '@lib/hooks';
import {
  careLabelNameUpdateSchema,
  CareLabelNameUpdateSchemaType,
} from '@lib/apis/admin/@schema';
import { Button, Textarea } from '@components/admin/@shared/atoms';

const portalElement = document.getElementById('modal') as HTMLElement;

interface NameInputModalProps {
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  onSubmit: (name: string) => void;
  onClickSecondaryButton?: () => void;
  onCloseModal: () => void;
  title?: string;
  placeholder?: string;
  description?: string;
  maxLength?: number;
}

export default function TextAreaModal({
  primaryButtonLabel,
  secondaryButtonLabel,
  onSubmit,
  onClickSecondaryButton,
  onCloseModal,
  title,
  placeholder,
  description,
  maxLength,
}: NameInputModalProps) {
  const [scrollY, setScrollY] = useState<string>('0');

  const methods = useForm<CareLabelNameUpdateSchemaType>({
    resolver: zodResolver(careLabelNameUpdateSchema),
    mode: 'onChange',
  });

  const {
    register,
    watch,
    formState: { errors, isValid },
    setValue,
    clearErrors,
  } = methods;

  const { handleValueReset } = useFormField<CareLabelNameUpdateSchemaType>({
    setValue,
    clearErrors,
  });

  const handleBackgroundClick = (e: MouseEvent) => {
    if (e.target !== e.currentTarget) return;

    onCloseModal();
  };

  const handleSubmitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const currentNameValue = watch('name');

    onSubmit?.(currentNameValue);
    onCloseModal();
  };

  const handleSecondaryButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    onClickSecondaryButton?.();
    onCloseModal();
  };

  // 모달이 띄워졌을 때 스크롤 방지 (화면 고정)
  useEffect(() => {
    const prevScrollY = preventScroll();
    setScrollY(`${window.scrollY}px`);

    return () => {
      allowScroll(prevScrollY);
    };
  }, []);

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  return createPortal(
    <Wrapper onClick={handleBackgroundClick} $scrollY={scrollY}>
      <Container onSubmit={handleSubmitForm}>
        <ContentsContainer>
          {/* Contents */}
          <Title>{title}</Title>
          <Textarea
            {...register('name')}
            inputName="name"
            value={watch('name')}
            placeholder={placeholder}
            onReset={() => handleValueReset('name')}
            maxLength={maxLength}
          />
          {description ? <Description>{description}</Description> : null}
          <TextCounter>
            <span>{watch('name')?.length || 0}</span> / <span>{maxLength}</span>
          </TextCounter>
        </ContentsContainer>
        <div>
          <Button
            label={primaryButtonLabel || '확인'}
            type="submit"
            disabled={!isValid}
          />
          <CancelButton type="button" onClick={handleSecondaryButtonClick}>
            {secondaryButtonLabel || '취소'}
          </CancelButton>
        </div>
      </Container>
    </Wrapper>,
    portalElement
  );
}

interface WrapperProps {
  $scrollY: string;
}

const Wrapper = styled.div<WrapperProps>`
  position: fixed;
  top: ${(props) => props.$scrollY};
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(28, 28, 28, 0.8);
  z-index: 9998;
  cursor: pointer;
`;

const Container = styled.form`
  padding: 32px 40px;
  width: 500px;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.white};
  cursor: default;
`;

const ContentsContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 20px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
`;

const TextCounter = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.gray400};
`;

const Description = styled.p`
  margin-top: 8px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.gray400};
`;

const CancelButton = styled.button`
  margin-top: 8px;
  padding: 4px 10px;
  width: 100%;
  display: block;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray400};
`;
