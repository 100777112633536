import { useEffect, MouseEvent, PropsWithChildren, useRef } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { allowScroll, preventScroll } from '@lib/utils/scroll';
import { hiddenScrollbar } from '@lib/styles/scrollbarStyles';

const portalElement = document.getElementById('modal') as HTMLElement;

interface ModalFullSizedProps extends PropsWithChildren {
  onCloseModal: () => void;
}

export default function ModalFullSized({
  children,
  onCloseModal,
}: ModalFullSizedProps) {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleModelClose = (e: MouseEvent) => {
    if (e.target !== e.currentTarget) return;

    onCloseModal();
  };

  // 모달이 띄워졌을 때 스크롤 방지 (화면 고정)
  useEffect(() => {
    const prevScrollY = preventScroll();

    return () => {
      allowScroll(prevScrollY);
    };
  }, []);

  useEffect(() => {
    return () => {
      const wrapperEl = wrapperRef?.current;
      if (!wrapperEl) return;

      wrapperEl.scrollTop = 0;
    };
  }, [wrapperRef?.current]);

  return createPortal(
    <Wrapper ref={wrapperRef}>
      <Container>{children}</Container>
    </Wrapper>,
    portalElement
  );
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  height: -webkit-fill-available;
  height: fill-available;
  background-color: ${(props) => props.theme.colors.white};
  z-index: 1900;
  overflow-y: auto;

  ${hiddenScrollbar}
`;

const Container = styled.div`
  height: 100%;
  min-height: 100dvh;
`;
