import { atom } from 'jotai';
import { changeLanguage } from 'i18next';

export const languageAtom = atom<string>(
  localStorage.getItem('i18nextLng') ?? ''
);

export const languageAtomWithPersistence = atom(
  (get) => get(languageAtom),
  (get, set, lang?: string) => {
    if (lang) {
      set(languageAtom, lang);
      localStorage.setItem('i18nextLng', lang);
      changeLanguage(lang);
    } else {
      set(languageAtom, '');
      localStorage.removeItem('i18nextLng');
      changeLanguage('en');
    }
  }
);

export const brandLogoImageUrlAtom = atom<string>(
  localStorage.getItem('brandLogoUrl') ?? ''
);

export const brandLogoImageUrlAtomWithPersistence = atom(
  (get) => get(brandLogoImageUrlAtom),
  (get, set, newUrl?: string) => {
    if (newUrl) {
      set(brandLogoImageUrlAtom, newUrl);
      localStorage.setItem('brandLogoUrl', newUrl);
    } else {
      set(brandLogoImageUrlAtom, '');
      localStorage.removeItem('brandLogoUrl');
    }
  }
);
