import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Icon } from '@components/@shared';

export type DropdownOption = {
  name: string;
  labelKey: string;
};

interface DropdownProps {
  options: DropdownOption[];
  value: string;
  onChangeValue: (value: string) => void;
  placeholder?: string;
  readOnly?: boolean;
  width?: string;
}

export default function Dropdown({
  options,
  value,
  onChangeValue,
  placeholder,
  readOnly,
  width,
}: DropdownProps) {
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [label, setLabel] = useState<string>(placeholder || '');

  const handleOptionClick = (name: string, label: string) => {
    onChangeValue(name);
    setLabel(label);
    setIsDropdownOpen(false);
  };

  const handleDropdownOpen = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  useEffect(() => {
    onChangeValue(value);
    const selected = options.find((option) => option.name === value);
    if (!selected) return;

    setLabel(selected.labelKey);
  }, [value]);

  return (
    <Container $width={width} $readOnly={readOnly}>
      <DropdownButton
        type="button"
        onClick={handleDropdownOpen}
        disabled={readOnly}
        $isOpen={!readOnly && isDropdownOpen}
        $readOnly={readOnly}
      >
        <Label $isSelected={value !== ''} $readOnly={readOnly}>
          {label}
        </Label>
        {!readOnly && (
          <ArrowDownIcon $isOpen={!readOnly && isDropdownOpen}>
            <Icon name="ChevronDown" fill="gray400" />
          </ArrowDownIcon>
        )}
      </DropdownButton>
      {!readOnly && isDropdownOpen && (
        <Options $isOpen={!readOnly && isDropdownOpen}>
          {options.map(({ name, labelKey }) => (
            <Option
              onClick={() => handleOptionClick(name, labelKey)}
              key={name}
            >
              {t(labelKey)}
            </Option>
          ))}
        </Options>
      )}
    </Container>
  );
}

interface ContainerProps {
  $width?: string;
  $readOnly?: boolean;
}

interface StyledDropdownProps {
  $isOpen?: boolean;
  $readOnly?: boolean;
}

interface StyledLabelProps {
  $isSelected?: boolean;
  $readOnly?: boolean;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  width: ${(props) => props.$width || '100%'};
  align-self: center;
  font-size: ${(props) => props.theme.fonts.size.sm};
  cursor: ${(props) => (props.$readOnly ? 'default' : 'pointer')};
  /* z-index: 1; */
`;

const DropdownButton = styled.button<StyledDropdownProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${(props) => props.theme.sizes.dropdownHeight};
  color: ${(props) => props.theme.colors.gray400};
  border-radius: ${(props) => props.theme.borderRadiuses.lg};
  background-color: ${(props) => props.theme.colors.gray50};
  padding: ${(props) => props.theme.spaces.xxs};
  cursor: ${(props) => (props.$readOnly ? 'default' : 'pointer')};

  ${(props) =>
    props.$isOpen &&
    css`
      border-bottom-left-radius: ${(props) => props.theme.borderRadiuses.none};
      border-bottom-right-radius: ${(props) => props.theme.borderRadiuses.none};
    `}
`;

const Label = styled.label<StyledLabelProps>`
  display: block;
  width: 100%;
  margin: 0;
  text-align: left;
  color: ${(props) =>
    props.$isSelected
      ? props.theme.colors.gray900
      : props.theme.colors.gray400};
  cursor: ${(props) => (props.$readOnly ? 'default' : 'pointer')};
`;

const ArrowDownIcon = styled.div<StyledDropdownProps>`
  padding: ${(props) => props.theme.spaces.xxs} 0;
  display: flex;
  align-items: center;
  transform: ${(props) => (props.$isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 300ms
    ${(props) => props.theme.animations.customCubicBezier};

  & > img {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
`;

const Options = styled.ul<StyledDropdownProps>`
  position: absolute;
  top: ${(props) => props.theme.sizes.dropdownHeight};
  left: 0;
  width: 100%;
  overflow: hidden;
  border-bottom-left-radius: ${(props) => props.theme.borderRadiuses.lg};
  border-bottom-right-radius: ${(props) => props.theme.borderRadiuses.lg};
  z-index: 1;
`;

const Option = styled.li`
  padding: ${(props) => props.theme.spaces.xxs};
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.gray900};
  background-color: ${(props) => props.theme.colors.gray50};
  transition: background-color 100ms ease-in;

  &:hover {
    background-color: ${(props) => props.theme.colors.gray100};
  }
`;
