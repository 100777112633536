import { Icon, IconName } from '@components/@shared';
import styled from 'styled-components';
import { ProgressStatusType } from '@customTypes/admin/careLabel/progress';

interface ProgressBarStepProps {
  progressStatus: ProgressStatusType;
  orderStatus: string;
  orderStepIcon: IconName;
  width?: number;
  height?: number;
  borderWidth?: number;
  fill?: string;
  hasIconWrapper?: boolean;
}

export default function ProgressBarStep({
  progressStatus = 'default',
  orderStatus,
  orderStepIcon,
  width = 54,
  height = 54,
  borderWidth = 2,
  fill,
  hasIconWrapper = true,
}: ProgressBarStepProps) {
  const iconWrapperFill =
    fill ??
    (progressStatus === 'active'
      ? 'white'
      : progressStatus === 'inactive'
        ? 'gray200'
        : 'black');

  const iconFill =
    fill ??
    (progressStatus === 'active'
      ? 'black'
      : progressStatus === 'inactive'
        ? 'gray400'
        : 'black');

  const finalFill = hasIconWrapper ? iconWrapperFill : iconFill;

  return (
    <ProgressBarStepWrapper
      $progressStatus={progressStatus}
      $orderStatus={orderStatus}
    >
      {hasIconWrapper ? (
        <IconBox
          $borderWidth={borderWidth}
          $width={width}
          $height={height}
          $progressStatus={progressStatus}
          $hasIconWrapper={hasIconWrapper}
        >
          <Icon name={orderStepIcon} fill={finalFill} />
        </IconBox>
      ) : (
        <Icon name={orderStepIcon} fill={finalFill} />
      )}
    </ProgressBarStepWrapper>
  );
}

interface ProgressBarStepWrapperProps {
  $orderStatus: string;
  $progressStatus: ProgressStatusType;
}

const ProgressBarStepWrapper = styled.div<ProgressBarStepWrapperProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2px;
  z-index: 10;

  &::after {
    content: ${({ $orderStatus }) =>
      $orderStatus ? `"${$orderStatus}"` : '""'};
    width: fit-content;
    position: absolute;
    top: 100%; // 부모 요소의 높이만큼 아래로 이동
    left: 50%; // 부모 요소의 중간점으로 이동
    transform: translateX(
      -50%
    ); // 자신의 너비의 절반만큼 왼쪽으로 이동하여 중앙 정렬
    margin-top: 2px;
    font-size: 14px;
    white-space: nowrap;
    font-weight: 500;
    color: ${({ $progressStatus, theme }) =>
      $progressStatus === 'inactive' ? theme.colors.gray200 : 'inherit'};
  }
`;

interface IconBoxProps {
  $progressStatus?: ProgressStatusType;
  $width: number;
  $height: number;
  $borderWidth: number;
  $hasIconWrapper: boolean;
}

const IconBox = styled.div<IconBoxProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ $width }) => $width}px;
  height: ${({ $height }) => $height}px;
  border-radius: 50%;
  background-color: ${({ theme, $progressStatus }) =>
    $progressStatus === 'active' ? theme.colors.black : theme.colors.white};
  border: ${({ theme, $progressStatus, $borderWidth }) =>
    `${$borderWidth}px solid ${
      $progressStatus === 'inactive' ? theme.colors.gray200 : theme.colors.black
    }`};
`;
