import { Button, Icon, IconName } from '@components/@shared';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import { Buttons } from '../../Buttons';

interface MainInnerProps extends CSSProperties {
  children: React.ReactNode;
}

function MainInner({ children, ...styles }: MainInnerProps) {
  return <Container style={styles}>{children}</Container>;
}

interface MainTitleProps {
  title: string;
  description?: string;
}

function MainTitle({ title, description }: MainTitleProps) {
  return (
    <Title>
      {title}
      {description && <TitleDescription>{description}</TitleDescription>}
    </Title>
  );
}

interface MainBannerProps extends CSSProperties {
  url: string;
  title: string;
  description?: string;
  buttonLabel?: string;
  iconName?: IconName;
  fill?: string;
  onClick?: () => void;
}

function MainBanner({
  url,
  title,
  description,
  buttonLabel,
  iconName,
  fill,
  onClick,
  ...styles
}: MainBannerProps) {
  return (
    <BannerContainer style={styles}>
      <VideoContainer>
        <Video autoPlay muted loop playsInline preload="auto">
          <source src={url} type="video/mp4" />
        </Video>
      </VideoContainer>
      <BannerContent>
        <BannerTitle>{title}</BannerTitle>
        {description ? (
          <BannerDescription>{description}</BannerDescription>
        ) : null}
        {buttonLabel && iconName ? (
          <Buttons.PrimaryButton
            label={buttonLabel}
            onClickButton={onClick}
            icon={<Icon name={iconName} fill={fill} />}
            minHeight="44px"
            padding="0 16px"
            marginTop="20px"
          />
        ) : null}
      </BannerContent>
    </BannerContainer>
  );
}

interface ProgressiveHeaderProps {
  title: string;
  progressBar: React.ReactNode;
}

function ProgressiveHeader({ title, progressBar }: ProgressiveHeaderProps) {
  return (
    <HeaderContainer>
      <MainTitle title={title} />
      <ProgressBarContainer>{progressBar}</ProgressBarContainer>
    </HeaderContainer>
  );
}

export default Object.assign(MainInner, {
  MainTitle,
  MainBanner,
  ProgressiveHeader,
});

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  /* gap: 20px; */
  padding: 20px 60px;
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  background-color: ${(props) => props.theme.colors.gray50};

  @media (max-width: 1439px) {
    padding: 20px 30px;
  }

  & > * {
    width: 100%;
    max-width: 1920px;
  }
`;

const Title = styled.h2`
  display: block;
  width: 100%;
  font-size: 32px;
  font-weight: 700;
  min-width: fit-content;
  margin-bottom: 20px;
`;

const TitleDescription = styled.div`
  margin-top: 8px;
  font-size: 16px;
  font-weight: 500;
  /* color: ${({ theme }) => theme.colors.gray400}; */
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px;
  max-width: 400px;
  width: 100%;
  flex-wrap: nowrap; /* 내부 요소를 한 줄로 유지 */
`;

const BannerContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  padding: 0 80px;
  gap: 64px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20%;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 21.75%,
        #e6e6e6 80.76%
      ),
      ${({ theme }) => theme.colors.white};
    mix-blend-mode: multiply; /* 혼합 모드 적용 */
    opacity: 0.5;
    z-index: 0;
  }

  @media (max-width: 1279px) {
    padding: 0 20px;
    gap: 48px;
  }

  @media (max-width: 959px) {
    padding: 0 10px;
    gap: 32px;
  }
`;

const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const BannerTitle = styled.span`
  font-size: 32px;
  font-weight: 500;
  white-space: pre-line;

  @media (max-width: 1279px) {
    font-size: 28px;
  }

  @media (max-width: 959px) {
    font-size: 24px;
  }
`;

const BannerDescription = styled.span`
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.gray400};
`;

const VideoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  width: 200px;
  height: 440px;
  min-width: 200px;
  min-height: 440px;
  margin-left: 10px;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
