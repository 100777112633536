import { CSSProperties, PropsWithChildren, ReactElement } from 'react';
import styled, { css } from 'styled-components';

function Buttons({ children }: PropsWithChildren) {
  return <ButtonsContainer>{children}</ButtonsContainer>;
}

interface ButtonProps extends CSSProperties {
  label?: string;
  iconUrl?: string;
  imgAlt?: string;
  icon?: ReactElement;
  disabled?: boolean;
  onClickButton?: () => void;
  width?: string;
}

function PrimaryButton({
  label,
  icon,
  disabled,
  onClickButton,
  width,
  ...styles
}: ButtonProps) {
  return (
    <StyledPrimaryButton
      type="button"
      onClick={onClickButton}
      $withIcon={!!icon}
      $width={width}
      $isActive={!disabled}
      style={styles}
    >
      {icon}
      {label && <span>{label}</span>}
    </StyledPrimaryButton>
  );
}

function SecondaryButton({
  label,
  icon,
  disabled,
  onClickButton,
  width,
  ...styles
}: ButtonProps) {
  return (
    <StyledSecondaryButton
      type="button"
      onClick={onClickButton}
      $withIcon={!!icon}
      $width={width}
      $isActive={!disabled}
      style={styles}
    >
      {icon}
      {label && <span>{label}</span>}
    </StyledSecondaryButton>
  );
}

export default Object.assign(Buttons, {
  PrimaryButton,
  SecondaryButton,
});

interface StyledButtonProps {
  $isActive?: boolean;
  $width?: string;
  $withIcon?: boolean;
}

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spaces.xxxs};
  height: 50px;
`;

const BaseButton = styled.button<StyledButtonProps>`
  width: ${(props) => props.$width || 'auto'};
  height: 100%;
  padding-left: ${(props) => props.theme.spaces.xs};
  padding-right: ${(props) =>
    props.$withIcon ? props.theme.spaces.sm : props.theme.spaces.xs};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${(props) => props.theme.spaces.xxxxs};
  border-radius: ${(props) => props.theme.borderRadiuses.lg};
  cursor: ${(props) => (props.$isActive ? 'pointer' : 'default')};
`;

const StyledPrimaryButton = styled(BaseButton)`
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.gray900};

  ${(props) =>
    props.$isActive &&
    css`
      &:hover {
        background-color: ${(props) => props.theme.colors.gray600};
      }
    `}
`;

const StyledSecondaryButton = styled(BaseButton)`
  color: ${(props) =>
    props.$isActive ? props.theme.colors.gray900 : props.theme.colors.gray300};
  background-color: ${(props) => props.theme.colors.white};
  border: 2px solid ${(props) => props.theme.colors.gray100};
  gap: 8px;

  ${(props) =>
    props.$isActive &&
    css`
      &:hover {
        background-color: ${(props) => props.theme.colors.gray100};
      }
    `}
`;
