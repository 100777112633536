import { Icon } from '@components/@shared';
import { ButtonSecondary } from '@components/admin/@shared/atoms';

interface DeleteButtonProps {
  onClick?: () => void;
  fill?: string;
  disabled?: boolean;
}

export default function DeleteButton({
  onClick,
  fill = 'black',
  disabled = false,
}: DeleteButtonProps) {
  return (
    <ButtonSecondary
      label={<Icon name="Delete" fill={disabled ? 'gray200' : fill} />}
      onClick={onClick}
      width="50px"
      padding={0}
      flexShrink={0}
      disabled={disabled}
    />
  );
}
