import styled from 'styled-components';
import { TapButton } from '../atoms/TapButton';
import { IconName } from '@components/@shared';

export type TabBarType = {
  index: number;
  label: string;
  iconName?: IconName;
};

interface TabBarProps {
  array: TabBarType[];
  currIndex: number | string;
  onClick: (index: number) => void;
}

export default function TabBar({ array, currIndex, onClick }: TabBarProps) {
  const checkIsActive = (index: number) => index === Number(currIndex);

  return (
    <TapButtonBox>
      {array.map(({ index, label, iconName }) => (
        <TapButton
          key={index}
          label={label}
          isActive={index === Number(currIndex)}
          onClick={() => onClick(index)}
          iconName={iconName}
          fill={checkIsActive(index) ? 'black' : 'gray400'}
        />
      ))}
    </TapButtonBox>
  );
}

const TapButtonBox = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
  border-bottom: 2px solid ${(props) => props.theme.colors.gray200};
`;
