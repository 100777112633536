import { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

interface BubbleToolTipProps {
  children: React.ReactNode;
  title?: string;
  description: string;
  position?: 'top' | 'bottom' | 'left' | 'right';
  customVertical?: number;
  customHorizontal?: number;
}

export default function BubbleToolTip({
  children,
  title,
  description,
  position = 'bottom',
  customVertical = 0,
  customHorizontal = 0,
}: BubbleToolTipProps) {
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [tooltipStyle, setTooltipStyle] = useState<{
    top: number;
    left: number;
  }>({ top: 0, left: 0 });

  const handleMouseEnter = () => {
    const rect = containerRef.current?.getBoundingClientRect();
    if (rect) {
      let newTooltipStyle = {
        top: 0,
        left: 0,
      };

      const horizontalCenter = rect.left + rect.width / 2; // 가로 중앙
      const verticalCenter = rect.top + rect.height / 2; // 세로 중앙

      switch (position) {
        case 'top':
          newTooltipStyle.top = rect.top - customVertical;
          newTooltipStyle.left = horizontalCenter;
          break;
        case 'bottom':
          newTooltipStyle.top = rect.bottom + customVertical;
          newTooltipStyle.left = horizontalCenter;
          break;
        case 'left':
          newTooltipStyle.top = verticalCenter;
          newTooltipStyle.left = rect.left - customHorizontal;
          break;
        case 'right':
          newTooltipStyle.top = verticalCenter;
          newTooltipStyle.left = rect.right + customHorizontal;
          break;
        default:
          break;
      }

      // 스크롤 위치 고려
      newTooltipStyle.top += window.scrollY;
      newTooltipStyle.left += window.scrollX;

      setTooltipStyle(newTooltipStyle);
    }
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    setIsVisible(false);
  };

  return (
    <>
      <TooltipContainer
        ref={containerRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </TooltipContainer>
      {isVisible &&
        createPortal(
          <TooltipContent
            $isVisible={true}
            position={position}
            style={{
              top: `${tooltipStyle.top}px`,
              left: `${tooltipStyle.left}px`,
            }}
          >
            {title ? <TooltipTitle>{title}</TooltipTitle> : null}
            <TooltipDescription>{description}</TooltipDescription>
          </TooltipContent>,
          document.body
        )}
    </>
  );
}

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`;

interface TooltipContentProps {
  $isVisible: boolean;
  position?: 'top' | 'bottom' | 'left' | 'right';
}

const TooltipContent = styled.div<TooltipContentProps>`
  position: absolute; /* 부모 요소 기준으로 위치 */
  z-index: 10;
  display: flex;
  flex-direction: column;
  width: 126px; /* 화면 줄어들 때 툴팁 줄어드는 이슈로 고정 너비 지정 */
  min-height: fit-content;
  height: fit-content;
  padding: 6px 8px;
  border-radius: 100px;
  background: ${(props) => props.theme.colors.black};
  color: ${(props) => props.theme.colors.white};
  font-size: 12px;
  font-weight: 600;

  visibility: ${(props) => (props.$isVisible ? 'visible' : 'hidden')};
  visibility: visible;
  opacity: ${(props) => (props.$isVisible ? 1 : 0)};
  opacity: 1;
  transition: opacity 0.2s ease-in-out;

  /* 툴팁 기본 위치 */
  transform: ${(props) => {
    switch (props.position) {
      case 'top':
        return 'translate(-50%, -100%)'; // 위로 이동
      case 'bottom':
        return 'translate(-50%, 0)'; // 아래로 이동
      case 'left':
        return 'translate(-100%, -50%)'; // 왼쪽으로 이동
      case 'right':
        return 'translate(0, -50%)'; // 오른쪽으로 이동
      default:
        return 'translate(-50%, 0)';
    }
  }};

  /* 화살표랑 보더 간격 없애기 위한 테두리 추가 */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    border: 1px solid ${(props) => props.theme.colors.black}; /* 테두리 색상 */
    transform: translate(0px, 0px); /* 테두리 간격 조정 */
    z-index: -1; /* 툴팁 뒤로 배치 */
  }

  /* 툴팁 화살표 */
  &::after {
    content: '';
    position: absolute;
    ${(props) =>
      props.position === 'bottom' &&
      `
      top: -8px;
      left: 50%;
      transform: translateX(-50%);
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid ${props.theme.colors.black};
    `}
    ${(props) =>
      props.position === 'top' &&
      `
      bottom: -6px;
      left: 50%;
      transform: translateX(-50%);
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 6px solid ${props.theme.colors.black};
    `}
    ${(props) =>
      props.position === 'left' &&
      `
      top: 50%;
      right: -8px;
      transform: translateY(-50%);
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 8px solid ${props.theme.colors.black};
    `}
    ${(props) =>
      props.position === 'right' &&
      `
      top: 50%;
      left: -8px;
      transform: translateY(-50%);
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-right: 8px solid ${props.theme.colors.black};
    `}
  }
`;

const TooltipTitle = styled.span`
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 4px;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray100};
`;

const TooltipDescription = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 130%;
`;
