import { ModalContainer } from '@components/admin/@shared/molecules';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Icon } from '@components/@shared';
import { useModal } from '@lib/hooks';
import { LandingModalForm } from './LandingModalForm';
import { accessTokenAtom, accessTokenAtomWithPersistence } from '@store/login';
import { useAtom } from 'jotai';
import { ADMIN_GUEST_PATH, ADMIN_PAGE_PATH } from '@lib/constants/admin';
import { useCareLabelList } from '@lib/apis/admin';

interface LandingModalProps {
  onClose: () => void;
}

type DesignMethod = 'direct' | 'upload';

const cards = [
  {
    icon: 'CloudFileUpload' as const,
    badge: '추천',
    title: '케어라벨\n주문',
    description: '가지고 있는\n파일로 주문하기',
    type: 'upload' as DesignMethod,
  },
  {
    icon: 'Pencil' as const,
    badge: '오픈 예정',
    title: '직접\n디자인',
    description: '서비스 체험판\n미리 신청하기',
    type: 'direct' as DesignMethod,
  },
] as const;

export function LandingModal({ onClose }: LandingModalProps) {
  const navigate = useNavigate();
  const { isModalOpen, handleModalOpen, handleModalClose } = useModal();
  const [accessToken] = useAtom(accessTokenAtom);
  const { data: careLabelList } = useCareLabelList();

  const handleCardClick = (type: DesignMethod) => {
    if (type === 'direct') {
      // 서비스 체험판 신청 모달
      handleModalOpen();
      return;
    }

    if (!accessToken) {
      navigate(ADMIN_GUEST_PATH.login);
      return;
    }

    // 첫 주문이면 pdf 업로드 페이지로 이동, 기존 주문이 있으면 목록 페이지로 이동
    const path =
      careLabelList?.projects.length === 0
        ? `${ADMIN_PAGE_PATH.index}/${ADMIN_PAGE_PATH.careLabel.index}/${ADMIN_PAGE_PATH.careLabel.create}`
        : `${ADMIN_PAGE_PATH.index}/${ADMIN_PAGE_PATH.careLabel.index}`;

    navigate(path);
  };

  return (
    <>
      {isModalOpen && <LandingModalForm onClose={handleModalClose} />}
      <ModalContainer onCloseModal={onClose}>
        <Container>
          <ModalContainer.DialogTitle title="서비스 선택" fontSize={22} />
          <CardWrapper>
            {cards.map((card) => (
              <Card key={card.type} onClick={() => handleCardClick(card.type)}>
                <Icon
                  name={card.icon}
                  width={72}
                  height={72}
                  fill={card.type === 'direct' ? 'gray400' : 'black'}
                />
                <Badge type={card.type}>{card.badge}</Badge>
                <Content>
                  <Title type={card.type}>{card.title}</Title>
                  <Description type={card.type}>{card.description}</Description>
                </Content>
              </Card>
            ))}
          </CardWrapper>
        </Container>
      </ModalContainer>
    </>
  );
}

const Container = styled.div`
  width: 540px;
  height: 460px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CardWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 12px;
  flex: 1;
`;

const Card = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 20px;
  transition: all 0.3s ease;
  border: 1px solid ${({ theme }) => theme.colors.gray100};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray25};
    border: 1px solid ${({ theme }) => theme.colors.gray400};
  }
`;

const Badge = styled.div<{ type: DesignMethod }>`
  display: flex;
  border-radius: 100px;
  padding: 4px 8px;
  align-items: center;
  gap: 10px;
  font-size: 12px;

  ${({ type, theme }) =>
    type === 'direct'
      ? css`
          background-color: ${theme.colors.gray400};
          color: ${theme.colors.white};
        `
      : css`
          background-color: ${({ theme }) => theme.colors.black};
          color: ${({ theme }) => theme.colors.white};
        `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Title = styled.div<{ type: DesignMethod }>`
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  white-space: pre-line;

  ${({ type, theme }) =>
    type === 'direct'
      ? css`
          color: ${theme.colors.gray600};
        `
      : css`
          color: ${({ theme }) => theme.colors.black};
        `}
`;

const Description = styled.div<{ type: DesignMethod }>`
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  white-space: pre-line;

  ${({ type, theme }) =>
    type === 'direct'
      ? css`
          color: ${theme.colors.gray600};
        `
      : css`
          color: ${({ theme }) => theme.colors.black};
        `}
`;
