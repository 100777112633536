import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Icon } from '@components/@shared';

interface PagingNavigationProps {
  totalPage: number;
  currPage: number;
  limit?: number;
  onSetPage: (page: number) => void;
  offset: number;
  onSetOffset: (offset: number) => void;
}

export default function PagingNavigation({
  totalPage,
  currPage,
  limit = 10,
  onSetPage,
  offset,
  onSetOffset,
}: PagingNavigationProps) {
  const { t } = useTranslation('admin/common');
  const [pageNumbersArray, setPageNumbersArray] = useState<number[]>([]);

  // 이전 페이지로 이동
  const handlePrevIconClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const nextOffset = offset - limit;
    if (nextOffset < 1) return;

    onSetOffset(nextOffset);
    onSetPage(nextOffset);
  };

  // 다음 페이지로 이동
  const handleNextIconClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const nextOffset = offset + limit;
    if (totalPage < nextOffset) return;

    onSetOffset(nextOffset);
    onSetPage(nextOffset);
  };

  // 원하는 페이지로 이동 (숫자 클릭)
  const handleNumberClick = (pageNumber: number) => {
    if (currPage === pageNumber) return;

    onSetPage(pageNumber);
  };

  // 컴포넌트 반복을 위한 전체 페이지 수 길이의 배열 업데이트
  useEffect(() => {
    if (totalPage < 1) {
      setPageNumbersArray([]);
      return;
    }

    setPageNumbersArray(Array.from({ length: totalPage }, (_, i) => i + 1));
  }, [totalPage, offset, limit]);

  // 페이지 변경될 때마다 스크롤 위치 최상단으로
  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, [currPage]);

  return (
    <Container>
      {offset > limit && pageNumbersArray.length > 0 && (
        <PageButton
          type="button"
          onClick={handlePrevIconClick}
          className="prev"
        >
          <Icon name="ChevronLeft" fill="gray600" width={16} height={16} />
          <span>{t('pagination.prev')}</span>
        </PageButton>
      )}
      {pageNumbersArray
        .slice(offset - 1, offset - 1 + limit)
        .map((pageNumber) => {
          return (
            <PageButton
              type="button"
              onClick={() => handleNumberClick(pageNumber)}
              $isActive={pageNumber === currPage}
              key={`page-${pageNumber}`}
            >
              {pageNumber.toString().padStart(2, '0')}
            </PageButton>
          );
        })}
      {totalPage >= offset + limit && (
        <PageButton
          type="button"
          onClick={handleNextIconClick}
          className="next"
        >
          <span>{t('pagination.next')}</span>
          <Icon name="ChevronRight" fill="gray600" width={16} height={16} />
        </PageButton>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: ${(props) => props.theme.spaces.xxs};
  width: 100%;
`;

interface PageButtonProps {
  $isActive?: boolean;
}

const PageButton = styled.button<PageButtonProps>`
  padding: ${(props) => props.theme.spaces.xxxs};
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spaces.xxxxxs};
  font-size: ${(props) => props.theme.fonts.size.sm};
  font-weight: ${(props) => props.theme.fonts.weight.medium};
  color: ${(props) => props.theme.colors.gray600};
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.borderRadiuses.xs};

  &.prev {
    padding-left: ${(props) => props.theme.spaces.xxxxs};
  }

  &.next {
    padding-right: ${(props) => props.theme.spaces.xxxxs};
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.gray100};
  }

  /* Active */
  ${(props) =>
    props.$isActive &&
    css`
      color: ${(props) => props.theme.colors.white};
      background-color: ${(props) => props.theme.colors.gray900};

      &:hover {
        background-color: ${(props) => props.theme.colors.gray900};
      }
    `}
`;
