import styled from 'styled-components';
import { Icon } from '../../atoms';
import { CSSProperties } from 'react';
import { toast } from 'react-toastify';

export type RadioButtonOption = {
  name: string;
  label: string | JSX.Element;
  description?: string | JSX.Element;
};

interface RadioButtonProps extends CSSProperties {
  inputName: string;
  options: RadioButtonOption[];
  onSelect?: (value: string) => void;
  selectedOption?: string;
  toastMessage?: string;
  disabled?: boolean;
  readonly?: boolean;
}

const RadioButtons = ({
  inputName,
  options,
  onSelect,
  selectedOption,
  disabled,
  readonly,
  toastMessage,
  ...styles
}: RadioButtonProps) => {
  const handleOptionClick = (name: string) => {
    if (readonly) {
      if (toastMessage) {
        toast.info(toastMessage);
      }
      return;
    }
    onSelect?.(name);
  };

  return (
    <RadioButtonsContainer style={styles}>
      {options.map(({ name, label, description }) => (
        <RadioButton
          type="button"
          name={inputName}
          value={name}
          onClick={() => handleOptionClick(name)}
          key={name}
          $isSelected={selectedOption === name}
          disabled={disabled}
          $readOnly={readonly}
        >
          <Icon name="RadioButton" />
          <RadioButtonLabelContainer>
            <div>{label}</div>
            {description && <p>{description}</p>}
          </RadioButtonLabelContainer>
        </RadioButton>
      ))}
    </RadioButtonsContainer>
  );
};

// DevTools에서 컴포넌트 이름 표시를 위해 설정
RadioButtons.displayName = 'RadioButtons';
export default RadioButtons;

const RadioButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

interface StyledRadioButtonProps {
  $isSelected?: boolean;
  $readOnly?: boolean;
}

const RadioButton = styled.button<StyledRadioButtonProps>`
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  background-color: ${(props) => props.theme.colors.white};
  text-align: left;
  color: ${(props) =>
    props.$isSelected ? props.theme.colors.black : props.theme.colors.gray600};
  cursor: pointer;
  position: relative;

  & svg {
    fill: ${(props) =>
      props.$isSelected
        ? props.theme.colors.black
        : props.theme.colors.gray200};
  }

  /* Disabled */
  &:disabled {
    color: ${(props) =>
      props.$isSelected
        ? props.theme.colors.gray300
        : props.theme.colors.gray200};
    cursor: default;

    & svg {
      fill: ${(props) =>
        props.$isSelected
          ? props.theme.colors.gray200
          : props.theme.colors.gray100};
    }
  }

  /* ReadOnly */
  ${(props) =>
    props.$readOnly &&
    `
      cursor: default;
    `}

  /* Selected */
      &::before {
    content: '';
    position: absolute;
    ${(props) =>
      props.$isSelected &&
      `
        border-width: 1px;
        border-style: solid;
        border-color: inherit; 
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        border-radius: 8px;
      `}
  }
`;

const RadioButtonLabelContainer = styled.div`
  & > p {
    margin-top: 2px;
    font-size: 12px;
    color: ${(props) => props.theme.colors.gray400};
  }
`;
