import styled from 'styled-components';
import Layout from '../Layout';
import Badge from '../Badge';
import {
  LANDING_ICON_COOPERATION_URL,
  LANDING_ICON_SECURITY_URL,
  LANDING_ICON_DATA_URL,
  LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_1,
  LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_2,
  LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_3,
  LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_4,
  LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_5,
  LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_6,
  LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_7,
  LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_8,
  LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_9,
  LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_10,
  LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_11,
  LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_12,
  LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_13,
  LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_14,
  LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_15,
  LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_16,
  LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_17,
  LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_18,
  LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_19,
  LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_20,
} from '@lib/constants/imageUrls';
import { Icon } from '@components/admin/@shared/atoms';
import { media } from '@lib/styles/breakpoints';
import { motion } from 'framer-motion';
import { scrollFadeInUpVariants } from '@lib/constants/motion';

export default function Section02() {
  const firstRowImages = [
    { url: LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_1, alt: '마퀴 이미지 1' },
    { url: LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_2, alt: '마퀴 이미지 2' },
    { url: LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_3, alt: '마퀴 이미지 3' },
    { url: LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_4, alt: '마퀴 이미지 4' },
    { url: LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_5, alt: '마퀴 이미지 5' },
    { url: LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_6, alt: '마퀴 이미지 6' },
    { url: LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_7, alt: '마퀴 이미지 7' },
    { url: LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_8, alt: '마퀴 이미지 8' },
    { url: LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_9, alt: '마퀴 이미지 9' },
    { url: LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_10, alt: '마퀴 이미지 10' },
  ];

  const secondRowImages = [
    { url: LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_11, alt: '마퀴 이미지 11' },
    { url: LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_12, alt: '마퀴 이미지 12' },
    { url: LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_13, alt: '마퀴 이미지 13' },
    { url: LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_14, alt: '마퀴 이미지 14' },
    { url: LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_15, alt: '마퀴 이미지 15' },
    { url: LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_16, alt: '마퀴 이미지 16' },
    { url: LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_17, alt: '마퀴 이미지 17' },
    { url: LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_18, alt: '마퀴 이미지 18' },
    { url: LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_19, alt: '마퀴 이미지 19' },
    { url: LANDING_SECTION_HOME_MARQUEE_IMAGE_URL_20, alt: '마퀴 이미지 20' },
  ];

  return (
    <Layout>
      <Container>
        <AnimationWrapper
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.3 }}
          variants={scrollFadeInUpVariants}
        >
          <Content>
            <Badge label="WHY NOW" />
            <Title>
              패션 문화를 선도하는 브랜드는
              <br />
              이미 디지털 케어라벨을 도입하고 있습니다.
            </Title>
          </Content>
          <CardWrapper>
            <Card>
              <CardIcon>
                <img
                  src={LANDING_ICON_COOPERATION_URL}
                  alt="공신력 있는 기관과 협업"
                />
              </CardIcon>
              <CardTitle>손쉬운 케어라벨 관리</CardTitle>
              <CardDescription>
                국내, 글로벌 기준에 맞는 <br />
                표준화된 케어라벨을 손쉽게 제작하세요.
              </CardDescription>
            </Card>
            <Card>
              <CardIcon>
                <img src={LANDING_ICON_SECURITY_URL} alt="안전한 보안 기술" />
              </CardIcon>
              <CardTitle>케어라벨 업그레이드+</CardTitle>
              <CardDescription>
                디지털 케어라벨을 통해 <br />
                고객에게 우리 브랜드의 가치를 전달하세요.
              </CardDescription>
            </Card>
            <Card>
              <CardIcon>
                <img src={LANDING_ICON_DATA_URL} alt="다양한 데이터 제공" />
              </CardIcon>
              <CardTitle>제품 추적과 모니터링</CardTitle>
              <CardDescription>
                우리 브랜드 제품의 <br />
                중고거래 데이터, ESG 성과를 한눈에 살펴보세요.
              </CardDescription>
            </Card>
          </CardWrapper>
          <a
            href="https://careid.center"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button>
              더 알아보기
              <Icon name="ChevronRight" fill="gray600" />
            </Button>
          </a>
          <MarqueeDescription>
            CARE ID® 는 유럽에서 시작된 <br />
            “디지털 제품 여권(Digital Product Passport)”의 가이드 라인을
            준수하는 <br />
            패션 산업을 지속가능하게 변화시키는 순환 패션 플랫폼입니다.
          </MarqueeDescription>
          <MarqueeWrapper>
            <Marquee className="right">
              <div className="marquee-content">
                {[1, 2, 3].map((_, repeatIndex) =>
                  firstRowImages.map((image, imgIndex) => (
                    <img
                      key={`${repeatIndex}-${imgIndex}`}
                      src={image.url}
                      alt={image.alt}
                    />
                  ))
                )}
              </div>
            </Marquee>
            <Marquee className="left">
              <div className="marquee-content">
                {[1, 2, 3].map((_, repeatIndex) =>
                  secondRowImages.map((image, imgIndex) => (
                    <img
                      key={`${repeatIndex}-${imgIndex}`}
                      src={image.url}
                      alt={image.alt}
                    />
                  ))
                )}
              </div>
            </Marquee>
          </MarqueeWrapper>
        </AnimationWrapper>
      </Container>
    </Layout>
  );
}

const Container = styled.div`
  padding-top: 280px;
  padding-bottom: 180px;
  transform: translateZ(0);
  will-change: transform;

  ${media.tablet} {
    padding: 135px 0;
  }

  ${media.mobile} {
    padding: 90px 0;
  }
`;

const AnimationWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;

  ${media.mobile} {
    gap: 24px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const Title = styled.h2`
  font-size: 48px;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;

  ${media.tablet} {
    font-size: 36px;
  }

  ${media.mobile} {
    font-size: 24px;
  }
`;

const CardWrapper = styled.div`
  width: 100%;
  place-content: center;
  align-items: center;
  display: flex;
  gap: 20px;

  ${media.mobile} {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
`;

const Card = styled.div`
  flex: 1;
  min-width: 0;
  max-width: 386px;
  height: 320px;
  background-color: ${({ theme }) => theme.colors.gray25};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 0px 30px;

  ${media.mobile} {
    width: 242px;
    height: 200px;
    padding: 30px;
  }
`;

const CardIcon = styled.div`
  img {
    width: 60px;
    height: 60px;
  }
`;

const CardTitle = styled.h3`
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 1px;
  ${media.tablet} {
    font-size: 21px;
  }

  ${media.mobile} {
    font-size: 14px;
  }
`;

const CardDescription = styled.p`
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: ${({ theme }) => theme.colors.gray400};

  ${media.tablet} {
    font-size: 12px;
  }
`;

const Button = styled.button`
  width: fit-content;
  display: inline-flex;
  background-color: ${({ theme }) => theme.colors.gray50};
  color: ${({ theme }) => theme.colors.gray600};
  border-radius: 100px;
  padding: 16px 16px;
  font-size: 14px;
  font-weight: 400;
  align-items: center;
  transition:
    background-color 0.3s,
    color 0.3s;

  a {
    display: flex;
    align-items: center;
    color: inherit;
  }

  &:hover {
    background-color: #000;
    color: #fff;

    a {
      color: #fff;
    }

    svg {
      fill: #fff;
    }
  }
`;

const MarqueeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
`;

const MarqueeDescription = styled.p`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
`;

const Marquee = styled.div`
  display: flex;
  padding: 10px;
  position: relative;
  width: 100%;
  max-width: 1200px;
  overflow: hidden;

  ${media.tablet} {
    width: 100%;
  }

  ${media.mobile} {
    width: 100%;
  }

  img {
    // opacity: 0.3;
    width: 96px;
    height: 60px;
    flex-shrink: 0;
  }

  .marquee-content {
    display: flex;
    width: max-content;
    height: 100%;
    place-items: center;
    gap: 20px;
    position: relative;
    will-change: transform;
  }

  &.right .marquee-content {
    animation: marqueeRight 10s linear infinite;
  }

  &.left .marquee-content {
    animation: marqueeLeft 10s linear infinite;
  }

  @keyframes marqueeRight {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-33.33333333333333%);
    }
  }

  @keyframes marqueeLeft {
    0% {
      transform: translateX(-33.33333333333333%);
    }
    100% {
      transform: translateX(0);
    }
  }
`;
