import { z } from 'zod';
import {
  checkSchema,
  IndexedSignatureTypeData,
} from '@lib/apis/user/@schema/core';

/**
 * 제품 이미지 (제품 정보)
 */
const productImageFieldList = {
  img_1: '제품 이미지 1',
  img_2: '제품 이미지 2',
  img_3: '제품 이미지 3',
  img_4: '제품 이미지 4',
  img_5: '제품 이미지 5',
  img_6: '제품 이미지 6',
  img_7: '제품 이미지 7',
  img_8: '제품 이미지 8',
  img_9: '제품 이미지 9',
  img_10: '제품 이미지 10',
};

/**
 * 제품 정보
 */
const productInfoFieldList = {
  id: '제품 아이디', // 필수
  name: '제품 이름', // 필수
  sku_index: '스큐 번호', // 필수
  brand_name: '브랜드 이름', // 필수
  size: '사이즈', // 필수
  size_value: '사이즈 값', // 필수
  size_country_code: '사이즈 국가 코드', // 필수
  category: '의류 분류', // 필수
  color: '제품 색상', // 필수
  description: '제품 설명', // 필수
  product_weight: '제품 무게',
  package_weight: '제품 포장 무게',
  weight_unit: '무게 단위',
  gender: '고객 성별',
  age: '고객 연령',
  season: '시즌',
  date_release: '제품 출시 날짜', // 필수
  water_properties: '방수 등급',
  gtin_num: 'GTIN 번호',
  article_num: '제품 아티클 번호',
  po_num: '제품 PO 번호',
  commodity_code_system: '무역 사용 시스템',
  commodity_code_value: '상품 무역 코드',
  // 제품 이미지 이름(들)
  ...productImageFieldList,
};

/**
 * 원단 (재료 정보)
 */
const materialContentInfoFieldList = {
  material_name: '원단', // 필수
  material_value: '혼용률', // 필수
};

/**
 * 재료 정보
 */
const materialInfoFieldList = {
  // 원단
  materials: materialContentInfoFieldList, // 필수
  component: '부자재', // 필수
  pattern: '제품 패턴',
  recycle: '재활용 가능 여부',
  recycle_input_src: '재활용 가능 부분',
  recycle_percentage: '재활용 가능 부분 비율',
  recycled: '재활용 소재 사용 여부',
  recycled_percentage: '재활용 소재 사용 비율',
  leather: '가죽 사용 여부',
  leather_species: '가죽 종류',
  leather_grade: '가죽 등급',
  sweing_thread_content: '실 종류',
  print_ink_type: '프린팅 잉크 타입',
  dye_class: '염료 종류',
  dye_class_standard: '염료 표준 등급',
  finishes: '마감',
};

/**
 * 케어 정보
 */
const careInfoFieldList = {
  img_care: '케어라벨 이미지',
  care_text: '케어 텍스트',
};

/**
 * 규정 준수 정보
 */
const regulationsInfoFieldList = {
  harmful_substances: '유해물질 포함 여부',
  harmful_substances_info: '포함 유해물질',
  microfibers: '미세 플라스틱 정보',
  certification: '규정 인증서',
};

/**
 * 순환성 지속가능성 정보
 */
const cyclingInfoFieldList = {
  instruction_take_back: '회수 방법 안내',
  instruction_recycle: '재활용 방법 안내',
  instruction_disassembly: '분해 방법 안내',
  instruction_repair: '수선 방법 안내',
  social_brand_statement: '소셜 브랜드 선언문',
};

/**
 * 공급망 정보
 */
const supplierInfoFieldList = {
  origin_consume: '의류 소비 지역', // 필수
  origin_weaving_knitting: '원단 제작 지역',
  origin_dyeing_printing: '염색 및 프린팅 지역', // 필수
  origin_confection: '의류 제작 지역', // 필수
};

/**
 * 디지털 식별자 정보
 */
const digitalIdentifierInfoFieldList = {
  identifier_type: 'DPP 식별 방식', // 필수
  identifier_location: 'DPP 식별자 위치', // 필수
  identifier_standard: 'DPP 규격', // 필수
};

const dppInfoFieldList = {
  ...productInfoFieldList,
  ...productImageFieldList,
  ...materialInfoFieldList,
  ...careInfoFieldList,
  ...cyclingInfoFieldList,
  ...regulationsInfoFieldList,
  ...supplierInfoFieldList,
  ...digitalIdentifierInfoFieldList,
};

// 브랜드 등록
export type DppInfoUploadSchemaType = z.infer<typeof DppInfoUploadSchema>;

const MateiralsObjSchema = z.object({
  material_name: z.string().min(1),
  material_value: z
    .number()
    .positive({
      message: '원단 혼용률은 0 이상의 숫자여야 합니다.',
    })
    .max(100, {
      message: '원단 혼용률은 100 이하의 숫자여야 합니다.',
    }),
});

const DppInfoUploadSchema = z.object({
  /**
   * 제품 정보
   */
  id: z.string().min(1), // 필수
  name: z.string().min(1), // 필수
  sku_index: z
    .number({
      invalid_type_error: `${productInfoFieldList['sku_index']}는 정수로 입력해주세요.`,
    })
    .positive()
    .refine((val) => !val || Number.isInteger(val), {
      message: `${productInfoFieldList['sku_index']}는 정수로 입력해주세요.`,
    }), // 필수
  brand_name: z.string().min(1), // 필수
  size: z.string().min(1), // 필수
  size_value: z.string().min(1), // 필수
  size_country_code: z.string().min(1), // 필수
  category: z.string().min(1, {
    message: '의류 분류를 선택해주세요.',
  }), // 필수
  color: z.string().min(1), // 필수
  description: z.string().min(1), // 필수
  product_weight: z.number().positive().optional(),
  package_weight: z.number().positive().optional(),
  // 출시일
  date_release: z.string().min(1), // 필수
  // 제품 이미지 이름 (최대 10개)
  // img_1: z.string().min(1, {
  //   message: '제품 이미지를 하나 이상 선택해주세요.',
  // }),
  img_1: z.string().optional(),
  img_2: z.string().optional(),
  img_3: z.string().optional(),
  img_4: z.string().optional(),
  img_5: z.string().optional(),
  img_6: z.string().optional(),
  img_7: z.string().optional(),
  img_8: z.string().optional(),
  img_9: z.string().optional(),
  img_10: z.string().optional(),
  /**
   * 재료 정보
   */
  // 원단
  materials: z
    .array(MateiralsObjSchema)
    .refine(
      (datas) =>
        datas.reduce((acc, curr) => acc + curr.material_value, 0) === 100,
      {
        message: '원단 혼용률 총합은 100이어야 합니다.',
      }
    ), // 필수
  // 부자재
  component: z.string().min(1), // 필수
  // 재활용 가능 여부
  recycle: z.boolean().optional(),
  // 재활용 소재 사용 여부
  recycled: z.boolean().optional(),
  // 가죽 사용 여부
  leather: z.boolean().optional(),
  // 유해 물질 포함 여부
  harmful_substances: z.boolean().optional(),
  // 의류 소비 지역
  origin_consume: z.string().min(1), // 필수
  // 염색 및 프린팅 지역
  origin_dyeing_printing: z.string().min(1), // 필수
  // 의류 제작 지역
  origin_confection: z.string().min(1), // 필수
  // DPP 식별 방식
  identifier_type: z.string().min(1), // 필수
  // DPP 식별자 위치
  identifier_location: z.string().min(1), // 필수
  // DPP 규격
  identifier_standard: z.string().min(1), // 필수
});

const DppInfoUpdateSchema = DppInfoUploadSchema;

export const checkDppInfoUploadSchema = (
  data: IndexedSignatureTypeData | FormData
) => {
  return checkSchema(data, DppInfoUploadSchema, dppInfoFieldList);
};

export const checkDppInfoUpdateSchema = (
  data: IndexedSignatureTypeData | FormData
) => {
  return checkSchema(data, DppInfoUpdateSchema, dppInfoFieldList);
};
