import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import { hiddenScrollbar } from './scrollbarStyles';

const styled = { createGlobalStyle };

const GlobalStyles = styled.createGlobalStyle`
  ${reset}

  @font-face {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 400 700;
    font-display: swap;
    src: url('https://fonts.googleapis.com/css2?family=Pretendard:wght@400;500;600;700&display=swap');
    unicode-range: U+AC00-D7A3, U+1100-11FF; /* 한글 */
  }

  @font-face {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 300 600;
    font-display: swap;
    src: url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,300;0,400;0,500;0,600&display=swap');
    unicode-range: U+0020-007F, U+00A0-00FF, U+0100-017F, U+0180-024F; /* 라틴문자, 특수문자, 숫자 */
  }

  :root {
    /* 다크 모드 무시 */
    color-scheme: only light;
  }

  @media (prefers-color-scheme: dark) {
    html,
    body {
      background: ${(props) => props.theme.colors.white} !important;
      background-color: ${(props) => props.theme.colors.white} !important;
      color: ${(props) => props.theme.colors.black};
    }
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    font-size: 10px;

    ${hiddenScrollbar}
  }

  body {
    font-family: ${(props) => props.theme.fonts.family};
    font-size: ${(props) => props.theme.fonts.size.md};
    font-weight: ${(props) => props.theme.fonts.weight.regular};
    line-height: ${(props) => props.theme.fonts.lineHeight.normal};
    color: ${(props) => props.theme.colors.black};
    background-color: ${(props) => props.theme.colors.gray50};

    ${hiddenScrollbar}
  }

  h1 {
    font-size: ${(props) => props.theme.fonts.size.xxl};
    font-weight: ${(props) => props.theme.fonts.weight.semibold};
  }

  h2 {
    font-size: ${(props) => props.theme.fonts.size.xl};
    font-weight: ${(props) => props.theme.fonts.weight.semibold};
  }

  label,
  button,
  input,
  textarea {
    font-family: ${(props) => props.theme.fonts.family};
    font-size: ${(props) => props.theme.fonts.size.md};
    font-weight: ${(props) => props.theme.fonts.weight.regular};
    line-height: ${(props) => props.theme.fonts.lineHeight.regular};
  }

  button {
    margin: 0;
    padding: 0;
    color: inherit;
    appearance: none;
    -webkit-appearance: none;
    border: none;
    background: none;
    cursor: pointer;
  }

  input {
    outline: none;
    border: none;
    background-color: ${(props) => props.theme.colors.gray50};
    padding: 0;

    /* 자동완성 기본 스타일 제거 */
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-text-fill-color: ${(props) => props.theme.colors.gray900};
      -webkit-box-shadow: 0 0 0px 1000px ${(props) => props.theme.colors.gray50}
        inset;
    }
  }

  textarea {
    outline: none;
    border: none;
    resize: none;
    padding: 0;

    /* 자동완성 기본 스타일 제거 */
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-text-fill-color: ${(props) => props.theme.colors.gray900};
      -webkit-box-shadow: 0 0 0px 1000px ${(props) => props.theme.colors.gray50}
        inset;
    }
  }

  a {
    color: ${(props) => props.theme.colors.black};
    text-decoration: none;
  }

  li {
    list-style: none;
  }
`;

export default GlobalStyles;
