import { Button, Icon } from '@components/admin/@shared/atoms';
import { ModalContainer } from '@components/admin/@shared/molecules';
import styled from 'styled-components';

interface LandingModalSuccessProps {
  onClose: () => void;
}

export function LandingModalSuccess({ onClose }: LandingModalSuccessProps) {
  const handleClose = () => {
    window.location.reload();
  };

  return (
    <ModalContainer onCloseModal={onClose}>
      <Container>
        <Content>
          <Icon name="CheckCircle" width={72} height={72} />
          <DescriptionContainer>
            <Description>신청이 완료되었어요!</Description>
            <Description>
              서비스가 준비되면 이메일 또는 메신저를 통해 소식을 전해드릴게요.
            </Description>
          </DescriptionContainer>
        </Content>
        <Button label="닫기" onClick={handleClose} />
      </Container>
    </ModalContainer>
  );
}

const Container = styled.div`
  display: flex;
  width: 540px;
  height: 460px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex: 1 0 0;
  align-self: stretch;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Description = styled.div`
  font-size: 16px;
  font-weight: 700;
`;
