import styled from 'styled-components';
import { LogoButton, Icon } from '@components/admin/@shared/atoms';
import {
  BUSINESS_LICENSE_INFO,
  CONTACT_INFO,
  FAMILY_SITE_INFO,
  FooterContent,
  TERMS_AND_CONDITIONS,
} from '@lib/constants/admin/footer';
import { media } from '@lib/styles/breakpoints';

export default function Footer() {
  return (
    <Wrapper>
      <Container>
        <LogoAndContactButton>
          <LogoButton paddingLeft="0" />
          <a
            href="https://careid.center"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button>
              더 알아보기
              <Icon name="ChevronRight" fill="gray600" />
            </Button>
          </a>
        </LogoAndContactButton>
        <FooterContents>
          <Content content={BUSINESS_LICENSE_INFO} itemTitleWidth="100px" />
          <Content content={CONTACT_INFO} itemTitleWidth="70px" />
          <Content content={TERMS_AND_CONDITIONS} />
        </FooterContents>
      </Container>
    </Wrapper>
  );
}

interface ContentProps {
  content: FooterContent;
  itemTitleWidth?: string;
}

function Content({ content, itemTitleWidth }: ContentProps) {
  return (
    <ContentContainer>
      <ContentTitle>{content.title}</ContentTitle>
      <ContentItemList $itemTitleWidth={itemTitleWidth}>
        {content.items.map((item) => (
          <li key={item.name}>
            {item.title && <span>{item.title}</span>}
            {/* 링크 유무에 따라 다른 렌더링 */}
            {item.href ? (
              <a href={item.href} target="_blank" rel="noopener noreferrer">
                {item.value}
              </a>
            ) : (
              <span>{item.value}</span>
            )}
          </li>
        ))}
      </ContentItemList>
    </ContentContainer>
  );
}

const Wrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray25};
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 0px 80px;
  display: flex;
  flex-direction: column;
  gap: 48px;

  ${media.tablet} {
    padding: 40px 10px 80px;
  }
`;

const LogoAndContactButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FooterContents = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;

  & > div:last-child {
    flex: 0 0 120px;
  }

  ${media.mobile} {
    flex-direction: column;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  white-space: pre-wrap;
`;

const ContentTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

interface ContentItemListProps {
  $itemTitleWidth?: string;
}

const ContentItemList = styled.ul<ContentItemListProps>`
  display: flex;
  flex-direction: column;
  gap: 12px;

  // ContentItem
  li {
    display: flex;
    align-items: flex-start;
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray600};

    // 링크가 있는 경우
    a {
      color: inherit;
      text-decoration: underline;
    }

    // ContentItem의 title 스타일
    span:first-child {
      flex: 0 0 ${({ $itemTitleWidth }) => $itemTitleWidth || '0'};
    }
  }
`;

const Button = styled.button`
  width: fit-content;
  display: flex;
  gap: 4px;
  background-color: ${({ theme }) => theme.colors.gray50};
  color: ${({ theme }) => theme.colors.gray600};
  border-radius: 100px;
  padding: 16px;
  font-size: 14px;
  font-weight: 400;
  align-items: center;
  justify-content: center;
  transition:
    background-color 0.3s,
    color 0.3s;

  a {
    display: flex;
    align-items: center;
    color: inherit;
  }

  &:hover {
    background-color: #000;
    color: #fff;

    a {
      color: #fff;
    }

    svg {
      fill: #fff;
    }
  }
`;
