import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CustomAxiosResponseType, DppDetailData } from '@customTypes/admin';
import { brandAdminAxios } from './@core/brand';

/**
 * [ADMIN] 케어라벨 전체 수정 API (v2)
 */
const updateCareLabel = async (data: any) => {
  const res = await brandAdminAxios.patch(`/care-label-project`, data);

  return res?.data;
};

export const useCareLabelUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['careLabelUpdate'],
    mutationFn: (data: any) => updateCareLabel(data),
    onSuccess: () => {
      Promise.all([
        queryClient.invalidateQueries({ queryKey: ['careLabelList'] }),
        queryClient.invalidateQueries({ queryKey: ['careLabelDetail'] }),
      ]);
    },
  });
};

// /**
//  * [ADMIN] 케어라벨 전체 수정 API
//  */
// const updateCareLabel = async (data: any) => {
//   const res = (await brandAdminAxios.patch(
//     `/care-label-project`,
//     data
//   )) as CustomAxiosResponseType;

//   return res?.msg;
// };

// export const useCareLabelUpdate = () => {
//   const queryClient = useQueryClient();

//   return useMutation({
//     mutationKey: ['careLabelUpdate'],
//     mutationFn: (data: any) => updateCareLabel(data),
//     onSuccess: () => {
//       Promise.all([
//         queryClient.invalidateQueries({ queryKey: ['careLabelList'] }),
//         queryClient.invalidateQueries({ queryKey: ['careLabelDetail'] }),
//       ]);
//     },
//   });
// };

/**
 * [ADMIN] 케어라벨 일부 수정 API
 * @description for 이름 수정, JSON 및 썸네일 수정
 */
const updateCareLabelPartially = async (data: FormData) => {
  const res = (await brandAdminAxios.put(
    `/care-label-project-json`,
    data
  )) as CustomAxiosResponseType;

  return res?.msg;
};

export const useCareLabelPartiallyUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['careLabelPartiallyUpdate'],
    mutationFn: (data: FormData) => updateCareLabelPartially(data),
    onSuccess: () => {
      Promise.all([
        queryClient.invalidateQueries({ queryKey: ['careLabelList'] }),
        queryClient.invalidateQueries({ queryKey: ['careLabelDetail'] }),
      ]);
    },
  });
};

/**
 * [ADMIN] DPP 상세 조회 API
 */
const getCareLabelDppDetail = async (no: string): Promise<DppDetailData> => {
  const res = await brandAdminAxios.get(`/dpp-detail?no=${no}`);

  return res?.data;
};

export const useCareLabelDppDetail = (no?: string | null) => {
  return useQuery({
    queryKey: ['careLabelDppDetail', no],
    queryFn: () =>
      no ? getCareLabelDppDetail(no) : Promise.reject('No no. provided'),
    enabled: !!no,
  });
};
