import { z } from 'zod';

export const dppSizeSchema = z.object({
  size_name: z.string().min(1, '사이즈를 입력해 주세요'),
  quantity: z
    .string()
    .refine((value) => Number.isInteger(Number(value)) && Number(value) > 0, {
      message: '수량은 양의 정수만 입력 가능합니다',
    })
    .optional(),
  //   quantity: z.number().optional(),
});

export type DppIssueSchemaType = z.infer<typeof dppIssueSchema>;

export const dppIssueSchema = z.object({
  no: z.number().min(1, 'DPP 번호를 입력해 주세요'),
  size: z.array(dppSizeSchema).min(1, '사이즈를 입력해 주세요'),
});
