import styled from 'styled-components';
import { media } from '@lib/styles/breakpoints';

interface LayoutProps {
  children: React.ReactNode;
}

export default function Layout({ children }: LayoutProps) {
  return (
    <Wrapper>
      <Container>{children}</Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Crect fill='%23fff' width='100' height='100'/%3E%3Ccircle fill='%23f3f3f3' cx='50' cy='50' r='11'/%3E%3C/svg%3E");
  background-size: 10px;
  background-repeat: repeat;
  min-height: 100vh;
  display: grid;

  ${media.tablet} {
    padding: 0 10px;
  }
`;

const Container = styled.main`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  overflow-x: hidden;

  ${media.laptop} {
    // max-width: 1000px;
  }

  ${media.tablet} {
    // max-width: 800px;
  }

  ${media.mobile} {
    // max-width: 100%;
    // padding: 0 16px;
  }
`;
