import { css } from 'styled-components';

export const defaultScrollbar = css`
  &::-webkit-scrollbar {
    width: 24px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: ${(props) => props.theme.colors.gray400};
    background-clip: padding-box;
    border-left: 20px solid transparent;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${(props) => props.theme.colors.gray600};
  }

  &::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.colors.gray100};
    background-clip: padding-box;
    border-left: 20px solid transparent;
  }
`;

export const wideMinimalScrollbar = css`
  &::-webkit-scrollbar {
    width: 14px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: ${(props) => props.theme.colors.black};
    /* margin */
    background-clip: padding-box;
    border-left: 12px solid transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.colors.gray200};
    /* margin */
    background-clip: padding-box;
    border-left: 12px solid transparent;
  }
`;

export const roundedMinimalScrollbar = css`
  &::-webkit-scrollbar {
    width: 10px;
    border-bottom-right-radius: ${(props) => props.theme.borderRadiuses.lg};
    background-color: ${(props) => props.theme.colors.gray50};
  }

  &::-webkit-scrollbar-thumb {
    width: 10px;
    background-color: ${(props) => props.theme.colors.gray200};
    border-radius: ${(props) => props.theme.borderRadiuses.lg};
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${(props) => props.theme.colors.gray300};
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;

export const minimalScrollbar = css`
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: ${(props) => props.theme.colors.black};
    /* margin */
    background-clip: padding-box;
  }
  &::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.colors.gray200};
    /* margin */
    background-clip: padding-box;
  }
`;

export const hiddenScrollbar = css`
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &&&::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }
`;
