import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { FieldMessage, MessageType } from '@customTypes/admin';

interface FieldContainerProps extends PropsWithChildren {
  label: string;
  description?: string;
  inputName?: string;
  isRequired?: boolean;
  message?: FieldMessage;
  labelRightElement?: React.ReactNode;
}

export default function FormFieldContainer({
  children,
  label,
  description,
  message,
  inputName,
  isRequired = false,
  labelRightElement,
}: FieldContainerProps) {
  return (
    <FieldContainerWrapper>
      <LabelContainer>
        <label htmlFor={inputName}>{label}</label>
        {isRequired && <RequiredMark>필수</RequiredMark>}
        {description && <Description>{description}</Description>}
        {labelRightElement && labelRightElement}
      </LabelContainer>
      <InputContainer $type={message?.type}>{children}</InputContainer>
      {message?.text && <Message $type={message.type}>{message.text}</Message>}
    </FieldContainerWrapper>
  );
}

const FieldContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

interface InputContainerProps {
  $type?: MessageType;
}

const InputContainer = styled.div<InputContainerProps>`
  margin-top: 4px;
  display: flex;
  gap: 8px;

  &&& input,
  &&& button,
  &&& testarea {
    border-color: ${(props) => {
      switch (props.$type) {
        case 'error':
          return `${props.theme.colors.stateError}`;
        default:
          return `${props.theme.colors.gray200}`;
      }
    }};
  }
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 18px;
  font-weight: 400;
`;

const RequiredMark = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.stateError};
  font-weight: 400;
`;

const Description = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.gray400};
  font-weight: 400;
`;

interface MessageProps {
  $type?: MessageType;
}

const Message = styled.span<MessageProps>`
  font-size: ${(props) => props.theme.fonts.size.sm};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  color: ${(props) => {
    switch (props.$type) {
      case 'success':
        return props.theme.colors.stateSuccess;
      case 'error':
        return props.theme.colors.stateError;
      default:
        return props.theme.colors.stateDefault;
    }
  }};
`;
