import { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

interface MainInnerProps extends CSSProperties {
  children: ReactNode;
  title: string;
}

export default function MainInner({
  children,
  title,
  ...styles
}: MainInnerProps) {
  return (
    <Container style={styles}>
      <Title>{title}</Title>
      {children}
    </Container>
  );
}

const Container = styled.div`
  padding: ${(props) =>
    `calc(${props.theme.spaces.lg} + ${props.theme.spaces.md}) ${props.theme.spaces.xxxxxl}`};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  background-color: ${(props) => props.theme.colors.gray50};
`;

const Title = styled.h2`
  flex: 0 0 auto;
  margin-bottom: ${(props) => props.theme.spaces.lg};
  font-size: ${(props) => props.theme.fonts.size.xxxxl};
`;
