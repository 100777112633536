import { ReactNode } from 'react';
import styled from 'styled-components';

interface BaseModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

function BaseModal({ isOpen, onClose, children }: BaseModalProps) {
  if (!isOpen) return null;

  return (
    <Overlay onClick={onClose}>
      <Content onClick={(e) => e.stopPropagation()}>{children}</Content>
    </Overlay>
  );
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 108px 144px;
`;

const Content = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  min-width: 300px;

  width: 100%;
  height: 100%;
  padding: 40px 60px;
`;

export default BaseModal;
