import { RadioButtonOption } from '@components/admin/@shared/molecules';
import { CARE_LABEL_DESIGN_PREVIEW_SATIN_BLACK_SILVER_IMAGE_URL } from '@lib/constants/imageUrls';
import { CARE_LABEL_DESIGN_PREVIEW_SATIN_BLACK_GOLD_IMAGE_URL } from '@lib/constants/imageUrls';
import { CARE_LABEL_DESIGN_PREVIEW_SATIN_BLACK_GRAY_IMAGE_URL } from '@lib/constants/imageUrls';
import { CARE_LABEL_DESIGN_PREVIEW_SATIN_BLACK_WHITE_IMAGE_URL } from '@lib/constants/imageUrls';
import {
  CARE_LABEL_DESIGN_PREVIEW_CROSS_WHITE_BLACK_IMAGE_URL,
  CARE_LABEL_DESIGN_PREVIEW_URE_TRANSPARENT_BLACK_IMAGE_URL,
} from '@lib/constants/imageUrls';

export type CareLabelInfoKey = (typeof CARE_LABEL_INFO_LIST)[number]['key'];

export const CARE_LABEL_INFO_LIST = [
  {
    label: '컬러',
    key: 'color_mode',
    unit: '',
  },
  {
    label: '국가',
    key: 'nation',
    unit: '',
  },
  {
    label: '가로',
    key: 'width',
    unit: 'mm',
  },
  {
    label: '세로',
    key: 'height',
    unit: 'mm',
  },
  {
    label: '시접',
    key: 'seam_margin',
    unit: 'mm',
  },
  {
    label: '좌우 여백',
    key: 'side_margin',
    unit: 'mm',
  },
  {
    label: '접지 방식',
    key: 'option',
    unit: '',
  },
  {
    label: '디지털 라벨',
    key: 'dpp',
    unit: '',
  },
  {
    label: '재질',
    key: 'textile_name',
    unit: '',
  },
] as const;

export const SIZE_INPUT_FIELDS = [
  { name: 'width', label: '가로' },
  { name: 'height', label: '세로' },
  { name: 'seam_margin', label: '시접' },
  { name: 'side_margin', label: '좌우 여백' },
] as const;

export const PRINT_FORMAT_OPTIONS: RadioButtonOption[] = [
  {
    name: 'single',
    label: '단면 인쇄',
    description: '한쪽 면에만 인쇄합니다.',
  },
  {
    name: 'double',
    label: '양면 인쇄',
    description: '양쪽 면에 인쇄합니다.',
  },
  {
    name: 'fold',
    label: '절반 접기',
    description: '절반으로 접습니다.',
  },
] as const;

export const LABEL_SIZE_MIN_HEIGHT = 32 as const;
export const LABEL_SIZE_MAX_HEIGHT = 240 as const;

interface TextileConfig {
  options: string[];
  imageUrl?: string;
  imageUrls?: Record<string, string>;
}

export const TEXTILE_MAPPING: Record<string, TextileConfig> = {
  WHITE: {
    options: ['blackandwhite'],
    imageUrl: CARE_LABEL_DESIGN_PREVIEW_CROSS_WHITE_BLACK_IMAGE_URL,
  },
  TRANSPARENT: {
    options: ['blackandwhite'],
    imageUrl: CARE_LABEL_DESIGN_PREVIEW_URE_TRANSPARENT_BLACK_IMAGE_URL,
  },
  BLACK: {
    options: ['blackandwhite', 'gray', 'gold', 'silver'],
    imageUrls: {
      blackandwhite: CARE_LABEL_DESIGN_PREVIEW_SATIN_BLACK_WHITE_IMAGE_URL,
      gray: CARE_LABEL_DESIGN_PREVIEW_SATIN_BLACK_GRAY_IMAGE_URL,
      gold: CARE_LABEL_DESIGN_PREVIEW_SATIN_BLACK_GOLD_IMAGE_URL,
      silver: CARE_LABEL_DESIGN_PREVIEW_SATIN_BLACK_SILVER_IMAGE_URL,
    },
  },
} as const;

// 15부터 60까지 5 단위로 옵션 생성
export const CARE_LABEL_WIDTH_OPTIONS = Array.from(
  { length: 10 },
  (_, i) => 15 + i * 5
);

// 케어라벨 주문 단가표
export const CARE_LABEL_PRICE_TABLE_URL =
  'https://careid.s3.ap-northeast-2.amazonaws.com/default/document/%EC%BC%80%EC%96%B4%EB%9D%BC%EB%B2%A8+%EB%8B%A8%EA%B0%80%ED%91%9C20250206.xlsx';
