import { useEffect, MouseEvent, PropsWithChildren } from 'react';
import { useAtom } from 'jotai';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { allowScroll, preventScroll } from '@lib/utils/scroll';
import { scrollYPosAtom } from '@store/scroll';
import { hiddenScrollbar } from '@lib/styles/scrollbarStyles';

const portalElement = document.getElementById('modal') as HTMLElement;

interface ModalProps extends PropsWithChildren {
  onCloseModal: () => void;
}

export default function Modal({ children, onCloseModal }: ModalProps) {
  const [, setScrollY] = useAtom(scrollYPosAtom);

  const handleModelClose = (e: MouseEvent) => {
    if (e.target !== e.currentTarget) return;

    onCloseModal();
  };

  // 모달이 띄워졌을 때 스크롤 방지 (화면 고정)
  useEffect(() => {
    const prevScrollY = preventScroll();
    setScrollY(prevScrollY);

    return () => {
      allowScroll(prevScrollY);
    };
  }, []);

  return createPortal(
    <Wrapper onClick={handleModelClose}>{children}</Wrapper>,
    portalElement
  );
}

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spaces.md};
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  background: ${(props) => props.theme.colors.modalBg};
  z-index: 2000;
  cursor: pointer;

  ${hiddenScrollbar}

  & > * {
    z-index: 2001;
    cursor: default;
  }
`;
