import { IconWrapper } from '@components/@shared';
import styled from 'styled-components';

interface BackButtonProps {
  onClick: () => void;
}

export default function BackButton({ onClick }: BackButtonProps) {
  return (
    <BackButtonWrapper onClick={onClick}>
      <IconWrapper
        iconName="ChevronLeft"
        $width={32}
        $height={32}
        fill="black"
        $borderWidth={1}
      />
      <span>뒤로가기</span>
    </BackButtonWrapper>
  );
}

const BackButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;

  & span {
    font-size: 14px;
    font-weight: 500;
  }
`;
