import { useEffect, useState, forwardRef } from 'react';
import styled from 'styled-components';
import { Icon } from '@components/admin/@shared/atoms';

export type DropdownOption = {
  name: string;
  label: string;
};

interface NarrowDropdownProps {
  inputName: string;
  options?: DropdownOption[];
  value?: string;
  placeholder?: string;
  onChange?: (value: any) => void;
  onBlur?: () => void;
}

const NarrowDropdown = forwardRef<HTMLDivElement, NarrowDropdownProps>(
  (
    { inputName, options, value, placeholder, onChange, onBlur, ...props },
    ref
  ) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [label, setLabel] = useState<string>(placeholder || '');

    const handleOptionClick = (name: string, label: string) => {
      onChange?.(name);
      setLabel(label);
      setIsDropdownOpen(false);
    };

    useEffect(() => {
      if (!options) return;

      const selected = options.find((option) => option.name === value);
      if (!selected) return;

      setLabel(selected.label);
    }, [value, options]);

    return (
      <Container>
        <DropdownButton
          type="button"
          onClick={() => setIsDropdownOpen((prev) => !prev)}
          $isOpen={isDropdownOpen}
        >
          <Label $isSelected={!!value}>{label}</Label>
          <ArrowDownIcon $isOpen={isDropdownOpen}>
            <Icon name="ChevronDown" fill="gray400" width={18} height={18} />
          </ArrowDownIcon>
        </DropdownButton>
        {isDropdownOpen && options && options.length > 0 && (
          <Options>
            {options.map(({ name, label }) => (
              <Option
                onClick={() => handleOptionClick(name, label)}
                key={name}
                $isSelected={name === value}
              >
                {label}
              </Option>
            ))}
          </Options>
        )}
      </Container>
    );
  }
);

// DevTools에서 컴포넌트 이름 표시를 위해 설정
NarrowDropdown.displayName = 'NarrowDropdown';
export default NarrowDropdown;

const DROPDOWN_HEIGHT = '30px';
const ARROW_ICON_SIZE = '18px';

const Container = styled.div`
  position: relative;
  width: 100%;
  align-self: center;
  font-size: ${({ theme }) => theme.fonts.size.sm};
  cursor: pointer;
`;

interface StyledDropdownProps {
  $isOpen?: boolean;
}

const DropdownButton = styled.button<StyledDropdownProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${DROPDOWN_HEIGHT};
  color: ${({ theme }) => theme.colors.gray400};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray200};
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0 10px;
  cursor: pointer;
  /* transition: all 100ms ease-in; */

  ${({ theme, $isOpen }) =>
    $isOpen &&
    `
      border-color: ${theme.colors.gray400};
      border-bottom-left-radius: ${theme.borderRadiuses.none};
      border-bottom-right-radius: ${theme.borderRadiuses.none};
      border-bottom: 1px solid ${theme.colors.gray100};
    `}
`;

interface StyledLabelProps {
  $isSelected?: boolean;
}

const Label = styled.label<StyledLabelProps>`
  display: block;
  width: 100%;
  margin: 0;
  font-size: 14px;
  text-align: left;
  color: ${({ $isSelected, theme }) =>
    $isSelected ? theme.colors.gray900 : theme.colors.gray400};
  cursor: pointer;
`;

const ArrowDownIcon = styled.div<StyledDropdownProps>`
  display: flex;
  align-items: center;
  transform: ${({ $isOpen }) => ($isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 300ms
    ${({ theme }) => theme.animations.customCubicBezier};

  & > img {
    width: ${ARROW_ICON_SIZE};
    height: ${ARROW_ICON_SIZE};
    object-fit: contain;
  }
`;

const Options = styled.ul<StyledDropdownProps>`
  padding: 4px;
  position: absolute;
  top: ${DROPDOWN_HEIGHT};
  left: 0;
  width: 100%;
  overflow: hidden;
  border-bottom-left-radius: ${({ theme }) => theme.borderRadiuses.lg};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadiuses.lg};
  border: 1px solid ${({ theme }) => theme.colors.gray400};
  border-top: none;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 1;
`;

interface OptionProps {
  $isSelected?: boolean;
}

const Option = styled.li<OptionProps>`
  padding: 5px 6px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray900};
  border-radius: 8px;
  background-color: ${({ theme, $isSelected }) =>
    $isSelected ? theme.colors.gray100 : theme.colors.white};
  transition: all 100ms ease-in;
  cursor: pointer;

  ${({ theme, $isSelected }) =>
    !$isSelected &&
    `
      &:hover {
        background-color: ${theme.colors.gray50};
      }
    `}
`;
