import { useAtom } from 'jotai';
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import {
  careLabelSearchKeywordsAtom,
  currPageAtom,
  numberOfItemsAtom,
} from '@store/admin/careLabel/list';
import {
  SearchKeywords,
  CareLabelListData,
  CustomAxiosResponseType,
} from '@customTypes/admin';
import { brandAdminAxios } from './@core/brand';

/**
 * [ADMIN] 케어라벨 리스트 조회 API
 */
const fetchCareLabelList = async (
  searchKeywords?: SearchKeywords,
  page: number = 1,
  numberOfItems: number = 12
): Promise<CareLabelListData> => {
  let queryParam = `?page=${page}&items=${numberOfItems}`;

  if (searchKeywords?.name) {
    queryParam += `&name=${searchKeywords.name}`;
  }

  const res = await brandAdminAxios.get(
    '/care-label-project-list' + queryParam
  );

  return res?.data;
};

export const useCareLabelList = () => {
  const [searchKeywords] = useAtom(careLabelSearchKeywordsAtom);
  const [currPage] = useAtom(currPageAtom);
  const [numberOfItems] = useAtom(numberOfItemsAtom);

  return useSuspenseQuery({
    queryKey: ['careLabelList', searchKeywords, currPage, numberOfItems],
    queryFn: () => fetchCareLabelList(searchKeywords, currPage, numberOfItems),
  });
};

/**
 * [ADMIN] 케어라벨 삭제 API
 */
const deleteCareLabel = async (noList: number[]) => {
  const res = (await brandAdminAxios.delete(`/care-label-project`, {
    data: { no: noList },
  })) as CustomAxiosResponseType;
  return res?.msg;
};

export const useCareLabelDelete = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['careLabelDelete'],
    mutationFn: (noList: number[]) => deleteCareLabel(noList),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['careLabelList'] });
    },
  });
};

/**
 * [ADMIN] 케어라벨 복제 API
 */
const cloneCareLabel = async (no: number) => {
  const res = (await brandAdminAxios.post(`/care-label-project-copy`, {
    no,
  })) as CustomAxiosResponseType;

  return res?.msg;
};

export const useCareLabelClone = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['careLabelClone'],
    mutationFn: (no: number) => cloneCareLabel(no),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['careLabelList'] });
    },
  });
};
