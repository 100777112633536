import { z } from 'zod';
import { validateImageWithCustomError } from './signUp';

export type CareLabelFormatSchemaType = z.infer<typeof careLabelFormatSchema>;
export type CareLabelTextileSchemaType = z.infer<typeof careLabelTextileSchema>;
export type CareLabelSizeAndTemplateSchemaType = z.infer<
  typeof careLabelSizeAndTemplateSchema
>;
export type CareLabelDppSchemaType = z.infer<typeof careLabelDppSchema>;
export type CareLabelNoDppSchemaType = z.infer<typeof careLabelNoDppSchema>;
export type CareLabelDesignSchemaType = z.infer<typeof careLabelDesignSchema>;
export type CareLabelNameSchemaType = z.infer<typeof careLabelNameSchema>;
export type CareLabelCreateSchemaType = z.infer<typeof careLabelCreateSchema>;

export const careLabelFormatSchema = z.object({
  category: z.string().min(1, '카테고리를 선택해 주세요'),
  nation: z.string().min(1, '사용 국가를 선택해 주세요'),
  color_mode: z.string().min(1, '컬러를 선택해 주세요'),
});

export const careLabelTextileSchema = z.object({
  textile: z.string().min(1, '원단을 선택해 주세요'),
});

export const careLabelSizeAndTemplateSchema = z.object({
  /**
   * @todo 접지 방식 타입 수정 필요 (선택지 정해져 있음)
   */
  option: z.string().min(1, '접지 방식을 선택해 주세요'),
  width: z
    .string()
    .min(1, '라벨 가로 길이를 입력해 주세요')
    .refine((value) => Number.isInteger(Number(value)) && Number(value) > 0, {
      message: '라벨 가로 길이는 양의 정수만 입력 가능합니다',
    }),
  height: z
    .string()
    .min(1, '라벨 세로 길이를 입력해 주세요')
    .refine((value) => Number.isInteger(Number(value)) && Number(value) > 0, {
      message: '라벨 세로 길이는 양의 정수만 입력 가능합니다',
    })
    .refine((value) => Number(value) >= 32 && Number(value) <= 240, {
      message: '라벨 세로 길이는 32mm 이상, 240mm 이하여야 합니다',
    }),
  seam_margin: z
    .string()
    .min(1, '시접을 입력해 주세요')
    .refine((value) => Number.isInteger(Number(value)) && Number(value) >= 0, {
      message: '시접은 0 이상의 정수만 입력 가능합니다',
    }),
  side_margin: z
    .string()
    .min(1, '좌우 여백을 입력해 주세요')
    .refine((value) => Number.isInteger(Number(value)) && Number(value) >= 0, {
      message: '좌우 여백은 0 이상의 정수만 입력 가능합니다',
    }),
  template_code: z.string().optional(),
});

export type ProductSizeSchemaType = z.infer<typeof productSizeSchema>;

export const productSizeSchema = z.object({
  size_name: z.string().min(1, '사이즈 호칭을 입력해 주세요'),
  size_value: z.string().min(1, '사이즈 값을 입력해 주세요'),
  // 총 기장
  size_outseam: z.string().nullable().optional(),
  // 어깨 너비
  size_shoulder_width: z.string().nullable().optional(),
  // 가슴 단면
  size_chest_width: z.string().nullable().optional(),
  // 소매 길이
  size_sleeve_length: z.string().nullable().optional(),
  // 허리 단면
  size_waist_width: z.string().nullable().optional(),
  // 밑위
  size_raise_length: z.string().nullable().optional(),
  // 엉덩이 단면
  size_back_rise: z.string().nullable().optional(),
  // 허벅지 단면
  size_thigh_width: z.string().nullable().optional(),
  // 밑단 단면
  size_leg_opening: z.string().nullable().optional(),
});

export const careLabelDppSchema = z.object({
  dpp: z.enum(['true', 'false']),
  dpp_no: z
    .string()
    .min(1, 'DPP를 적용할 SKU를 선택해 주세요')
    .refine((value) => Number.isInteger(Number(value)) && Number(value) > 0, {
      message: 'DPP 번호가 유효하지 않습니다',
    }),
});

export const careLabelNoDppSchema = z.object({
  dpp: z.enum(['true', 'false']),
  size: z
    .array(productSizeSchema, {
      message: '사이즈를 하나 이상 입력해 주세요',
    })
    .min(1, '사이즈를 하나 이상 입력해 주세요'),
  // .refine(
  //   (sizes) => {
  //     if (sizes.length <= 1) return true;

  //     const firstSize = sizes[0] as ProductSizeSchemaType;
  //     const pattern = (
  //       Object.keys(firstSize) as Array<keyof ProductSizeSchemaType>
  //     ).reduce(
  //       (acc, key) => {
  //         acc[key] =
  //           firstSize[key] !== null &&
  //           firstSize[key] !== undefined &&
  //           firstSize[key] !== '';
  //         return acc;
  //       },
  //       {} as Record<keyof ProductSizeSchemaType, boolean>
  //     );

  //     return sizes.every((size) =>
  //       Object.keys(size).every(
  //         (key) =>
  //           pattern[key as keyof ProductSizeSchemaType] ===
  //           (size[key as keyof ProductSizeSchemaType] !== null &&
  //             size[key as keyof ProductSizeSchemaType] !== undefined)
  //       )
  //     );
  //   },
  //   { message: '모든 사이즈는 동일한 항목을 가져야 합니다' }
  // ),
});

export const careLabelDesignSchema = z.object({
  /**
   * @todo json 타입 수정 필요
   */
  json: z.any(),
  thumbnail_img: validateImageWithCustomError('썸네일 이미지'),
});

export const careLabelNameSchema = z.object({
  name: z.string().min(1, '이름을 입력해 주세요'),
});

export const careLabelCreateSchema = careLabelFormatSchema
  .merge(careLabelTextileSchema)
  .merge(careLabelSizeAndTemplateSchema)
  .merge(careLabelDesignSchema)
  .merge(careLabelNameSchema);

export const validateMargins = (schema: z.ZodType) => {
  return schema
    .refine((data) => Number(data.seam_margin) <= Number(data.height), {
      message: '시접은 라벨 세로 길이 이하여야 합니다',
      path: ['seam_margin'],
    })
    .refine((data) => Number(data.side_margin) <= Number(data.width), {
      message: '좌우 여백은 라벨 가로 길이 이하여야 합니다',
      path: ['side_margin'],
    });
};

export const createCareLabelCreateSchema = (isDppLinked: boolean) => {
  // DPP 미연동 선택 시 dpp_data 필드 제거
  const baseSchema = careLabelCreateSchema.merge(
    isDppLinked ? careLabelDppSchema : careLabelNoDppSchema
  );

  //   공통 refine 로직 추가
  const validatedSchema = validateMargins(baseSchema);

  return validatedSchema;
};
