import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  accessTokenAtomWithPersistence,
  chargeBrandAtomWithPersistence,
  roleAtomWithPersistence,
} from '@store/login';
import { Icon } from '@components/@shared';
import { ADMIN_PAGE_PATH } from '@lib/constants/admin/common';
import { useLogout } from '@lib/hooks/logout/useLogout';

export default function LoginButton() {
  const { t } = useTranslation('admin/common');
  const navigate = useNavigate();

  const [accessToken, setAccessToken] = useAtom(accessTokenAtomWithPersistence);
  const [, setRole] = useAtom(roleAtomWithPersistence);
  const [, setChargeBrand] = useAtom(chargeBrandAtomWithPersistence);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const { handleLogout } = useLogout({ path: ADMIN_PAGE_PATH.guest.login });

  const handleButtonHovered = () => {
    setIsHovered(true);
  };

  const handleButtonUnhovered = () => {
    setIsHovered(false);
  };

  const handleLoginBtnClick = () => {
    navigate(ADMIN_PAGE_PATH.guest.login);
  };

  return (
    <Container
      type="button"
      onClick={accessToken ? handleLogout : handleLoginBtnClick}
      onMouseEnter={handleButtonHovered}
      onMouseLeave={handleButtonUnhovered}
    >
      <Icon name="Power" fill={isHovered ? 'gray900' : 'gray600'} />
      <span>{accessToken ? t('profile.logout') : t('profile.login')}</span>
    </Container>
  );
}

const Container = styled.button`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spaces.xxs};
  font-size: ${(props) => props.theme.fonts.size.sm};
  font-weight: ${(props) => props.theme.fonts.weight.semibold};
  color: ${(props) => props.theme.colors.gray600};
  word-break: keep-all;

  &:hover {
    color: ${(props) => props.theme.colors.gray900};
  }
`;
