import {
  ChangeEvent,
  CSSProperties,
  Dispatch,
  KeyboardEvent,
  MouseEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { resetAllInputsValue } from '@lib/utils';
import { SearchKeywords } from '@customTypes/admin';
import { Icon } from '@components/@shared';

export interface SearchInput {
  name: string;
  placeholder?: string;
  label?: string;
}

interface SearchFormProps extends CSSProperties {
  inputList: SearchInput[];
  initKeywords: SearchKeywords;
  onSetKeywords: Dispatch<SetStateAction<SearchKeywords>>;
}

export default function SearchBar({
  inputList,
  initKeywords,
  onSetKeywords,
  ...styles
}: SearchFormProps) {
  const searchInputListRef = useRef<(HTMLInputElement | null)[]>([]);
  const [keywords, setKeywords] = useState<SearchKeywords>(initKeywords);

  // 검색어 변경해주는 함수
  const handleKeywordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setKeywords({
      ...keywords,
      [e.target.name]: e.target.value,
    });
  };

  // 검색어 저장해주는 함수
  const handleKeywordSearch = () => {
    onSetKeywords(keywords);
  };
  // Enter 키 눌렀을 때도 검색어 저장
  const handleEnterKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleKeywordSearch();
    }
  };

  // 검색어 초기화해주는 함수
  const handleKeywordReset = () => {
    resetAllInputsValue(searchInputListRef);
    setKeywords(initKeywords);
    onSetKeywords(initKeywords);

    // onSetKeywords((prev) =>
    //   Object.keys(prev).reduce<SearchKeywords>((acc, key) => {
    //     acc[key] = '';
    //     return acc;
    //   }, {})
    // );
  };

  useEffect(() => {
    return () => {
      handleKeywordReset();
    };
  }, []);

  return (
    <Container style={{ ...styles }}>
      <SearchInputList>
        {inputList.map((input, index) => (
          <SearchInputContainer key={input.name}>
            <SearchInputLabel htmlFor={input.name}>
              {input.label}
            </SearchInputLabel>
            <SearchInput
              type="search"
              id={input.name}
              ref={(el) => (searchInputListRef.current[index] = el)}
              name={input.name}
              placeholder="입력해주세요"
              value={keywords[input.name]}
              onChange={handleKeywordChange}
              onKeyDown={handleEnterKeyDown}
            />
          </SearchInputContainer>
        ))}
      </SearchInputList>
      <ButtonsContainer>
        <ResetButton type="button" onClick={handleKeywordReset}>
          <Icon name="Close" fill="gray200" width={24} height={24} />
        </ResetButton>
        <SearchButton type="button" onClick={handleKeywordSearch}>
          <Icon name="MagnifierSearch" fill="white" width={20} height={20} />
        </SearchButton>
      </ButtonsContainer>
    </Container>
  );
}

const Container = styled.div`
  padding: ${(props) =>
    `${props.theme.spaces.xxxxs} ${props.theme.spaces.xxs}`};
  padding-left: ${(props) => props.theme.spaces.xs};
  height: 50px;
  display: flex;
  gap: 10px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.borderRadiuses.lg};
  border: 1px solid ${(props) => props.theme.colors.gray200};
  overflow: hidden;
  max-width: 360px;

  @media (max-width: 959px) {
    max-width: 100%;
  }
`;

const SearchInputList = styled.div`
  /* width: 460px; */
  width: 100%;
  flex-basis: 100px;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  gap: ${(props) => props.theme.spaces.xxs};
`;

const SearchInputContainer = styled.div`
  flex: 1 0 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid ${(props) => props.theme.colors.gray100};
`;

const SearchInputLabel = styled.label`
  font-size: ${(props) => props.theme.fonts.size.xs};
  font-weight: ${(props) => props.theme.fonts.weight.medium};
  color: ${(props) => props.theme.colors.gray900};
`;

const SearchInput = styled.input`
  padding: 0;
  width: 100%;
  font-size: ${(props) => props.theme.fonts.size.sm};
  background-color: ${(props) => props.theme.colors.white};
  border: none;

  &::placeholder {
    color: ${(props) => props.theme.colors.gray400};
  }

  /* 기본 X 아이콘 제거 */
  &::-ms-clear,
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ResetButton = styled.button`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};

  & > img {
    display: block;
    width: 100%;
    width: 100%;
    object-fit: contain;
  }
`;

const SearchButton = styled.button`
  padding: 6px;
  /* width: 32px;
  height: 32px; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${(props) => props.theme.fonts.weight.semibold};
  background-color: ${(props) => props.theme.colors.gray900};
  border-radius: ${(props) => props.theme.borderRadiuses.full};

  & > img {
    display: block;
    width: 100%;
    width: 100%;
    object-fit: contain;
  }
`;
