import axios from 'axios';
import JSZip from 'jszip';
import { toast } from 'react-toastify';
import { Parser as Xml2JsParser } from 'xml2js';
import { pxToMm } from './unit';
import { utf8ToBase64 } from './image';

export const downloadFile = (url: string, fileName: string) => {
  // a 태그 생성
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  // a 태그 제거
  link.parentNode?.removeChild(link);
};

/**
 * 파일 다운로드 함수
 * @param data 파일 데이터
 * @param type 파일 타입
 */
export const createLinkAndDownloadFile = (data: any, type?: string) => {
  const blob = new Blob([data], {
    type:
      type ||
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const url = window.URL.createObjectURL(blob);
  const fileName = 'qr_list.xlsx';
  downloadFile(url, fileName);
  window.URL.revokeObjectURL(url);
};

/**
 * pdf -> pptx -> json 변환 함수
 */
export const convertPdfToJson = async (file: File) => {
  const { downloadUrl } = await convertPdfToPptx(file);
  const json = await convertPptxFromUrlToJson(downloadUrl);

  return json;
};

/**
 * pdf 파일을 받아서 변환한 pptx 파일 다운로드 url 제공하는 함수
 * @param pdfFile pdf 파일
 * @returns pptx 파일 다운로드 url
 */
export const convertPdfToPptx = async (file: File) => {
  const apiKey = process.env.REACT_APP_CLOUD_CONVERT_API_KEY;
  if (!apiKey) {
    throw new Error(`CloudConvert API key is not set. Current key: ${apiKey}`);
  }

  try {
    // Step 1: Create job
    const createJobResponse = await axios.post(
      'https://api.cloudconvert.com/v2/jobs',
      {
        tasks: {
          'import-file': {
            operation: 'import/upload',
          },
          'convert-file': {
            operation: 'convert',
            input: 'import-file',
            output_format: 'pptx',
          },
          'export-file': {
            operation: 'export/url',
            input: 'convert-file',
          },
        },
      },
      {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        },
      }
    );

    // Step 2: Upload file
    const uploadTask = createJobResponse.data.data.tasks.find(
      (task: any) => task.name === 'import-file'
    );

    const formData = new FormData();
    // 파라미터 순서 변경 및 key 추가
    Object.entries(uploadTask.result.form.parameters).forEach(
      ([key, value]) => {
        formData.append(key, value as string);
      }
    );
    formData.append('file', file);

    await axios.post(uploadTask.result.form.url, formData);

    // Step 3: Wait for job completion
    const jobId = createJobResponse.data.data.id;
    let job;
    do {
      const jobResponse = await axios.get(
        `https://api.cloudconvert.com/v2/jobs/${jobId}`,
        {
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );
      job = jobResponse.data.data;
      await new Promise((resolve) => setTimeout(resolve, 1000));
    } while (job.status !== 'finished' && job.status !== 'error');

    if (job.status === 'error') {
      throw new Error('Conversion failed');
    }

    // Step 4: Get download URL
    const exportTask = job.tasks.find(
      (task: any) => task.name === 'export-file'
    );
    const downloadUrl = exportTask.result.files[0].url;

    return {
      message: 'File converted successfully',
      downloadUrl,
    };
  } catch (error) {
    console.error('Error during conversion:', error);
    throw error;
  }
};

/**
 * pptx 파일 다운로드 url을 받아서 json으로 변환하는 함수
 * @param url pptx 파일 다운로드 url
 * @returns json
 */
export const convertPptxFromUrlToJson = async (url: string) => {
  // Step 1: Download PPTX file from the URL
  console.log('Downloading PPTX file...');
  const response = await axios.get(url, { responseType: 'arraybuffer' });
  const pptxData = response.data;

  // Step 2: Load the PPTX file
  const zip = await JSZip.loadAsync(pptxData);

  const slides = [];
  const parser = new Xml2JsParser();

  // EMU to mm conversion function
  const emuToMm = (emu: number) => Math.round((emu / 914400) * 25.4);

  // Extract slide size information
  const presentationXml = await zip.files['ppt/presentation.xml'].async('text');
  const presentationJson = await parser.parseStringPromise(presentationXml);
  const slideSize = presentationJson['p:presentation']['p:sldSz'][0]['$'];
  const slideWidth = emuToMm(parseInt(slideSize.cx, 10));
  const slideHeight = emuToMm(parseInt(slideSize.cy, 10));

  // Iterate over each slide file in PPTX
  for (const [fileName, file] of Object.entries(zip.files)) {
    if (fileName.startsWith('ppt/slides/slide') && fileName.endsWith('.xml')) {
      const slideXml = await file.async('text');

      // Parse XML content
      const slideJson = await parser.parseStringPromise(slideXml);
      const slideObjects = [];

      // Extract information about shapes (e.g., position, size, text)
      const shapes = slideJson['p:sld']['p:cSld'][0]['p:spTree'][0]['p:sp'];
      if (shapes) {
        shapes.forEach((shape: any) => {
          const xfrm = shape['p:spPr'][0]['a:xfrm'][0];
          const x = emuToMm(parseInt(xfrm['a:off'][0]['$'].x, 10));
          const y = emuToMm(parseInt(xfrm['a:off'][0]['$'].y, 10));
          const width = emuToMm(parseInt(xfrm['a:ext'][0]['$'].cx, 10));
          const height = emuToMm(parseInt(xfrm['a:ext'][0]['$'].cy, 10));

          let textContent = '';
          if (shape['p:txBody'] && shape['p:txBody'][0]['a:p']) {
            const paragraphs = shape['p:txBody'][0]['a:p'];
            textContent = paragraphs
              .map((paragraph: any) => {
                if (paragraph['a:r']) {
                  return paragraph['a:r']
                    .map((run: any) => (run['a:t'] ? run['a:t'][0] : ''))
                    .join('');
                } else if (paragraph['a:t']) {
                  return paragraph['a:t'][0];
                }
                return '';
              })
              .join('\n');
          }

          slideObjects.push({
            type: shape['p:nvSpPr'][0]['p:cNvPr'][0]['$'].name || 'unknown',
            x: x,
            y: y,
            width: width,
            height: height,
            text: textContent,
          });
        });
      }

      // Extract information about pictures (images)
      const pictures = slideJson['p:sld']['p:cSld'][0]['p:spTree'][0]['p:pic'];
      if (pictures) {
        for (const picture of pictures) {
          const xfrm = picture['p:spPr'][0]['a:xfrm'][0];
          const x = emuToMm(parseInt(xfrm['a:off'][0]['$'].x, 10));
          const y = emuToMm(parseInt(xfrm['a:off'][0]['$'].y, 10));
          const width = emuToMm(parseInt(xfrm['a:ext'][0]['$'].cx, 10));
          const height = emuToMm(parseInt(xfrm['a:ext'][0]['$'].cy, 10));

          // Extract image reference
          let imageFileName = '';
          let imageBase64 = '';
          if (picture['p:blipFill'] && picture['p:blipFill'][0]['a:blip']) {
            const embed = picture['p:blipFill'][0]['a:blip'][0]['$']['r:embed'];
            const relsFilePath =
              fileName.replace('slides/slide', 'slides/_rels/slide') + '.rels';
            const relsFile = zip.files[relsFilePath];
            if (relsFile) {
              const relsXml = await relsFile.async('text');
              const relsJson = await parser.parseStringPromise(relsXml);
              const relationship = relsJson['Relationships'][
                'Relationship'
              ].find((rel: any) => rel['$'].Id === embed);
              if (relationship) {
                imageFileName = relationship['$'].Target.replace(
                  '../media/',
                  ''
                );
                const imagePath = `ppt/media/${imageFileName}`;
                const imageFile = zip.files[imagePath];
                if (imageFile) {
                  const imageData = await imageFile.async('nodebuffer');
                  imageBase64 = imageData.toString('base64');
                }
              }
            }
          }

          slideObjects.push({
            type: 'image',
            x: x,
            y: y,
            width: width,
            height: height,
            imageFile: imageFileName,
            imageBase64: imageBase64
              ? `data:image/png;base64,${imageBase64}`
              : null,
          });
        }
      }

      slides.push({
        slideNumber: slides.length + 1,
        width: slideWidth,
        height: slideHeight,
        objects: slideObjects,
      });
    }
  }

  console.log('PPTX parsed and saved to output.json');

  return { slides };
};

// function getFileExtension(file: File) {
//   const fileName = file.name;
//   const extension = fileName
//     .substring(fileName.lastIndexOf('.') + 1)
//     .toLowerCase();
//   return extension;
// }

export const getFileExtension = (file: File): string => {
  const fileName = file.name;
  const extension = fileName.split('.').pop()?.toLowerCase() || '';

  return extension;
};

/**
 * pdf 파일을 받아서 변환한 pptx 파일 다운로드 url 제공하는 함수
 * @param pdfFile pdf 파일
 * @returns pptx 파일 다운로드 url
 */
export const convertAiPdfToSvgUrl = async (file: File) => {
  const apiKey = process.env.REACT_APP_CLOUD_CONVERT_API_KEY;
  if (!apiKey) {
    throw new Error(`CloudConvert API key is not set. Current key: ${apiKey}`);
  }

  try {
    const fileExtension = getFileExtension(file);
    console.log(fileExtension);

    if (fileExtension !== 'pdf' && fileExtension !== 'ai') {
      toast.error('ai 또는 pdf 파일을 선택해 주세요');
      return;
    }

    // Step 1: Create job
    const createJobResponse = await axios.post(
      'https://api.cloudconvert.com/v2/jobs',
      {
        tasks: {
          'import-file': {
            operation: 'import/upload',
          },
          'convert-file': {
            operation: 'convert',
            // pdf or ai
            input: 'import-file',
            input_format: fileExtension,
            output_format: 'svg',
          },
          'export-file': {
            operation: 'export/url',
            input: 'convert-file',
          },
        },
      },
      {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        },
      }
    );

    console.log('STEP1 ::: createJobResponse', createJobResponse);

    // Step 2: Upload file
    const uploadTask = createJobResponse.data.data.tasks.find(
      (task: any) => task.name === 'import-file'
    );

    const formData = new FormData();
    // 파라미터 순서 변경 및 key 추가
    Object.entries(uploadTask.result.form.parameters).forEach(
      ([key, value]) => {
        formData.append(key, value as string);
      }
    );
    formData.append('file', file);

    await axios.post(uploadTask.result.form.url, formData);

    console.log('STEP2 ::: formData', formData);

    // Step 3: Wait for job completion
    const jobId = createJobResponse.data.data.id;
    let job;
    do {
      const jobResponse = await axios.get(
        `https://api.cloudconvert.com/v2/jobs/${jobId}`,
        {
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );
      job = jobResponse.data.data;
      await new Promise((resolve) => setTimeout(resolve, 1000));
    } while (job.status !== 'finished' && job.status !== 'error');

    if (job.status === 'error') {
      throw new Error('Conversion failed');
    }

    console.log('STEP3 ::: job', job);

    // Step 4: Get download URL
    const exportTask = job.tasks.find(
      (task: any) => task.name === 'export-file'
    );
    const downloadUrl = exportTask.result.files[0].url;

    console.log('STEP4 ::: downloadUrl', downloadUrl);

    return {
      message: 'File converted successfully',
      downloadUrl,
    };
  } catch (error) {
    console.error('Error during conversion:', error);
    throw error;
  }
};

/**
 * pptx 파일 다운로드 url을 받아서 json으로 변환하는 함수
 * @param url pptx 파일 다운로드 url
 * @returns json
 */
export const getSvgDetailsFromUrl = async (url: string) => {
  const response = await fetch(url);

  if (!response.ok) {
    throw new Error(
      `Failed to fetch SVG: ${response.status} ${response.statusText}`
    );
  }

  try {
    // Read the SVG text content
    const svgText = await response.text();

    // Parse the SVG using DOMParser (browser API)
    const parser = new DOMParser();

    const svgDoc = parser.parseFromString(svgText, 'image/svg+xml');
    const svgElement = svgDoc.documentElement;

    if (!svgElement || svgElement.nodeName !== 'svg') {
      throw new Error('Invalid SVG content.');
    }

    const widthPx = parseFloat(svgElement.getAttribute('width') || '0');
    const heightPx = parseFloat(svgElement.getAttribute('height') || '0');

    const pxToMm = 25.4 / 96;

    const width = Math.floor(widthPx * pxToMm);
    const height = Math.floor(heightPx * pxToMm);

    // Convert SVG to Base64
    const base64 = utf8ToBase64(svgText);
    const base64Image = `data:image/svg+xml;base64,${base64}`;

    // Return the result object
    const result = {
      width,
      height,
      image: base64Image,
    };

    console.log('SVG details in mm:', result);
    return result;
  } catch (error) {
    console.error('Error processing SVG:', error);
    throw error;
  }
};

export const getSvgFromAiPdf = async (file: File) => {
  const res = await convertAiPdfToSvgUrl(file);
  if (!res?.downloadUrl) {
    throw new Error('Failed to get SVG download URL');
  }

  const svgDetails = await getSvgDetailsFromUrl(res.downloadUrl);
  return svgDetails;
};

export const convertAiToPdf = async (file: File) => {
  const apiKey = process.env.REACT_APP_CLOUD_CONVERT_API_KEY;
  if (!apiKey) {
    throw new Error(`CloudConvert API key is not set. Current key: ${apiKey}`);
  }

  try {
    const fileExtension = getFileExtension(file);
    console.log(fileExtension);

    if (fileExtension !== 'ai') {
      toast.error('AI 파일을 선택해 주세요');
      return;
    }

    // Step 1: Create job
    const createJobResponse = await axios.post(
      'https://api.cloudconvert.com/v2/jobs',
      {
        tasks: {
          'import-file': {
            operation: 'import/upload',
          },
          'convert-file': {
            operation: 'convert',
            input: 'import-file',
            input_format: fileExtension,
            output_format: 'pdf', // Output format changed to 'pdf'
          },
          'export-file': {
            operation: 'export/url',
            input: 'convert-file',
          },
        },
      },
      {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        },
      }
    );

    console.log('STEP1 ::: createJobResponse', createJobResponse);

    // Step 2: Upload file
    const uploadTask = createJobResponse.data.data.tasks.find(
      (task: any) => task.name === 'import-file'
    );

    const formData = new FormData();
    Object.entries(uploadTask.result.form.parameters).forEach(
      ([key, value]) => {
        formData.append(key, value as string);
      }
    );
    formData.append('file', file);

    await axios.post(uploadTask.result.form.url, formData);

    console.log('STEP2 ::: formData', formData);

    // Step 3: Wait for job completion
    const jobId = createJobResponse.data.data.id;
    let job;
    do {
      const jobResponse = await axios.get(
        `https://api.cloudconvert.com/v2/jobs/${jobId}`,
        {
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );
      job = jobResponse.data.data;
      await new Promise((resolve) => setTimeout(resolve, 1000));
    } while (job.status !== 'finished' && job.status !== 'error');

    if (job.status === 'error') {
      throw new Error('Conversion failed');
    }

    console.log('STEP3 ::: job', job);

    // Step 4: Get download URL
    const exportTask = job.tasks.find(
      (task: any) => task.name === 'export-file'
    );
    const downloadUrl = exportTask.result.files[0].url;

    console.log('STEP4 ::: downloadUrl', downloadUrl);

    // // Step 5: Download PDF file
    // const response = await axios.get(downloadUrl, {
    //   responseType: 'blob',
    // });

    // // Step 6: Create a Blob URL or directly return the Blob
    // const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
    // return pdfBlob; // 반환값을 Blob으로 설정

    // Step 5: Download PDF file as Blob
    const response = await axios.get(downloadUrl, {
      responseType: 'blob',
    });

    // Step 6: Convert Blob to File and return
    const pdfFile = new File(
      [response.data],
      `${file.name.replace(/\.[^/.]+$/, '')}.pdf`, // 기존 파일명을 유지하고 확장자를 PDF로 변경
      { type: 'application/pdf' }
    );

    return pdfFile;
  } catch (error) {
    console.error('Error during conversion:', error);
    throw error;
  }
};
