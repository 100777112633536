import styled from 'styled-components';
import { Icon } from '@components/@shared';
import { ButtonSecondary } from '@components/admin/@shared/atoms';

interface OrderButtonProps {
  onClick?: () => void;
  disabled?: boolean;
}

export default function OrderButton({ onClick, disabled }: OrderButtonProps) {
  return (
    <ButtonSecondary
      label={
        <LabelContainer>
          <Icon name="Cart" fill={disabled ? 'gray200' : 'black'} />
          <span>주문하기</span>
        </LabelContainer>
      }
      width="fit-content"
      onClick={onClick}
      disabled={disabled}
    />
  );
}

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
`;
