import {
  MouseEvent,
  PropsWithChildren,
  Children,
  CSSProperties,
  ReactNode,
} from 'react';
import styled, { css } from 'styled-components';
import { CHECKBOX_DEFAULT_ICON_URL } from '@lib/constants/iconUrls';
import { Icon, IconName } from '@components/@shared';

interface TableProps extends CSSProperties {
  children: ReactNode;
}

function Table({ children, ...styles }: TableProps) {
  return <Container style={styles}>{children}</Container>;
}

export type Tab = {
  index: number;
  label: string;
  iconName?: IconName;
  component?: () => ReactNode;
};

interface TabProps {
  array: Tab[];
  onClick: (index: number) => void;
  currIndex: number;
}

function Tabs({ array, onClick, currIndex }: TabProps) {
  const checkIsActive = (index: number) => index === Number(currIndex);
  return (
    <TabsContainer>
      {array.map(({ index, label, iconName }) => (
        <Tab
          key={index}
          onClick={() => onClick(index)}
          $isActive={checkIsActive(index)}
        >
          {iconName ? (
            <Icon
              name={iconName}
              fill={checkIsActive(index) ? 'black' : 'gray400'}
            />
          ) : null}
          <span>{label}</span>
        </Tab>
      ))}
    </TabsContainer>
  );
}

interface TitleProps extends PropsWithChildren {
  hasBottomBorder?: boolean;
}

function Title({ children, hasBottomBorder = false }: TitleProps) {
  return (
    <MainTitleContainer $hasBottomBorder={hasBottomBorder}>
      <MainTitle>{children}</MainTitle>
    </MainTitleContainer>
  );
}

function SubTitle({ children }: PropsWithChildren) {
  return <TableSubTitle>{children}</TableSubTitle>;
}

interface RowProps extends PropsWithChildren {
  noCheckbox?: boolean;
}

function NameRow({ children, noCheckbox }: RowProps) {
  return <ListRowName $noCheckbox={noCheckbox}>{children}</ListRowName>;
}

function RowList({ children }: PropsWithChildren) {
  return (
    <ListRowList $hasScroll={Children.count(children) > 10}>
      {children}
    </ListRowList>
  );
}

function Row({ children, noCheckbox }: RowProps) {
  return <ListRow $noCheckbox={noCheckbox}>{children}</ListRow>;
}

interface EditAndRemoveButtonProps {
  onPreview?: () => void;
  onRemove?: () => void;
  onEdit?: () => void;
}

function EditAndRemoveButton({
  onPreview,
  onRemove,
  onEdit,
}: EditAndRemoveButtonProps) {
  const preventDefaultEvent =
    (callbackFn: () => void) => (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      callbackFn();
    };

  return (
    <EditIconsContainer>
      {onPreview && (
        <IconButton type="button" onClick={preventDefaultEvent(onPreview)}>
          <Icon name="Magnifier" />
        </IconButton>
      )}
      {onEdit && (
        <IconButton type="button" onClick={preventDefaultEvent(onEdit)}>
          <Icon name="Pencil" />
        </IconButton>
      )}
      {onRemove && (
        <IconButton type="button" onClick={preventDefaultEvent(onRemove)}>
          <Icon name="Delete" />
        </IconButton>
      )}
    </EditIconsContainer>
  );
}

function Checkbox() {
  return (
    <CheckboxContainer>
      <img src={CHECKBOX_DEFAULT_ICON_URL} alt="checkbox" />
    </CheckboxContainer>
  );
}

interface NoResultProps {
  message?: string;
  style?: React.CSSProperties;
  className?: string;
}

const defaultMessage = '검색 결과가 없습니다. 다시 검색해주세요.';

function NoResult({ message, style, className }: NoResultProps) {
  return (
    <NoResultMessage $customStyle={style} className={className}>
      {message || defaultMessage}
    </NoResultMessage>
  );
}

export default Object.assign(Table, {
  Tabs,
  Title,
  SubTitle,
  NameRow,
  RowList,
  Row,
  EditAndRemoveButton,
  Checkbox,
  NoResult,
});

const Container = styled.div`
  /* margin: ${(props) => props.theme.spaces.lg} 0; */
  padding: ${(props) => `${props.theme.spaces.xxl} ${props.theme.spaces.xxxl}`};
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.borderRadiuses.xxl};
  text-align: left;
  /* min-width: 480px; */
  min-width: 400px;
  min-height: 185px;
  word-break: break-all;
  margin-bottom: 20px;
`;

const TabsContainer = styled.div`
  flex-shrink: 0; /* 고정 높이 */
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
  margin-bottom: 32px;
`;

interface TabButtonProps {
  $isActive?: boolean;
}

const Tab = styled.button<TabButtonProps>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 14px 10px;
  font-size: 14px;
  font-weight: 600;
  color: ${(props) =>
    props.$isActive ? props.theme.colors.black : props.theme.colors.gray400};

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: ${(props) =>
      props.$isActive ? props.theme.colors.black : 'transparent'};
  }
`;

interface MainTitleContainerProps {
  $hasBottomBorder?: boolean;
}

const MainTitleContainer = styled.div<MainTitleContainerProps>`
  width: 100%;
  display: flex;
  ${({ $hasBottomBorder }) =>
    $hasBottomBorder &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};
      padding-bottom: 20px;
    `}
`;

const MainTitle = styled.span`
  min-width: fit-content;
  font-size: 24px;
  font-weight: 700;
`;

interface ListRowProps {
  $noCheckbox?: boolean;
}

const ListRowName = styled.div<ListRowProps>`
  padding-bottom: ${(props) => props.theme.spaces.xxs};
  width: 100%;
  display: grid;
  grid-template-columns: ${(props) => (props.$noCheckbox ? '1fr' : '24px 1fr')};
  gap: ${(props) => props.theme.spaces.xxs};
  font-size: ${(props) => props.theme.fonts.size.lg};
  font-weight: ${(props) => props.theme.fonts.weight.semibold};
  border-bottom: 1px solid ${(props) => props.theme.colors.gray100};

  & > * {
    display: flex;
    align-items: center;
  }
`;

interface ListRowListProps {
  $hasScroll: boolean;
}

const ListRowList = styled.ul<ListRowListProps>`
  /* padding-top: ${(props) => props.theme.spaces.xxs}; */
  ${(props) =>
    props.$hasScroll &&
    css`
      max-height: 620px;
      overflow-y: auto;
    `}
`;

const ListRow = styled.li<ListRowProps>`
  width: 100%;
  display: grid;
  grid-template-columns: ${(props) => (props.$noCheckbox ? '1fr' : '24px 1fr')};
  gap: ${(props) => props.theme.spaces.xxs};
  font-size: ${(props) => props.theme.fonts.size.sm};
  font-weight: ${(props) => props.theme.fonts.weight.medium};
  border-bottom: 1px solid ${(props) => props.theme.colors.gray100};
  z-index: 1;

  &:last-child {
    border: none;
  }

  & > * {
    display: flex;
    align-items: center;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const EditIconsContainer = styled.div`
  gap: ${(props) => props.theme.spaces.xxs};
`;

const IconButton = styled.button`
  display: flex;
  align-items: center;
`;

interface NoResultMessageProps {
  $customStyle?: React.CSSProperties;
}

const NoResultMessage = styled.div<NoResultMessageProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  font-size: ${(props) => props.theme.fonts.size.sm};
  font-weight: ${(props) => props.theme.fonts.weight.medium};
  color: ${(props) => props.theme.colors.gray600};

  ${(props) =>
    props.$customStyle &&
    Object.entries(props.$customStyle).map(
      ([key, value]) => `${key}: ${value};`
    )}
`;

const TableSubTitle = styled.span`
  font-size: 20px;
  font-weight: 500;
`;
