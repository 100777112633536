import { ADMIN_PAGE_PATH } from '@lib/constants/admin';
import {
  accessTokenAtomWithPersistence,
  chargeBrandAtomWithPersistence,
  emailAtomWithPersistence,
  roleAtomWithPersistence,
} from '@store/login';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';

type UseLogoutProps = {
  path: string;
};

export const useLogout = ({ path }: UseLogoutProps) => {
  const navigate = useNavigate();
  const [, setAccessToken] = useAtom(accessTokenAtomWithPersistence);
  const [, setRole] = useAtom(roleAtomWithPersistence);
  const [, setEmail] = useAtom(emailAtomWithPersistence);
  const [, setChargeBrand] = useAtom(chargeBrandAtomWithPersistence);

  const handleLogout = () => {
    setAccessToken();
    setRole();
    setEmail();
    setChargeBrand();

    return navigate(path);
  };

  return { handleLogout };
};
