import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAtomValue } from 'jotai';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { LOGO_IMAGE_BLACK_URL } from '@lib/constants/imageUrls';
import {
  BRAND_ADMIN_NAV_LIST,
  SUPER_ADMIN_NAV_LIST,
} from '@lib/constants/admin/common';
import { navListByRoleAtom, roleAtom } from '@store/login';
import { brandLogoImageUrlAtomWithPersistence } from '@store/admin/common';
import { getNavListByRole } from '@lib/utils/navigator';

export default function SideBar() {
  const { t } = useTranslation('admin/common');
  const location = useLocation();
  const [currPathLabel, setCurrPathLabel] = useState<string>('');
  const role = useAtomValue(roleAtom);
  const navListByRole = useAtomValue(navListByRoleAtom);
  const brandLogoImageUrl = useAtomValue(brandLogoImageUrlAtomWithPersistence);

  useEffect(() => {
    const currPath = SUPER_ADMIN_NAV_LIST.find((nav) =>
      location.pathname.startsWith(nav.path)
    );
    setCurrPathLabel(currPath?.labelKey || '');
  }, [location.pathname]);

  return (
    <Wrapper>
      <Container>
        <LogoImageLink to="#">
          <LogoImage
            src={brandLogoImageUrl || LOGO_IMAGE_BLACK_URL}
            alt="logo"
          />
        </LogoImageLink>
        <nav>
          <NavList>
            {role &&
              navListByRole.map((item) => (
                <NavItem
                  $isActive={item.labelKey === currPathLabel}
                  key={item.id}
                >
                  <NavItemLink to={item.path}>
                    <IconImage src={item.iconUrl} alt="icon" />
                    <span>{t(item.labelKey)}</span>
                  </NavItemLink>
                </NavItem>
              ))}
          </NavList>
        </nav>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100vh;
  overflow: hidden;
  z-index: 1900;
`;

const Container = styled.div`
  position: fixed;
  padding: ${(props) => props.theme.spaces.md};
  top: 0;
  left: 0;
  width: 16%;
  min-width: 180px;
  max-width: 240px;
  height: 100vh;
  border-right: 1px solid ${(props) => props.theme.colors.gray200};
  background-color: ${(props) => props.theme.colors.white};
`;

const LogoImageLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 8px 20px;
`;

const LogoImage = styled.img`
  width: 116px;
  height: auto;
`;

const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spaces.md};
  margin-top: ${(props) => props.theme.spaces.xxxl};
`;

interface NavItemProps {
  $isActive: boolean;
}

const NavItem = styled.li<NavItemProps>`
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.gray900};
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.borderRadiuses.xs};

  &:hover {
    background-color: ${(props) => props.theme.colors.gray50};
  }

  ${(props) =>
    props.$isActive &&
    css`
      background-color: ${(props) => props.theme.colors.gray100};

      &:hover {
        background-color: ${(props) => props.theme.colors.gray100};
      }
    `}
`;

const NavItemLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 10px 20px;
`;

const IconImage = styled.img`
  width: 24px;
  height: 24px;
`;
